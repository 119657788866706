import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddfilebtnComponent } from 'src/app/Modules/Shared/addfilebtn/addfilebtn.component';
import { AddtagbtnComponent } from 'src/app/Modules/Shared/addtagbtn/addtagbtn.component';
import { AddfolderbtnComponent } from 'src/app/Modules/Shared/addfolderbtn/addfolderbtn.component';
import { BtnIndexComponent } from 'src/app/Modules/Shared/btn-index/btn-index.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfPreviewComponent } from './pdf-preview/pdf-preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RenameFileComponent } from './rename-file/rename-file.component';
import { ShareFileComponent } from './share-file/share-file.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { LinkSharingComponent } from './link-sharing/link-sharing.component';
import { NgxFilesizeModule } from 'ngx-filesize';
import { TagAssigningComponent } from './tag-assigning/tag-assigning.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddBasketbtnComponent } from './add-basketbtn/add-basketbtn.component';
import { BasketsIndexComponent } from './baskets-index/baskets-index.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { FoldersComponent } from './folders/folders.component';
import { MatTabsModule } from '@angular/material/tabs';
// import { MoreFilterComponent } from './more-filter/more-filter.component';



@NgModule({
  declarations: [AddfilebtnComponent,
    AddtagbtnComponent,
    AddfolderbtnComponent,
    BtnIndexComponent,
    PdfPreviewComponent,
    RenameFileComponent,
    ShareFileComponent,
    LinkSharingComponent,
    TagAssigningComponent,
    AddBasketbtnComponent,
    BasketsIndexComponent,
    ConfirmationDialogComponent,
    FoldersComponent,


  ],
  imports: [
    CommonModule,
    AutocompleteLibModule, NgxFilesizeModule,
    MatButtonModule, MatIconModule, FormsModule, MatSlideToggleModule, ReactiveFormsModule, PdfViewerModule, MatChipsModule, MatDatepickerModule, MatInputModule, MatTabsModule
  ],
  entryComponents: [BasketsIndexComponent, ConfirmationDialogComponent, BtnIndexComponent, PdfPreviewComponent, RenameFileComponent, ShareFileComponent, LinkSharingComponent, TagAssigningComponent, FoldersComponent],
  exports: [NgxFilesizeModule]
})

export class ButtonModalModule { }
