import { Component, OnInit, Input } from '@angular/core';

import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { $ } from 'protractor';
import { range } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'


@Component({
  selector: 'app-tag-assigning',
  templateUrl: './tag-assigning.component.html',
  styleUrls: ['./tag-assigning.component.scss']
})
export class TagAssigningComponent implements OnInit {
  @Input() selectedFileId: any;
  constructor(private file: FileApiService, 
    public dialog: MatDialog, 
    private RightClickServiceGet: RightClickDataService, 
    private RightClickServiceSend: RightClickDataService,
    private dataServiceGet: TagsdataService,
    private dataServiceSend: TagsdataService,) { }
  Tagsarr: any[] = []
  selectedChips: any[] = [];
  unselectedTagIds: any;
  TagIds: any[] = [];
  checktag: any[] = [];
  singleClick = true;
  xyz = true;
  selectedTagIds: any;
  fileId: any[] = [];
  filesIds: any[] = [];
  Id;
  files: any[] = [];
  removeIds;


  ngOnInit(): void {

    this.checktag = [];
    this.Tagsarr = this.RightClickServiceGet.RightClickTagAttribute


    this.fileId = this.RightClickServiceGet.RightClickFileId


    let aaa = this.RightClickServiceGet.RightClickFilesIdList;

    if (aaa == 0) {
      this.filesIds.push({ Id: this.fileId.toString() })
      this.checktag = this.RightClickServiceGet.RightClickTagListOnFiles
    }
    else {
      for (var i in this.RightClickServiceGet.RightClickFilesIdList) {
        this.filesIds.push({
          Id: this.RightClickServiceGet.RightClickFilesIdList[i].toString()
        })
      }
    }





  }
  ngAfterViewInit() {

    for (var i = 0; i < this.Tagsarr.length; i++) {
      for (var j = 0; j < this.checktag.length; j++) {

        if ((this.Tagsarr[i].TagId == this.checktag[j].id) && (this.Tagsarr[i].IsReadOnly == false)) {


          document.getElementById('sdTag_' + this.Tagsarr[i].TagId).innerHTML = "<i class='fa fa-check-circle'></i>"
          this.xyz = false;

        }
      }

    }
  }


  changeSelected(parameter: any, query: string, TagId: any) {

    let tags: any;
    const index = this.selectedChips.indexOf(query);
    if (index >= 0) {
      if (document.getElementById('sdTag_' + TagId).innerHTML == "") {
        this.selectedChips.push(query);
        tags = this.selectedChips
        this.TagIds.push({ TagId: TagId })
        this.selectedTagIds = this.TagIds;

        document.getElementById('sdTag_' + TagId).innerHTML = "<i class='fa fa-check-circle'></i>"


      }
      else {
        this.selectedChips.splice(index, 1);
        this.TagIds.splice(index, 1);
        tags = this.selectedChips
        this.unselectedTagIds = this.TagIds;

        document.getElementById('sdTag_' + TagId).innerHTML = ""

      }


    } else {

      if (document.getElementById('sdTag_' + TagId).innerHTML == "") {
        this.selectedChips.push(query);
        tags = this.selectedChips
        this.TagIds.push({ Id: TagId })
        this.selectedTagIds = this.TagIds;

        document.getElementById('sdTag_' + TagId).innerHTML = "<i class='fa fa-check-circle'></i>"


      }
      else {
        document.getElementById('sdTag_' + TagId).innerHTML = ""
        this.removeIds = { Id: '-' + TagId }
        this.TagIds.push(this.removeIds)

        tags = this.selectedChips
        this.unselectedTagIds = this.TagIds;


      }


    }

  }

  Tagassigning() {

    this.file.TagAssign(this.TagIds, this.filesIds).subscribe((data) => {


      if (data == "Success") {
        //this.RightClickServiceSend.SendRelaodFileRequest("true");
        this.dataServiceSend.sendFileById("TagAssignFile");
        this.RightClickServiceSend.sendRightClickEvent("false");
        this.RightClickServiceSend.sendUncheckEvent("true");
        this.dialog.closeAll();
      }


    });

  }


}
