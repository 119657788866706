<div *ngIf="RightClickMenu=='files'" class="con-menu" id="rightClickModal">
    <div class='context-menu' id="conMenuModal">
        <ul *ngIf="checkboxcount>=1" class="MenuLi" id="accessCount">
            <li (mouseover)="showBasket=false" id="menuCount" class="multipleFileMenu">
                <span class='menuFC'></span>&nbsp;<span class="MenuIcon"> <span
                        class="badge">{{checkboxcount}}</span>Sélectionnés </span>
            </li>
        </ul>
        <ul class="MenuLi" id="accessView">
            <li (mouseover)="showBasket=false" *ngIf="!checkboxcount || checkboxcount==1" id="menuView"
                class="singleFileMenu" (click)="pdfView()">
                <span class='menuView'></span>&nbsp;<span class="MenuIcon"><i class="fas fa-eye faIcons"></i> Vue</span>
            </li>
            <a (mouseover)="showBasket=false" *ngIf="!checkboxcount || checkboxcount==1" id="downloadtag"
                href={{downloadpdf}} (click)="DownloadPdf()">
                <li id="menuDownload" class="singleFileMenu">
                    <span class='menuDownload'></span>&nbsp;<span class="MenuIcon"><i
                            class="fas fa-file-download faIcons"></i> Télécharger</span>
                </li>
            </a>
            <li (mouseover)="showBasket=false" id="menuDownloadZip" class="multipleFileMenu" (click)="DownloadZip()">
                <span class='menuDownload'></span>&nbsp;<span class="MenuIcon"><i
                        class="fas fa-file-archive faIcons"></i>Télécharger comme ZIP</span></li>
        </ul>
        <ul class="MenuLi" id="accessLink">
            <li (mouseover)="showBasket=false" *ngIf="!checkboxcount || checkboxcount==1" id="menuLink"
                (click)="linkshareView()" class="singleFileMenu"><span class='menuLink'></span>&nbsp;<span
                    class="MenuIcon"><i class="fas fa-link faIcons"></i> Obtenir le
                    lien</span></li>
            <li (mouseover)="showBasket=false"
                *ngIf="(systemDependent==false && !checkboxcount) || (systemDependent==false && checkboxcount>=1)"
                id="menuUserShare" (click)="ShareFile()"><span class='menuUserShare'></span>&nbsp;<span
                    class="MenuIcon"><i class="fas fa-user-plus faIcons"></i> Partager</span></li>
        </ul>
        <ul class="MenuLi" id="accessTags">
            <li (mouseover)="showBasket=false" id="menuTags" class="singleFileMenu" (click)="tagassignView()"><span
                    class='tagAssignModal'></span>&nbsp;<span class="MenuIcon"><i
                        class="fas fa-tags faIcons"></i>Attribuer une balise</span></li>
            <li id="menubasket" class="fileBasket" *ngIf="basketMenu==true" (mouseover)="ShowBasketNames();"><span
                    class='addfile'></span>&nbsp;<span class="MenuIcon"><i
                        class="fa fa-shopping-bag faIcons"></i>Ajouter au panier</span></li>
            <li (mouseover)="showBasket=false" id="menuMove" class="fileMove" (click)="FileMovetoFolder()"><span
                    class='movefile'></span>&nbsp;<span class="MenuIcon"><i
                        class="fas fa-folder-open faIcons"></i>Déplacer vers un dossier</span></li>

        </ul>
        <ul (mouseover)="showBasket=false" class="MenuLi" id="accessFile">
            <li (mouseover)="showBasket=false" *ngIf="FolderId > 0" id="menuFileMove" class="singleFileMove"
                (click)="MoveFiles()"><span class="fileMoveModal"></span>&nbsp;<span class="MenuIcon"><i
                        class="fa fa-mail-reply faIcons"></i>
                    Retirer du dossier</span></li>
            <li (mouseover)="showBasket=false"
                *ngIf="(systemDependent==false && !checkboxcount) || (systemDependent==false && checkboxcount>=1)"
                id="menuFileDelete" class="singleFileMenu" (click)="DeleteFile()"><span
                    class="fileDeleteModal"></span>&nbsp;<span class="MenuIcon"><i class="fas fa-trash faIcons"></i>
                    Supprimer</span></li>
            <li (mouseover)="showBasket=false"
                *ngIf="(systemDependent==false && checkboxcount==1) || (systemDependent==false && !checkboxcount)"
                id="menuFileRename" class="singleFileMenu" (click)="RenameFile()"><span
                    class="fileRenameModal"></span>&nbsp;<span class="MenuIcon"><i class="fas fa-file-alt faIcons"></i>
                    Renommer</span></li>
        </ul>

    </div>

</div>
<div (mouseleave)="showBasket=false" style="margin-left: 240px; margin-top: 200px; position: absolute;"
    *ngIf="showBasket==true && basketlistNames != null" class="con-menu" id="rightClickModal">
    <div [style.height]="basketlistNames.length > 2 ? '150px' : '70px'" class='context-menu' id="conMenubasket">

        <ul id="accessView">

            <li id="menuDownloadZip" class="multipleFileMenu" *ngFor="let baskets of basketlistNames;let i=index"
                (click)="AddtoBasket(i)"><span class='menuDownload'></span>&nbsp;<span class="MenuIcon"><i
                        class="fa fa-shopping-bag faIcons"></i>{{baskets.Name}}</span></li>
        </ul>
    </div>

</div>
<div *ngIf="RightClickMenu=='folder'" class="con-menu" id="rightClickModal">
    <div class='context-menu' id="conMenuModal">

        <ul id="accessView">


            <li id="menuDownloadZip" class="multipleFileMenu" (click)="FolderDownloadZip()"><span
                    class='menuDownload'></span>&nbsp;<span class="MenuIcon"><i
                        class="fas fa-file-archive faIcons"></i>Télécharger comme ZIP</span></li>
            <li id="menuFileRename" class="singleFileMenu" (click)="RenameFile()"><span
                    class="fileRenameModal"></span>&nbsp;<span class="MenuIcon"><i class="fas fa-edit faIcons"></i>
                    Modifier</span></li>
            <li id="menuFileDelete" class="singleFileMenu" (click)="DeleteFile()"><span
                    class="fileDeleteModal"></span>&nbsp;<span class="MenuIcon"><i class="fas fa-trash faIcons"></i>
                    Supprimer</span></li>
        </ul>
    </div>

</div>