import { Component, OnInit } from '@angular/core';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service';
@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.scss']
})
export class FoldersComponent implements OnInit {
  resultFolder;
Folderarr:any[]=[];
FolderId=0;
FileIds:any[]=[];
selectedFileIds:any[]=[];
bcElements: any[] = [];
  constructor(private fileApiSrv: FileApiService,
    private RightClickServiceGet: RightClickDataService,
    private RightClickServiceSend: RightClickDataService
  ) { }

  ngOnInit(): void {
    this.fileApiSrv.GetFolders().subscribe(folders => {
      this.resultFolder = folders.Folders;
        this.Folderarr = this.resultFolder.filter(function (e) {
          return e.ParentId == 0
        })
     
      
      })
  }

  FolderClick(id,Foldername)
  {
    let breadcrumb =
    {
      "folderId": id,
      "FolderName": Foldername,
    }
    this.bcElements.push(breadcrumb)
    this.Folderarr = this.resultFolder.filter(function (e) {
      return e.ParentId == id
    })
    this.FolderId=id;
  }
FileMove()
{
  this.selectedFileIds= this.RightClickServiceGet.FileIdShare;
  for(let j=0;j<this.selectedFileIds.length;j++)
  {

    this.FileIds.push({Id:this.selectedFileIds[j]})
    
  }
  this.fileApiSrv.FileDropIntoFolder(this.FileIds,this.FolderId).subscribe(data=>
    {
      if(data=="Success")
      {
        this.RightClickServiceSend.sendFileMoveToFolder(this.selectedFileIds);
      }
    });
}

DeleteBc(index) {
  let IndexSet
  let folderidd
  IndexSet = this.bcElements[index]
  folderidd = IndexSet.folderId
  this.FolderId = folderidd;
  this.fileApiSrv.GetFolders().subscribe(folders => {
    this.resultFolder = folders.Folders;
    this.Folderarr = this.resultFolder.filter(function (e) {
      return e.ParentId == folderidd
    })
  })
 
  this.bcElements.splice(index + 1, this.bcElements.length - (index + 1));
}
getallfolders()
{
  this.bcElements = [];
  this.fileApiSrv.GetFolders().subscribe(folders => {
    this.resultFolder = folders.Folders;
      this.Folderarr = this.resultFolder.filter(function (e) {
        return e.ParentId == 0
      })
   
    
    })
}
}
