import { Component, OnInit } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { Subscription } from 'rxjs';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import {MatSliderModule} from '@angular/material/slider';
import { Options } from 'ng5-slider';
import { FilterdataService } from 'src/app/Service/Frontend/filterdata.service';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'

@Component({
  selector: 'app-timelinefilter',
  templateUrl: './timelinefilter.component.html',
  styleUrls: ['./timelinefilter.component.scss']

})

export class TimelinefilterComponent implements OnInit {
  TypeEventSubscription: Subscription;

  value: number = 1;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      {legend: 'Aucune', value: 1},
      // {value: 2},À tout moment
      {value: 2, legend: "Aujourd'hui"},
      // {value: 4},
      {value: 3, legend: 'Hier'},
      // {value: 6},
      {value: 4, legend: '7derniers jours'},
      // {value: 8},
      {value: 5, legend: 'Mois'}
    ]
  };
 
  clickEventSubscription: Subscription;
  public empArray: any;
  constructor(private file: FileApiService, 
    private TimeFilter: TagsdataService,
    private filterServiceSend:FilterdataService,
    private filterServiceGet:FilterdataService,
    private RightClickServiceSend: RightClickDataService,
    private RightClickServiceGet: RightClickDataService) 
  { 
     this.clickEventSubscription = this.TimeFilter.getReloadFilter().subscribe((data) => {
       if(data=="true")
       {
       this.sDate = 'fromdate:null;todate:null';
       this.value = 1;
       }
    
   })
  

  }
  
  public timelinevalue: any;
  fromDate: number;
  toDate: number;
  fromDateOne: string;
  toDateOne: string;
  fDate: string;
  aaaa: string
  timeline: string;
  sDate: string;
  todayDate: string;
  isShow = false;
  subscription: Subscription;
  TimelineValue: string;
  Removeclass: any;
  id: number;
  selectedChips: any[] = [];
  visible: boolean = false;

  STypes:string = null;
  STags:string = null;
  SDates:string = 'fromdate:null;todate:null';

  ngOnInit(): void {
  }


  newMessage(name) {
    this.TimeFilter.Filtertimelinedatas(name)
  }

  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
  }
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  // openPdfViewer(timelinevalue: string) {
  //   this.visible = false;
  //   this.timeline = timelinevalue;
  //   var today = new Date();
  //   this.Removeclass = document.querySelectorAll(".timeLineModel")
  //   console.log(this.Removeclass);

  //   [].forEach.call(this.Removeclass, function (el) {
  //     el.classList.remove("addcolour", "adddcolour");
  //   });
  //   switch (this.timeline) {

  //     case 'All':
  //       document.querySelector("#time_all").classList.add("addcolour");

  //       break;
  //     case 'Today':

  //       document.querySelector("#time_today").classList.add("addcolour");

  //       break;
  //     case 'Yesterday':

  //       document.querySelector("#time_yesterday").classList.add("addcolour");

  //       break;
  //     case '7days':

  //       document.querySelector("#time_7days").classList.add("addcolour");

  //       break;
  //     case 'Month':

  //       document.querySelector("#time_month").classList.add("addcolour");

  //       break;
  //   }


  //   switch (this.timeline) {
  //     case 'All':
  //       this.sDate = 'fromdate:null;todate:null';
  //       break;
  //     case 'Today':
  //       this.sDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + new Date().getDate().toString());

  //       this.sDate = 'fromdate:' + this.sDate + ';' + 'todate:' + this.sDate
  //       break;
  //     case 'Yesterday':
  //       this.todayDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + new Date().getDate().toString());
  //       this.sDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + (new Date().getDate() - 1).toString());
  //       this.sDate = 'fromdate:' + this.sDate + ';' + 'todate:' + this.todayDate
  //       break;
  //     case '7days':
  //       var sixDaysAgo = new Date(today);
  //       sixDaysAgo.setDate(today.getDate() - 6);
  //       this.fDate = 'fromdate:' + this.getFilterDateFormat(sixDaysAgo);

  //       break;
  //     case 'Month':
  //       this.todayDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + new Date().getDate().toString());
  //       var date = new Date();
     
  //       var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  //       var dd = firstDay.getDate();
  //       var mm = String(firstDay.getMonth() + 1).padStart(2, '0');
  //       var yyy = firstDay.getFullYear();
  //       var str = yyy + '/' + mm + '/' + dd;
  //       this.sDate = 'fromdate:' + str + ';' + 'todate:' + this.todayDate
     
  //       break;
  //   }
  //   this.reset();
  //   // this.newMessage(this.sDate);
  //   // this.subscription = this.TimeFilter.Timemes$.subscribe(x => this.TimelineValue = x)

  // }



  reset() {
    this.timeline = null;

  }


  getFilterDateFormat(Date_ob) {
    var dd = (Date_ob.getDate());
    var mm = String(Date_ob.getMonth() + 1).padStart(2, '0');
    var yyyy = Date_ob.getFullYear();
    this.sDate = yyyy + '/' + mm + '/' + dd;
    this.todayDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + new Date().getDate().toString());
    this.sDate = 'fromdate:' + this.sDate + ';' + 'todate:' + this.todayDate
 
  }

  onclick(string) {
    this.Removeclass = document.querySelectorAll(".timeLineModel");
    //console.log(this.Removeclass);
    [].forEach.call(this.Removeclass, function (el) {
      el.classList.remove("addcolour");
    });
    document.querySelector("#time_custom").classList.add("addcolour");
    this.visible = !this.visible;
  }


  openPdf(value)
  {
    let Flag: string;
    let Terms: string;
    let Limit;
    let Offset;
    var today = new Date();
    
    switch (value) 
    {
      case 1:
        this.sDate = 'fromdate:null;todate:null';
     
        break;
      case 2:
        this.sDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + new Date().getDate().toString());

        this.sDate = 'fromdate:' + this.sDate + ';' + 'todate:' + this.sDate
        
        break;
      case 3:
        this.todayDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + new Date().getDate().toString());
        this.sDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + (new Date().getDate() - 1).toString());
        this.sDate = 'fromdate:' + this.sDate + ';' + 'todate:' + this.todayDate
      
        break;
      case 4:
        var sixDaysAgo = new Date(today);
        sixDaysAgo.setDate(today.getDate() - 6);
        this.fDate = 'fromdate:' + this.getFilterDateFormat(sixDaysAgo);

        break;
      case 5:
        this.todayDate = (new Date().getFullYear().toString() + '/' + (('0' + (new Date().getMonth() + 1)).slice(-2)) + '/' + new Date().getDate().toString());
        var date = new Date();
     
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var dd = firstDay.getDate();
        var mm = String(firstDay.getMonth() + 1).padStart(2, '0');
        var yyy = firstDay.getFullYear();
        var str = yyy + '/' + mm + '/' + dd;
        this.sDate = 'fromdate:' + str + ';' + 'todate:' + this.todayDate
  
        break;
    }
    this.TimeFilter.sendTimetoMoreFilterEvent(this.sDate);
    // this.newMessage(this.sDate);
    // this.subscription = this.TimeFilter.Timemes$.subscribe(x => this.TimelineValue = x)

    this.filterServiceSend.FilterFilesLoad=true;
this.filterServiceSend.FilterTimeLineData=this.sDate;

this.STypes=this.filterServiceGet.FilterTypeData;
this.SDates=this.filterServiceGet.FilterTimeLineData;
this.STags=this.filterServiceGet.FilterTagsData;


if(this.STypes=='')
{
this.STypes=null;
}
if(this.SDates=='')
{
this.SDates=null;
}
if(this.STags=='')
{
this.STags=null;
}

this.STags = 'tags:' + this.STags;
if (this.STags.includes("null")) {
  this.STags = null
}
this.STypes = 'type:' + this.STypes
if (this.STypes.includes("null")) {
  this.STypes = null
}
this.SDates = this.SDates

  if (this.STags == null) {
    this.STags = 'tags:' + this.STags
  }
  if (this.STypes == null) {
    this.STypes = 'type:' + this.STypes
  }
  if (this.SDates == null) {
    this.SDates = 'fromdate:' + this.SDates + ';'+ 'todate:' + this.SDates
  }
  Terms = this.STypes + ';' + this.STags + ';' + this.SDates






 
 Flag = "AdvanceSearch";
  Limit = 25;
  Offset = 0;

  this.TimeFilter.sendFilterTerms(Terms);

// if((this.filterServiceGet.FilterTypeData==null || this.filterServiceGet.FilterTypeData=='') && (this.filterServiceGet.FilterTagsData==null ||this.filterServiceGet.FilterTagsData==''))
// {
// this.file.GetSearchFiles(Flag ,Terms, Limit ,Offset).subscribe(data => {
// this.filterServiceSend.FilterGlobalFiles=data;
// this.filterServiceSend.sendFilterFiles(true);
// });
// }

// else{
//   this.filterServiceSend.sendFilterFiles(false);
// }
this.filterServiceSend.FilterTerms=Terms;
if(Terms != "type:null;tags:null;fromdate:null;todate:null")
{
 
this.file.GetSearchFiles(Flag ,Terms, Limit ,Offset).subscribe(data => {
this.filterServiceSend.FilterGlobalFiles=data;
this.filterServiceSend.sendFilterFiles(data);
this.TimeFilter.sendFilterResult("true");
this.TimeFilter.sendReloadFilter("false");
this.RightClickServiceSend.sendUncheckEvent("true");
});

  }

}
}
export class ButtonToggleOverviewExample { }
export class DatepickerOverviewExample { }
