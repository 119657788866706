<div class="col-md-12 border searchtagform" id="circletag">


  <i class="fas fa-tags tagicon"></i>
  <span class="tagstxt"> Mots clés </span>
  <mat-slide-toggle id="toggle" [(ngModel)]="FlagForSlideToggle" (change)="onToggleChange()">Dépendante
  </mat-slide-toggle>

  <div [hidden]="isShow" class="tagbody">

    <input type="text" id="tagFilterSearch" class="col-md-12 form-control" autocomplete="off"
      (keydown.backspace)="removeTagOnBackspace($event)" (keyup)="keyUp($event)" placeholder="Rechercher des tags" />
    <br>


    <div>
      <span selectable multiple class="doctagnames" id="SearchTag">
        <span class="docTag" *ngFor="let taglist of searchtagdata" id="FilterTagId_{{taglist.TagId}}"
          [ngStyle]="{'border-color':taglist.Color,'background-color':taglist.Color+60}"
          (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName , taglist.TagId)">{{ taglist.TagName
          }}
          <span *ngFor="let tags of selectedtagList">
            <span id="selectTag_{{taglist.TagId}}" *ngIf="taglist.TagId == tags.TagId"><i
                class='fa fa-check-circle'></i></span>
          </span>
        </span>
      </span>
    </div>

    <br>

  </div>

</div>

<div>
  <span selectable multiple class="doctagnames" id="selectedList">
    <span class="docTag" *ngFor="let taglist of selectedtagList;let i=index" id="FilterTagId_{{taglist.TagId}}"
      [ngStyle]="{'border-color':taglist.Color,'background-color':taglist.Color+60}"
      (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName , taglist.TagId)">{{ taglist.TagName }}
      <i class='fa fa-times-circle'></i>
    </span>
  </span>
</div>