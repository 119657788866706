import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatChip } from '@angular/material/chips';
import { Subscription } from 'rxjs';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { type } from 'os';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { FilterdataService } from 'src/app/Service/Frontend/filterdata.service';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'

@Component({
  selector: 'app-typefilter',
  templateUrl: './typefilter.component.html',
  styleUrls: ['./typefilter.component.scss']
})

export class TypefilterComponent implements OnInit {
  clickEventSubscription: Subscription;
  public empArray: any;
  STypes:string = null;
  STags:string = null;
  SDates:string = 'fromdate:null;todate:null';

  constructor(private TypeFilter: TagsdataService,
    private file: FileApiService,
    private filterServiceSend:FilterdataService,
    private filterServiceGet:FilterdataService,
    private RightClickServiceSend: RightClickDataService,
    private RightClickServiceGet: RightClickDataService)
  {

    this.clickEventSubscription = this.TypeFilter.getReloadFilter().subscribe((data) => {
if(data=="true")
{
      this.sType = [];
    this.commonarray = [];
      document.querySelector("#type_pdf").classList.remove("adddcolour");
      document.querySelector("#type_img").classList.remove("adddcolour");
      document.querySelector("#type_file").classList.remove("adddcolour");
      document.querySelector("#type_pre").classList.remove("adddcolour");
      document.querySelector("#type_excel").classList.remove("adddcolour");
      document.getElementById('icon_jpg').innerHTML="";
      document.getElementById('icon_jpg').classList.remove('selectedIcon');
      document.getElementById('icon_pdf').innerHTML="";
      document.getElementById('icon_pdf').classList.remove('selectedIcon');
      document.getElementById('icon_doc').innerHTML="";
      document.getElementById('icon_doc').classList.remove('selectedIcon');
      document.getElementById('icon_ppt').innerHTML="";
      document.getElementById('icon_ppt').classList.remove('selectedIcon');
      document.getElementById('icon_xlsx').innerHTML="";
      document.getElementById('icon_xlsx').classList.remove('selectedIcon');
      this.selectedChips=[];
}
    })
  
  }
  public typevalue: any;
  sType: any[] = [];
  selectedChips: any[] = [];
  commonarray: any[] = [];
  isShow = false;

  Removeclass;
  teachDS
  changeDetectorRefs
id;
  subscription: Subscription;
  TypeValue: string
  inputNamevalue = '';

  typedatas: any[] = [];
  bookmarks:any[] = [];
visibleSelectedIcon="hidden";
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.typedatas, event.previousIndex, event.currentIndex);
  }





  ngOnInit(): void {

  }


  newMessage(name) {
    this.TypeFilter.Filtertypedatas(name)


  }


  toggleSelection(chip: MatChip) {
    chip.toggleSelected();
  }
  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  changeSelected(query: string) {

    let Flag: string;
    let Terms: string;
    let Limit;
    let Offset;
    
    const index = this.commonarray.indexOf(query);
    if (index >= 0) {
      document.getElementById('icon_'+query).innerHTML="";
      document.getElementById('icon_'+query).classList.remove('selectedIcon');
      this.commonarray.splice(index, 1);

      switch (query) {

        case 'pdf':
          document.querySelector("#type_pdf").classList.remove("adddcolour");


          break;
        case 'jpg':

          document.querySelector("#type_img").classList.remove("adddcolour");
          this.commonarray = this.commonarray.filter(e => e !== 'jpeg');
          this.commonarray = this.commonarray.filter(e => e !== 'png');
          break;
          case 'doc':

          document.querySelector("#type_file").classList.remove("adddcolour");
          this.commonarray = this.commonarray.filter(e => e !== 'docx');

          break;
        case 'ppt':

          document.querySelector("#type_pre").classList.remove("adddcolour");
          this.commonarray = this.commonarray.filter(e => e !== 'pptx');
          break;
        case 'xlsx':

          document.querySelector("#type_excel").classList.remove("adddcolour");
          this.commonarray = this.commonarray.filter(e => e !== 'xlsm');
          break;
      }


    } else {
      this.visibleSelectedIcon=query;
      document.getElementById('icon_'+query).innerHTML=("<i class='fas fa-check'></i>");
      document.getElementById('icon_'+query).classList.add('selectedIcon');

      switch (query) {

        case 'pdf':
          document.querySelector("#type_pdf").classList.add("adddcolour");
          this.commonarray.push('pdf')

          break;
        case 'jpg':

          document.querySelector("#type_img").classList.add("adddcolour");
          this.commonarray.push('jpg')
          this.commonarray.push('jpeg')
          this.commonarray.push('png')
          break;
        case 'doc':

          document.querySelector("#type_file").classList.add("adddcolour");
          this.commonarray.push('doc')
          this.commonarray.push('docx')
          break;
        case 'ppt':

          document.querySelector("#type_pre").classList.add("adddcolour");
          this.commonarray.push('ppt')
          this.commonarray.push('pptx')
          break;
        case 'xlsx':
          this.commonarray.push('xlsx')
          this.commonarray.push('xlsm')
          document.querySelector("#type_excel").classList.add("adddcolour");

          break;
      }


    }

     this.newMessage(this.sType);
     this.subscription = this.TypeFilter.mes$.subscribe(x => this.TypeValue = x)

     this.TypeFilter.sendTypetoMoreFilterEvent(this.sType);

    this.TypeFilter.sendTypetoMoreFilterEvent(this.commonarray);

    this.filterServiceSend.FilterFilesLoad=true;
    this.filterServiceSend.FilterTypeData=this.commonarray;


    this.STypes=this.filterServiceGet.FilterTypeData;
    this.SDates=this.filterServiceGet.FilterTimeLineData;
    this.STags=this.filterServiceGet.FilterTagsData;
    
    if(this.STypes=='')
    {
  this.STypes=null;
    }
      if(this.SDates=='')
      {
  this.SDates=null;
      }
      if(this.STags=='')
      {
  this.STags=null;
      }
      
    this.STags = 'tags:' + this.STags;
    if (this.STags.includes("null")) {
      this.STags = null
    }
    this.STypes = 'type:' + this.STypes
    if (this.STypes.includes("null")) {
      this.STypes = null
    }
    this.SDates = this.SDates

      if (this.STags == null) {
        this.STags = 'tags:' + this.STags
      }
      if (this.STypes == null) {
        this.STypes = 'type:' + this.STypes
      }
      if (this.SDates == null) {
        this.SDates = 'fromdate:' + this.SDates + ';'+ 'todate:' + this.SDates
      }
      Terms = this.STypes + ';' + this.STags + ';' + this.SDates


     Flag = "AdvanceSearch";
      Limit = 25;
      Offset = 0;



  //   if((this.filterServiceGet.FilterTimeLineData==null || this.filterServiceGet.FilterTimeLineData=='') && (this.filterServiceGet.FilterTagsData==null || this.filterServiceGet.FilterTagsData==''))
  //   {
  //   this.file.GetSearchFiles(Flag ,Terms, Limit ,Offset).subscribe(data => {
  //   this.newMessage(data);
  //  console.log(data);
  //  this.filterServiceSend.FilterGlobalFiles=data;
  //  this.filterServiceSend.sendFilterFiles(true);
  // });
  //   }

  //   else{
  //     console.log('rm');
  //     if(this.STypes!="type:")
  //     {
  //     this.filterServiceSend.sendFilterFiles(this.STypes);
  //     }
  //   }
  this.filterServiceSend.FilterTerms=Terms;
  this.TypeFilter.sendFilterTerms(Terms);
  //this.TypeFilter.sendReloadFilter("false")
  
  if(Terms != "type:null;tags:null;fromdate:null;todate:null")
 {
 
    this.file.GetSearchFiles(Flag ,Terms, Limit ,Offset).subscribe(data => {
    this.newMessage(data);
   this.filterServiceSend.FilterGlobalFiles=data;
   this.filterServiceSend.sendFilterFiles(data);
   this.TypeFilter.sendFilterResult("true");
   this.TypeFilter.sendReloadFilter("false");
   this.RightClickServiceSend.sendUncheckEvent("true");
  });
    
}

  }






}






