
<div class="col-md-6-5 menu-panel">
   <div class="menu-item" id="tabsname" *ngFor="let tabnamelist of tabnames;let i=index"
       (click)="tabsindex(i)">

      <div [ngSwitch]="i">
         <span><i class="fas fa-folder foldericon" *ngSwitchCase="'0'"> </i></span>
         <span><i class="fas fa-user-tie" *ngSwitchCase="'1'"> </i></span>
         <span><i class="fas fa-share-alt" *ngSwitchCase="'2'"> </i></span>
         <span><i class="fas fa-list-alt" *ngSwitchCase="'3'"> </i></span>
         <span id="name"> {{tabnamelist.tabname}}</span>
      </div>
   </div>
</div>
<div class="inner">&nbsp;</div>