<div class=" col-md-12 border searchtimeform" id="circle">

 
    <i class="fas fa-history timelineicon"></i>
     <span class="timetxt"> Chronologie </span>
  
   
<div  [hidden] = "isShow" class="timelineheader custom-slider">
<ng5-slider id="slide" [(value)]="value" [options]="options" (click)="openPdf(value)"></ng5-slider> 


</div>

</div>
