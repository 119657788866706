<div class="modal-title">
   <h2  style="border-bottom:1px solid rgb(233, 230, 230);" *ngIf="dataname=='folder'"><i class="fas fa-folder icons" ></i> Renommer le dossier!</h2> 
   <h2 style="border-bottom:1px solid rgb(233, 230, 230);" *ngIf="dataname=='files'"><i class="fas fa-file icons" ></i> Rennommer votre fichier!</h2> 
</div>
<div class="row">
   <div class="col-md-5">
       <span> 
         <input #inputfield class="form-control renameInput" type="text" placeholder="Rennommer votre fichier"
         value={{this.NewFileName}} (keyup)="RenameEvent($event.target.value)" />
         <span class="col-md-2" id="inputfieldEmpty"></span>
      </span>
       <span class="col-md-2" id="inputfield"></span>
   </div>
 <div *ngIf="dataname=='folder'" class="col-md-4">
       <label>Sélectionnez la couleur de votre dossier</label>
   </div>
   <div *ngIf="dataname=='folder'" class="col-md-2">
       <input #inputFoldercolour class="form-control docInput" type="color" value="{{this.ChangeFoldercolor}}" (change)="ChangeFolderColorEvent($event.target.value)"  />
   </div>
</div>
<div class="modal-footer">
   <button class="registerBtn btn btn-default" type="submit" id="button" 
      (click)="AddNewFileName()"><b>Enregister</b></button>

</div>
