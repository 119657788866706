import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'src/app/Service/backend/token-storage.service';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { saveAs } from 'file-saver';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service';
@Component({
  selector: 'app-baskets-index',
  templateUrl: './baskets-index.component.html',
  styleUrls: ['./baskets-index.component.scss']
})
export class BasketsIndexComponent implements OnInit {
  basketlist: any;
  BasketName: any;
  BasketId: any;
  Basketfiles: any[] = [];
  SelectedBasketfiles: any[] = [];
  AllFilesDelete: any[] = [];
  FileIdArray:any[]=[];
  changeText = false;
  isVisible = false;


  constructor(private readonly tokenStorageSrv: TokenStorageService,
    private dataServiceSend: TagsdataService,
    private fileApiService: FileApiService,
    public dialog: MatDialog,
    private RightClickServiceSend: RightClickDataService

  ) {

  }

  ngOnInit(): void {
   
    this.basketlist = this.tokenStorageSrv.getBasketList();
    if (this.basketlist.length > 0) {
      this.BasketId = 0;
      this.BasketName = this.basketlist[this.BasketId].Name;
      this.Basketfiles = this.basketlist[this.BasketId].files
      if (this.Basketfiles.length == 0) {
        document.getElementById("NoFiles").innerHTML = "Vous n’avez pas encore ajouté de documents";
      }
      else {
        document.getElementById("NoFiles").innerHTML = " ";
      }
    }
  }

  BasketNameClick(index) {


    document.getElementById('deleteProtectedFile').innerHTML = "";
    document.getElementById('NoFilesZip').innerHTML = "";
    this.BasketId = index;
    this.BasketName = this.basketlist[index].Name;
    this.Basketfiles = this.basketlist[index].files;
    if (this.Basketfiles.length == 0) {
      document.getElementById("NoFiles").innerHTML = "Vous n’avez pas encore ajouté de documents";
    }
    else {
      document.getElementById("NoFiles").innerHTML = " "
    }
  }
  Removefile(id) {

    let data = this.tokenStorageSrv.getBasketList();


    let index = data[this.BasketId].files.findIndex(e => e.Id === id);

    if (index !== -1) {
      data[this.BasketId].files.splice(index, 1);
    }


    this.tokenStorageSrv.DeletefromBasket(data);
    this.basketlist = this.tokenStorageSrv.getBasketList();
    this.Basketfiles = this.basketlist[this.BasketId].files;
    this.RightClickServiceSend.sendFileinBasket('remove_' + id);
  }
  deleteIconShow(i) {

    document.getElementById("basket_" + i).style.display = 'inline-block';


  }
  deleteIconHide(i) {
    //document.getElementById(i).innerHTML = ""

    document.getElementById("basket_" + i).style.display = 'none';
  }

  DeleteBasket(name, i ,Removebasket:string) {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      height: '180px',
      data:
      {
         DeleteBasket:Removebasket
      }

    }).afterClosed()
      .subscribe(response => {
        this.dataServiceSend.sendBasCount(this.BasketId);
        if (response.data == 'delete') {
          let data = this.tokenStorageSrv.getBasketList();

          let index = data.findIndex(e => e.Name === name);
          if (index !== -1) {
            data.splice(index, 1);
          }
          this.tokenStorageSrv.DeletefromBasket(data);
          this.basketlist = this.tokenStorageSrv.getBasketList();
          if (this.basketlist.length > 0) {
            this.BasketId = 0;
            this.BasketName = this.basketlist[this.BasketId].Name;
            this.Basketfiles = this.basketlist[this.BasketId].files
          }
          else {

            this.BasketName = '';
            this.Basketfiles = [];
            document.getElementById("NoFiles").innerHTML = " "
          }
          this.dataServiceSend.sendBasCount('count');

        }

      });




  }
  ZipDownload() {
    let data = this.tokenStorageSrv.getBasketList();
if(data[this.BasketId].files.length>0)
{
    for (let j = 0; j < data[this.BasketId].files.length; j++) {
      this.SelectedBasketfiles.push({ LinkId: data[this.BasketId].files[j].LinkId });
    }

    this.fileApiService.DownloadZip(this.SelectedBasketfiles).subscribe((data) => {

      const base64 = data.ZippedFile;
      this.ZipContent(base64);

    });
  }
  else{
    document.getElementById('NoFilesZip').innerHTML = "aucun fichier à télécharger";

  }
}
  ZipContent(data) {
    var MyDate = new Date();
    var MyDateString;
    MyDate.setDate(MyDate.getDate());
    MyDateString = ('0' + MyDate.getDate()).slice(-2) + '_'
      + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '_'
      + MyDate.getFullYear() + ' ' +
      (new Date().getHours().toString()) + '_'
      + (new Date().getMinutes().toString()) + '_'
      + (new Date().getSeconds().toString());
    const name = "vos-fichiers " + MyDateString + ".zip";
    const zipBlob = this.dataURItoBlob(data);
    const zipfile = new File([zipBlob], name, { type: 'application/pdf' });
    saveAs(zipfile, name);

  }
  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI)
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  DeleteFiles(DeleteFiles:string) {
 
    this.AllFilesDelete = [];
    if(this.Basketfiles.length > 0)
    {
    this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      height: '180px',
      data:
      {
        DeleteBasketFiles:DeleteFiles

      }

    }).afterClosed()
      .subscribe(response => {
        if (response.data == 'delete') {
          if (this.Basketfiles.length > 0) {

            let data = this.tokenStorageSrv.getBasketList();

            for (let j = 0; j < data[this.BasketId].files.length; j++) {

              if (data[this.BasketId].files[j].SystemDependent == true) {
                document.getElementById('deleteProtectedFile').innerHTML = "le panier contient des fichiers protégés, donc ne peut pas supprimer";
                break;

              }
              else {
                document.getElementById('deleteProtectedFile').innerHTML = "";
                this.AllFilesDelete.push({ Name: data[this.BasketId].files[j].Name });
                this.FileIdArray.push(data[this.BasketId].files[j].Id);
              }
            }
            if (this.AllFilesDelete.length == data[this.BasketId].files.length) {
              this.fileApiService.fileDelete(this.AllFilesDelete).subscribe(datas => {
                if (datas = 'Success') {
                  this.dialog.closeAll();
                  let datafiles = this.tokenStorageSrv.getBasketList();
                  datafiles[this.BasketId].files = [];
                  this.tokenStorageSrv.DeletefromBasket(datafiles);
                  this.basketlist = this.tokenStorageSrv.getBasketList();
                  this.Basketfiles = this.basketlist[this.BasketId].files;
                  //this.RightClickServiceSend.SendRelaodFileRequest("true");
                      this.dataServiceSend.sendFileById(this.FileIdArray);
                    
                }

              })
            }

          }
        }
      });

    }
  }
}
