import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ConfigurationServiceService } from 'src/app/configuration-service.service'
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'

@Injectable({
  providedIn: 'root'
})
export class TagfilterService {

  private SearchTagsList;
  constructor(private http:HttpClient,
    private ConfigService: ConfigurationServiceService,
    private dataServiceSend: TagsdataService) { }


  SearchTags(Term:any)
  {
    this.dataServiceSend.BearerHeader=true;
this.SearchTagsList=this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_Aetc_restApi +'tags/Search',{ SearchTerms: Term});

return this.SearchTagsList;
  }
}
