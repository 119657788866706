import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddfileDto } from 'src/app/Dto/AddfileDto';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import * as uuid from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { files } from 'jszip';
import { ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { threadId } from 'worker_threads';
import { ConfigurationServiceService } from 'src/app/configuration-service.service'
import { query } from '@angular/animations';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import * as fileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { MatTabsModule } from '@angular/material/tabs';
import { TagfilterService } from 'src/app/Service/backend/tagfilter.service';

@Component({
  selector: 'app-addfilebtn',
  templateUrl: './addfilebtn.component.html',
  styleUrls: ['./addfilebtn.component.scss']
})

export class AddfilebtnComponent implements OnInit {
  @ViewChild('inputTagfield') fileselectname: any;
  click: boolean = true;
  Tagsarr;
  SystemTags;
  UserCogTags;
  searchtagdata: any[];
  postparams: any;
  selectedChips: any[] = [];
  UploadFile: any[] = [];
  DownloadFile: any[] = [];
  uploadedDocument: any;
  pdfDataURL: string | ArrayBuffer;
  TagIds: any[] = [];
  selectedTagIds: any;
  unselectedTagIds: any;
  SelectedFolderId = 0;
  clickEvent: Subscription;
  selectedfilename: any[] = [];
  isShownList: boolean = false;
  checks = true;
  isChecked = false;
  checked = true;
  toggleStatus
  text: string = 'Ajouter par tag';
  // isSlideOn:boolean = true;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private tagsservice: TagsdataService,
    private RightClickServiceGet: RightClickDataService,
    private ConfigService: ConfigurationServiceService,
    private files: FileApiService,
    private dataServiceGet: TagsdataService,
    private MaterialTabs: MatTabsModule,
    private tagfile: TagfilterService
  ) {
    this.UploadFile = [];

  }

  ngOnInit(): void {

    this.SelectedFolderId = this.dataServiceGet.sharedFolderId;

    this.Tagsarr = this.dataServiceGet.TagsLoad
    this.SystemTags = this.dataServiceGet.TagsSystem
    this.UserCogTags = this.dataServiceGet.TagsUser

  }



  getFolderId(fId: any) {
    this.SelectedFolderId = fId;
  }

  changeSelected(parameter: any, TagName: string, TagId: any, Color: any) {
    for (var i = 0; i < this.selectedfilename.length; i++) {
      if (this.selectedfilename[i].checked === true && this.checked == true) {

        let FileRecord = this.selectedfilename[i]
        let FileRecordList = new Array;
        FileRecordList = FileRecord.TagList

        if (FileRecordList.length == 0) {
          this.selectedfilename[i].TagList.push({ TagId: TagId, tagcolour: Color, tagname: TagName, Tagremoval: false })

        }
        else {
          let index = FileRecordList.findIndex(x => x.TagId === TagId);
          if (index >= 0) {

          }
          else {
            this.selectedfilename[i].TagList.push({ TagId: TagId, tagcolour: Color, tagname: TagName, Tagremoval: false })

          }


        }



      }
      else {
        if (this.selectedfilename[i].checked === true) {
          let FileRecord = this.selectedfilename[i]
          let FileRecordList = new Array;
          FileRecordList = FileRecord.TagList
          let index = FileRecordList.findIndex(x => x.TagId === TagId);
          if (index >= 0) {
            this.selectedfilename[i].TagList.splice(index, 1)
          }
        }

      }

    }
  }
  AddedTag(event) {

    this.checked = event.checked
    if (this.checked == false) {
      this.text = 'Supprimer par tag';
    }
    else {
      this.text = 'Ajouter par tag';
    }

  }

  RemoveTag(event, i, tagname) {

    let FileRecordforRemoval = this.selectedfilename[i]
    let FileRecordRemovalList = FileRecordforRemoval.TagList
    let index = FileRecordRemovalList.findIndex(x => x.tagname === tagname);
    if (index >= 0) {
      this.selectedfilename[i].TagList.splice(index, 1)

    }
  }
  CheckValue(event, i) {

    if (event.target.checked == true) {
      this.selectedfilename[i].checked = true
    }
    else {

      this.selectedfilename[i].checked = false
    }


  }

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    document.getElementById("droppable").style.borderColor = "cornflowerblue";

  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    document.getElementById("droppable").style.borderColor = '';

  }

  fileEvent(event) {
    this.DownloadFile = []
    this.isShownList = true;
    document.getElementById("droppable").style.borderColor = '';
    this.click = (event.target as HTMLInputElement).value === '' ? true : false;
    const files = event.target.files;

    const guId = uuid.v4();
    for (var i = 0; i < files.length; i++) {
      var filetype = files[i].type
      var fileExtension = filetype.split('/');
      fileExtension = '.' + fileExtension[1];
      this.DownloadFile.push(files[i])
      this.UploadFile.push(files[i])
      this.selectedfilename.push(
        { File: files[i], FileName: files[i].name, checked: true, TagList: new Array, GUID: guId, Name: guId + fileExtension }
      )
      var mySet = new Set(this.selectedfilename);
      this.selectedfilename = [...mySet];

    }
  }


  MorekeyUp(event) {

    let tagsearchTextBox
    let el
    tagsearchTextBox = (document.getElementById('tagMoreFilterSearch') as HTMLInputElement).value;

    el = event.key;

    if (tagsearchTextBox.length >= 3) {

      this.tagfile.SearchTags(tagsearchTextBox).subscribe(data => {

        this.searchtagdata = data
      })
    }
    if (el === "Backspace") {
      this.searchtagdata = []

      this.files.GetListofTag().subscribe(alltag => {
        this.SystemTags = alltag.SystemTags
        this.Tagsarr = alltag.ManualTags
        this.UserCogTags = alltag.UserTags
      })
    }

  }

  removeMoreTagOnBackspace = function (event) {
    if (event.keyCode === 8) {
      this.TagSearcharr = this.arr;
    }
  };

  setupReader(file) {
    let filename = file.File.name
    let filetype = file.File.type;
    let filesize = file.File.size;
    let tagslist = file.TagList
    let reader = new FileReader();
    this.uploadedDocument =
    {
      file: file.File
    }
    reader.readAsDataURL(this.uploadedDocument.file);
    reader.onload = (x) => {

      let tmpDataURL = x.target.result;
      var jsonfile = JSON.stringify(tmpDataURL)
      var splitfilebyte = jsonfile.split(",")
      var filebyte = splitfilebyte[1].replace('"', '')
      this.pdfDataURL = filebyte
      let jsonTagIds = JSON.stringify(this.selectedTagIds);
      const guId = uuid.v4();
      let TagsList = [{
        TagId: '0',
        TagName: 'storedoc',
        Color: '#808080',
        IsReadOnly: true,
        TagUsers: null
      }];
      TagsList.forEach(item => item.TagId);
      let jsonTagsList = JSON.stringify(TagsList);

      var fileExtension = filetype.split('/');
      fileExtension = '.' + fileExtension[1];

      var token = atob(sessionStorage.getItem("auth-token"));

      var isMultiToken = sessionStorage.getItem("multiTokenList");
      let FileUsers = '';

      if (isMultiToken == 'True') {
        FileUsers = atob(sessionStorage.getItem("mt-users"))
      }

      let addfileDto: AddfileDto = {
        AccessToken: token,
        DocumentName: filename,

        DocumentType: fileExtension,
        FileBytesString: this.pdfDataURL,
        UserId: this.dataServiceGet.TokenJWTdecode,
        GUID: guId,
        FolderParentId: this.SelectedFolderId,
        IsDeleted: false,
        IsSystemDependent: false,
        Path: guId + fileExtension,
        TagIds: tagslist,
        TagsList: TagsList,
        FileUser: FileUsers,
        Type: "storedoc",
      }

      this.tagsservice.sendFileAppendEvent(addfileDto);
      this.dialog.closeAll();
    }
  }

  FileUpload() {

    const files = this.selectedfilename;
    for (var i = 0; i < files.length; i++) {
      this.setupReader(files[i]);

    }
  }
  DownloadPdf(i) {

    this.RightClickServiceGet.Sendaddfileevent("true");
    let fileContent = this.DownloadFile[i];
    let fileName = fileContent.name
    let reader = new FileReader();
    this.uploadedDocument =
    {
      fileContent: fileContent
    }
    reader.readAsDataURL(this.uploadedDocument.fileContent);
    reader.onload = (x) => {

      let tmpDataURL = x.target.result;
      var jsonfile = JSON.stringify(tmpDataURL)
      var splitfilebyte = jsonfile.split(",")
      var filebyte = splitfilebyte[1].replace('"', '')
      const file = new Blob([filebyte], { type: 'application/pdf' });
      saveAs(tmpDataURL, fileName);
    }

  }

  DeletePdf(index) {
    this.selectedfilename.splice(index, 1);
    this.DownloadFile.splice(index, 1);
    if (this.selectedfilename.length == 0) {
      this.isShownList = false;
    }

  }

}
