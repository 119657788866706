<div class="col-md-12">
        <i class="fas fa-filter filtericon"></i>
         <span class="headerFilter">Filtre</span>
         <span id="finalmore" (click)="filterMore('Filter Record')"><u>Plus</u></span>
        
       
          <div class="circle"><span id="alertmsg"></span></div>
   
    <div class="filtersection">
      <app-typefilter></app-typefilter>
      <app-timelinefilter></app-timelinefilter>
      <app-tagsfilter></app-tagsfilter><br>
    </div>
<!-- <div>
  <button id="finalsub" class="btn btn-default"  (click)="OnSubmit()">
    <i class="fas fa-check"></i> Appliquer </button>
  <button id="finalcancel" class="btn btn-default"  (click)="refreshPage()"><i class="fa fa-times"></i> Réinitialiser </button>
 
</div> -->

</div>














