import { Component, OnInit } from '@angular/core';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { Subscription } from 'rxjs';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { MoreFilterComponent } from 'src/app/Modules/Filter/more-filter/more-filter.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/Modules/Shared/confirmation-dialog/confirmation-dialog.component';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  clickEventSubscription: Subscription;
  TypeEventSubscription: Subscription;
  constructor(private TypeFilter: TagsdataService,
    private file: FileApiService,
    private DataServiceSend: TagsdataService,
    private TagFilter: TagsdataService,
    
    public dialog: MatDialog,
    private searchFilter: TagsdataService) { 

          this.TypeEventSubscription = this.TypeFilter.getTypetoMoreFilterEvent().subscribe((data) => {
    this.SType = data;
   
         

    })
    this.TypeEventSubscription = this.TypeFilter.getTimetoMoreFilterEvent().subscribe((data) => {
      this.SDate = data;
     
           
  
      })
    }


  SType:string = null;
  SMoreValue:string
   STag:string = null;
   router;
 SDate:string = 'fromdate:null;todate:null';
 SearchValue:string



isShownSubmitButton:boolean = true;


  subscription: Subscription;

  ngOnInit(): void {
   
    this.subscription = this.TagFilter.Tagmes$.subscribe(tagdatas => {
      this.STag = tagdatas;
    

    });

    
  }

  newMessage(name) {
    this.searchFilter.FilterSearchdatas(name)
  }

  OnSubmit() {
    this.DataServiceSend.sendFilterResult("true");
    document.getElementById('alertmsg').innerHTML = " "
    let Flag: string;
    let Terms: string;
    let Limit;
    let Offset;
    this.STag = 'tags:' + this.STag;
    if (this.STag.includes("null")) {
      this.STag = null
    }
    this.SType = 'type:' + this.SType
    if (this.SType.includes("null")) {
      this.SType = null
    }
    this.SDate = this.SDate
    if (this.SType == null && this.STag == null && this.SDate == 'fromdate:null;todate:null') {
      document.getElementById('alertmsg').innerHTML = "Veuillez sélectionner des valeurs"
    }
    else {
      if (this.STag == null) {
        this.STag = 'tags:' + this.STag
      }
      if (this.SType == null) {
        this.SType = 'type:' + this.SType
      }
      if (this.SDate == null) {
        this.SDate = 'fromdate:' + this.SDate + ';'+ 'todate:' + this.SDate
      }
      Terms = this.SType + ';' + this.STag + ';' + this.SDate
      Flag = "AdvanceSearch";
      Limit = 0;
      Offset = 0;
       this.DataServiceSend.sendFilterTerms(Terms);

        this.file.GetSearchFiles(Flag ,Terms, Limit ,Offset).subscribe(data => {
          this.TypeFilter.sendTypetoMoreFilterEvent(null);
           this.TypeFilter.sendTimetoMoreFilterEvent(null);
           this.TypeFilter.sendTagtoMoreFilterEvent(null);
          this.TypeFilter.sendReloadFilter("false");
        this.newMessage(data);
        this.subscription = this.searchFilter.Search$.subscribe(x => this.SearchValue = x)
        this.SType = null;
        this.STag = null;
        this.SDate = 'fromdate:null;todate:null';
       
      });

    }

  }
  refreshPage() {
    window.location.reload();
  }

filterMore(FilterRecord:string)
 {
 
  if(this.SType !=null || this.SDate !='fromdate:null;todate:null'  || this.STag !=null)
  {
    
    this.dialog.open(ConfirmationDialogComponent,{
      width: '20%',
  
      height: '180px',
      data: 
      {
        FilterFiles: FilterRecord
      }
       
 
   
    });
  }
  else 
  {
    this.dialog.open(MoreFilterComponent,{
      width:'65%',
      height:'790px',
       
 
   
    });
  }
    
     
 }
}
