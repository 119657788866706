
import { FormControl, FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { $ } from 'protractor';
import { range } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TagfilterService } from 'src/app/Service/backend/tagfilter.service';
import { Component, OnInit } from '@angular/core';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { Subscription } from 'rxjs';
import { FileApiService } from 'src/app/Service/backend/file-api.service';

import { MatDialog } from '@angular/material/dialog';
import { faUnderline } from '@fortawesome/free-solid-svg-icons';
import { FilterdataService } from 'src/app/Service/Frontend/filterdata.service';

@Component({
  selector: 'app-more-filter',
  templateUrl: './more-filter.component.html',
  styleUrls: ['./more-filter.component.scss']
})
export class MoreFilterComponent implements OnInit {
  clickEventSubscription: Subscription;
  TypeEventSubscription: Subscription;
  option1: boolean = false;
  options2: boolean = false;
  option3: boolean = false;
  option4: boolean = false;
  option5: boolean = false;
  option6: boolean = false;
  option7: boolean = false;
  option8: boolean = false;
  option9: boolean = false;
  option10: boolean = false;

  constructor(
    private file: FileApiService,
    public dialog: MatDialog,
    private RightClickServiceGet: RightClickDataService,
    private tagfile: TagfilterService,
    private MoreFilter: TagsdataService,
    private DataServiceGet: TagsdataService,
    private DataServiceSend: TagsdataService,
    private filterServiceSend: FilterdataService) {

    this.clickEventSubscription = this.MoreFilter.getReloadFilter().subscribe((data) => {


      this.option1 = false;
      this.options2 = false;
      this.option3 = false;
      this.option4 = false;
      this.option5 = false;
      this.option6 = false;
      this.option7 = false;
      this.option8 = false;

      this.option9 = false;
      this.option10 = false;

    })
    this.clickEventSubscription = this.MoreFilter.getMoreClickEvent().subscribe((data) => {
      this.dialog.closeAll()

    })


  }
  Moretype: any[] = [];

  MoresTag: any[] = [];
  MoreSDate: string;

  searchtagdata: any[];
  selectedChips: any[] = [];
  selectedChipTags: any[] = [];
  systemtagList: any[];
  manualtagList: any[];
  usertagList: any[];
  TagIdsUncheck: any[] = [];

  fromdate = '';
  todate = '';
  subscription: Subscription;
  SearchValue: string

  dateOne
  dateTwo
  dateTwoFinal
  dateOneFinal
  dateOneOption


  SType: string = null;

  STag: string = null;

  SDate: string = 'fromdate:null;todate:null';


  ngOnInit(): void {



    this.file.GetListofTag().subscribe(alltag => {
      this.systemtagList = alltag.SystemTags
      this.manualtagList = alltag.ManualTags
      this.usertagList = alltag.UserTags

    })


  }

  MorekeyUp(event) {

    let tagsearchTextBox
    let el
    tagsearchTextBox = (document.getElementById('tagMoreFilterSearch') as HTMLInputElement).value;

    el = event.key;

    if (tagsearchTextBox.length >= 3) {

      this.tagfile.SearchTags(tagsearchTextBox).subscribe(data => {

        this.searchtagdata = data
      })
    }
    if (el === "Backspace") {
      this.searchtagdata = []

      this.file.GetListofTag().subscribe(alltag => {
        this.systemtagList = alltag.SystemTags
        this.manualtagList = alltag.ManualTags
        this.usertagList = alltag.UserTags
      })
    }


  }

  changeSelected(parameter: string, query: string, tagid: any) {
    let tags: any;
    this.TagIdsUncheck.push(tagid);
    const index = this.selectedChipTags.indexOf(query);
    if (index >= 0) {
      this.selectedChipTags.splice(index, 1);
      this.MoresTag = this.selectedChipTags
      document.getElementById('selectTag_' + tagid).innerHTML = " "

    } else {

      this.selectedChipTags.push(query);
      this.MoresTag = this.selectedChipTags
      document.getElementById('selectTag_' + tagid).innerHTML = "<i class='fa fa-check-circle'></i>"

    }

  }


  changeTypeSelected(query: string) {


    const index = this.selectedChips.indexOf(query);
    if (index >= 0) {
      this.selectedChips.splice(index, 1);


    } else {

      this.selectedChips.push(query);
      this.Moretype = this.selectedChips

    }
  }


  handleDate() {

    this.dateOne = this.fromdate

    if (this.dateOne.includes("-")) {
      this.dateOneFinal = this.dateOne.replace("-", "/");
      this.dateOneOption = this.dateOneFinal.replace("-", "/");
    }




  }
  handle() {

    let dateTwoOption
    this.dateTwo = this.todate

    if (this.dateTwo.includes("-")) {
      this.dateTwoFinal = this.dateTwo.replace("-", "/");
      dateTwoOption = this.dateTwoFinal.replace("-", "/");
    }


    this.MoreSDate = 'fromdate:' + this.dateOneOption + ';' + 'todate:' + dateTwoOption

  }

  removeMoreTagOnBackspace = function (event) {
    if (event.keyCode === 8) {
      this.TagSearcharr = this.arr;
    }
  };

  newMessage(name) {
    this.MoreFilter.FilterSearchdatas(name)
  }

  SubmitMore() {


    let Flag: string;
    let Terms: string;
    let Limit;
    let Offset;

    if (this.MoresTag.length == 0) {
      this.STag = 'tags:' + null
    }
    else {
      this.STag = 'tags:' + this.MoresTag;
      if (this.STag.includes("null")) {
        this.STag = null
      }
    }

    if (this.Moretype.length == 0) {
      this.SType = 'type:' + null
    }
    else {
      this.SType = 'type:' + this.Moretype
      if (this.SType.includes("null")) {
        this.SType = null
      }
    }


    if (this.MoreSDate == undefined) {
      this.SDate = this.SDate
    }

    else {
      this.SDate = this.MoreSDate
    }
    if (this.SType == null && this.STag == null) {
      document.getElementById('alertmsg').innerHTML = "Please select any values"
    }
    else {
      if (this.STag == null) {
        this.STag = 'tags:' + this.STag
      }

      if (this.SType == null) {
        this.SType = 'type:' + this.SType
      }



      Terms = this.SType + ';' + this.STag + ';' + this.SDate
      Flag = "AdvanceSearch";
      Limit = 25;
      Offset = 0;

      this.DataServiceSend.sendFilterTerms(Terms);
      this.filterServiceSend.FilterTerms = Terms;
      if (Terms != "type:null;tags:null;fromdate:null;todate:null") {
        this.file.GetSearchFiles(Flag, Terms, Limit, Offset).subscribe(data => {
          this.filterServiceSend.FilterGlobalFiles = data;
          this.MoreFilter.sendMoreClickEvent("false");
          this.newMessage(data);
          this.subscription = this.MoreFilter.Search$.subscribe(x => this.SearchValue = x)
          this.filterServiceSend.sendFilterFiles(data);
          this.DataServiceGet.sendFilterResult("true");

        })
      }
    }
  }


}
