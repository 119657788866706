import { Component, OnInit, } from '@angular/core';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TokenStorageService } from 'src/app/Service/backend/token-storage.service'

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  ResultatFilterSearch: Subscription;
  constructor(
    private tabsdata: TagsdataService,
    private getServiceData: TagsdataService,
    private readonly tokenStorageSrv: TokenStorageService,
    private readonly routeParams: ActivatedRoute
  ) {
    this.ResultatFilterSearch = this.getServiceData.getFilterResult().subscribe((result) => {
      this.selectedBg = false;
      this.inputName = this.tabnames[3];
      var tab = JSON.stringify(this.inputName);
      var value = tab.split("\"")
      this.newMessage(value[3]);
    })
  }

  message: string;
  tabname: any;
  inputName = '';
  subscription: Subscription;
  selectedBg: any;
  tabnames: any[] = [{ tabname: "Tous les documents" }, { tabname: "Mes documents" }, { tabname: "Documents partagés" }, { tabname: "Résultat personnalisé" }];

  ngOnInit(): void {

    if (this.routeParams.snapshot.paramMap.get("stToken") == null) {


      this.selectedBg = false;
      this.inputName = this.tabnames[3];
      var tab = JSON.stringify(this.inputName);
      var value = tab.split("\"")
      this.newMessage(value[3]);

      let MultiUser = this.tokenStorageSrv.getMultiTokens();

      if (MultiUser == 'True') {
        this.selectedBg = true;
        this.inputName = this.tabnames[0];
        var tab = JSON.stringify(this.inputName);
        var value = tab.split("\"")
        this.newMessage(value[3]);
        this.subscription = this.tabsdata.message$.subscribe(x => this.message = x)
      }

    }
    else {
      this.selectedBg = true;
      this.inputName = this.tabnames[0];
      var tab = JSON.stringify(this.inputName);
      var value = tab.split("\"")
      this.newMessage(value[3]);
      this.subscription = this.tabsdata.message$.subscribe(x => this.message = x)
    }
  }

  newMessage(tabname) {
    this.tabsdata.changeMessage(tabname)
  }

  tabsindex(i) {

    this.inputName = this.tabnames[i];
    var tab = JSON.stringify(this.inputName);
    var value = tab.split("\"")

    switch (i) {
      case 0:
        this.inputName = this.tabnames[i];
        this.newMessage(value[3]);
        break;
      case 1:
        this.inputName = this.tabnames[i];
        this.newMessage(value[3]);
        break;
      case 2:
        this.inputName = this.tabnames[i];
        this.newMessage(value[3]);
        break;
      case 3:
        this.inputName = this.tabnames[i];
        this.newMessage(value[3]);
        break;


    }

  }

}
