import { Component, OnInit } from '@angular/core';
import {RightClickDataService} from 'src/app/Service/Frontend/right-click-data.service'

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit {

  constructor(private RightClickServiceGet:RightClickDataService) { }
  pdfurl ;
  filename;
  filetype;
  
  ngOnInit(): void {
    this.pdfurl=this.RightClickServiceGet.RightClickFile;
    this.filename=this.RightClickServiceGet.RightClickFileType;
    this.filetype = this.filename.split(/[\s.]+/);
    this.filetype= this.filetype[ this.filetype.length-1];
  }

}
