<h2 class="btnnameHeader">
    <i class="fa fa-shopping-bag icons"></i>
    vos paniers
</h2>
<div class="leftColumn">
<div class="basket" *ngFor="let baskets of basketlist;let i=index" (mouseover)="deleteIconShow(i)"
 (mouseout)="deleteIconHide(i)" (click)="BasketNameClick(i);">   
    <div  class="basketNamelist">{{baskets.Name}}<span class="basketsdelete"  id="basket_{{i}}"><i class='fas fa-trash' style="color:rgb(209, 53, 53);" (click)="DeleteBasket(baskets.Name,i,'Removebasket')"></i></span> 
        
        
        
        </div>

 

</div>

</div>
<div class="rightColumn">
    <span class="basketNames">{{BasketName}}  </span>
    
    <table class="tbl">
        <thead class="tblHeader">
            <tr class="tblRowHead">
                
                <th class="txtDocName" style="width:16%;">
                    Nom
                </th>
                <th style="width:3%;">
                    Créé à
                </th>
                <th style="width:1%;">
                    Taille
                </th>
                <th style="width:1.5%;">
                    Supprimer
                </th>
            </tr>
            <!-- <mat-divider></mat-divider> -->
        </thead>
        
        <tbody id="loadDocuments">
            
            <!-- <mat-divider></mat-divider> -->
            <span id="NoFiles"></span>
            <tr  class="doclist" *ngFor="let file of Basketfiles;let i=index">

                

                <td class="documentname" style="width:15%" >
                    {{file.DocName}} <i class="fas fa-lock" style="color:grey; font-size: 12px;"
                    *ngIf="file.SystemDependent==true;"></i>
                    <br />
                   
                </td>
                <td class="docdate" style="width:1%; ">{{file.CreatedDate |date:"dd/MM/y hh:mm:ss"}}</td>
                 <td class="docSize" style="width:2%;">{{file.Size|filesize}}</td> 
                 <td class="docicon" style="width:2%;">


                    <i class="fas fa-minus-circle Deleteicon" (click)="Removefile(file.Id);"></i>
                    
                   

                </td>
            </tr>
        
        </tbody>
    </table>

</div>
<div>
    <span id="deleteProtectedFile"></span>
    <span id="NoFilesZip"></span>
    <button class="termineBtn btn btn-default" (click)="ZipDownload();"><b>Télécharger comme ZIP</b></button>
    <!-- <button class="termineBtn btn btn-default" (click)="DeleteFiles('DeleteBasketFiles');"><b>supprimer les fichiers</b></button> -->
</div>