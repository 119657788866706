
    <h4>Veuillez confirmer</h4>
    
    <div *ngIf="ReceivedValue.DeleteBasketFiles=='DeleteBasketFiles'">


        <div>
          Voulez-vous vraiment supprimer ?
        </div>
        <div class="modal-footer" style="border: none;">
          <button type="button" class="cancelButton btn btn-default" (click)="decline()">Annuler</button>
          <button type="button" class="okButton btn btn-default" (click)="accept()">supprimer</button>
        </div>
  </div>

  <div *ngIf="ReceivedValue.DeleteBasket=='Removebasket'">


    <div>
      Voulez-vous vraiment supprimer ?
    </div>
    <div class="modal-footer" style="border: none;">
      <button type="button" class="cancelButton btn btn-default"(click)="decline()">Annuler</button>
      <button type="button" class="okButton btn btn-default" (click)="accept()">supprimer</button>
    </div>
</div>


  <div *ngIf="ReceivedValue.FilterFiles=='Filter Record'">
    <div>
      voulez-vous réinitialiser le filtre ?
    </div>
    <div class="modal-footer" style="border: none;">
      <button type="button" class="cancelButton btn btn-default" (click)="CancelReset()">Annuler</button>
      <button type="button" class="okButton btn btn-default" (click)="OkReset()">D'accord</button>
    </div>
</div>

