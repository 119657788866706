import { Component, OnInit, ViewChild, ElementRef, HostListener, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { empty, Subject, Subscription } from 'rxjs';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { AddfileDto } from 'src/app/Dto/AddfileDto';
import { ConfigurationServiceService } from 'src/app/configuration-service.service'
import * as uuid from 'uuid';
import { ActivatedRoute } from '@angular/router';
import { isEmptyExpression, ThrowStmt } from '@angular/compiler';
import { chownSync, truncateSync } from 'fs';
import { TokenStorageService } from 'src/app/Service/backend/token-storage.service';
import { FilterdataService } from 'src/app/Service/Frontend/filterdata.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BasketsIndexComponent } from '../../Shared/baskets-index/baskets-index.component';
import { CdkDragDrop, copyArrayItem, moveItemInArray, CdkDragStart } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-files-index',
  templateUrl: './files-index.component.html',
  styleUrls: ['./files-index.component.scss'],
})

export class FilesIndexComponent implements OnInit {
  clickEventSubscription: Subscription;
  FolderEventSubscription: Subscription;
  RightClickSubcsription: Subscription;
  ResultatFilterTerms: Subscription;
  BasketCount: Subscription;
  FileMoveSubscription: Subscription;
  message: string;
  tabfilename: string;
  subscription: Subscription;
  UserFilesByTags: Subscription;
  SearchFilesByAdvance: Subscription;
  UnCheckBoxsubscription: Subscription;
  UnCheckBoxForZip: Subscription;
  FilterFiles: Subscription;
  urlSafe: SafeResourceUrl;
  pdfurl;
  filenames: any;
  value: any;
  rowClicked;
  el: any;
  DropFolderId: any;
  BasketCountId = 0;
  sortDir = 1;
  resultFolder;
  basketCount: any;
  fileDetailsBasket: any[] = [];
  resultFiles: any[] = [];
  SelectedFileArray: any[] = [];
  Taglistfiles: any[] = [];
  FilterDataArray: any[] = [];
  searchTextBox;
  FilterTermsArray: any[] = [];
  FilterSearchsArray: any[] = [];
  RecentSearchStringArray: any[] = [];
  show: boolean = false;
  // show:boolean;
  isShown: boolean = false;
  isShownprogress: boolean = false;
  Foldernames: string;
  selectedFileId: any[] = [];
  bcElements: any[] = [];
  BasketList: any[] = [];
  BasketFiles: any[] = [];
  DropFilesFolder: any[] = [];
  DropFileIds: any[] = [];
  FileName;
  ReloadFileSubscription: Subscription;
  checks = false;
  CheckedCount;
  rightClickMenu: any;
  systemdependent: any;
  systemdependentArray: any[] = [];
  DragpdfDataURL;
  pdfDataURL: string | ArrayBuffer;
  SelectedFolderId = 0;
  SelectedParentId = 0;
  uploadedDocument: any;
  selectedTagIds: any;
  progressvar;
  UserIdsArray: any[] = [];
  dropfiles: any[] = [];
  files: any[] = [];
  filearray: any[] = [];
  resultantFiles: any[] = [];
  resultantSearchFiles: any[] = [];
  checkcountarray: any[] = [];
  SelectedFileForDelete: any[] = [];
  Folderarr: any[];
  FolderId: any;
  limitValue = 25;
  offsetvalue = 0;
  FilterlimitValue = 25;
  Filteroffsetvalue = 0;
  SearchlimitValue = 25;
  Searchoffsetvalue = 0;
  SearchFlag: any;
  SelectedAllCheckBox = false;
  isScroll = false;
  scrollHeight;
  LoadFilesScroll = true;
  LoadFilterScroll = false;
  Refresh = false;
  SearchNofiles = false;
  click: boolean = true;
  Removeclass

  private readonly onDestroy = new Subject<void>();
  dragging: boolean;
  constructor(private RightClickServiceSend: RightClickDataService,
    private RightClickServiceGet: RightClickDataService,
    private tabsdata: TagsdataService,
    private file: FileApiService, private searchFilter: TagsdataService,
    private dataServiceGet: TagsdataService,
    private DataServiceSend: TagsdataService,
    public sanitizer: DomSanitizer,
    private ConfigService: ConfigurationServiceService,
    private readonly routeParams: ActivatedRoute,
    private readonly tokenStorageSrv: TokenStorageService,
    public dialog: MatDialog,
    private filterServiceSend: FilterdataService,
    private filterServiceGet: FilterdataService,
    private toastr: ToastrService) {



    // this.FilterFiles=this.filterServiceGet.getFilterFiles().subscribe((data)=>
    // {
    //   let filesData;
    //   filesData=this.filterServiceGet.FilterGlobalFiles;
    //   if(data==true)
    //   {

    //   if(filesData.Files.length==0)
    //   {
    //   document.getElementById("Nofiles").innerHTML="Vous n’avez pas encore ajouté de documents"
    //   }
    //   else{
    //    document.getElementById("Nofiles").innerHTML=" "
    //    this.filearray=filesData.Files;
    //    this.resultantFiles=filesData.Files;
    //   }
    // }
    // else 
    // {
    // console.log(data);

    // // let val = filesData.Files.filter((element) => {
    // //   console.log(element)
    // //   return element.Tags.filter(function (item) {
    // //     console.log(item)
    // //       return item.TagName == rm
    // //   });
    // // });

    // // this.filearray=filesData.Files.forEach((element) => {
    // //   element.Tags = element.Tags.filter(function (item) {
    // //     console.log(item)
    // //       return item.TagName == rm
    // //   })
    // // })

    // // this.filearray=filesData.Files.filter(element => {
    // //  return element.Tags.filter(function(elements) {

    // //     return elements.TagId == 1
    // // })




    // // })

    // // const courses = [1, 6, 3];
    // // let datas=filesData.Files
    // // const r = datas.filter(d => d.Tags.every(c => courses.includes(c.TagId)));
    // // console.log(r);
    // //console.log(val)
    // this.FilterDataArray.push(data)
    // console.log(this.FilterDataArray);
    // let datalength=this.FilterDataArray.length;
    // let x;
    // let y;
    // let result;
    // let rm

    // //x=dataString.split(":");

    // x=this.FilterDataArray[datalength-1].split(":");
    // console.log(x[1]);
    // y=x[1].split(',');

    // result=filesData.Files;
    // console.log(y.length)

    // for(let i=0;i<y.length;i++)
    // { 

    //   result =result.filter((element) => {
    //     if(x[0]=="tags")
    //     {
    //   return element.Tags.filter(function (item) {

    //       console.log(y[0])
    //       return item.TagName === y[i]

    //   }).length != 0;
    //     }
    //     else if(x[0]=="type")
    // {

    //     rm=element.Name.split('.')
    // // return rm[1]==y[i];
    // return y.some(function (o2) {
    //   return rm[1] === o2; // return the ones with equal id
    // });

    // }
    // });
    // }
    // if(result.length==0)
    // {
    // document.getElementById("Nofiles").innerHTML="Vous n’avez pas encore ajouté de documents"
    // }
    // else{
    //  document.getElementById("Nofiles").innerHTML=" "
    //  this.filearray=result;
    //  this.resultantFiles=result;
    // }
    // console.log(this.filearray)
    // }
    // });


    this.dataServiceGet.getFileById().subscribe(filedata => {

      if (filedata == "DeleteFile" || filedata == "ShareFile" || filedata == "RenameFile" || filedata == "TagAssignFile") {
        let fileIds = this.RightClickServiceSend.FileIdShare;
        if (fileIds.length > 1) {
          for (let i = 0; i < fileIds.length; i++) {


            this.file.FileById(fileIds[i]).subscribe(data => {

              if (data.resData.length == 0 && filedata == "DeleteFile") {

                let index = this.filearray.findIndex(record => record.Id === fileIds[i]);
                this.filearray.splice(index, 1);
              }
              else if (data.resData.length > 0 && (filedata == "ShareFile" || filedata == "TagAssignFile")) {
                for (var j = 0; j < this.filearray.length; j++) {
                  if (fileIds[i] == this.filearray[j].Id) {

                    this.filearray[j].Tags = data.resData[0].Tags;

                  }
                }

              }

            })
          }
        }
        else {
          let id = this.RightClickServiceGet.RightClickFileId;
          this.file.FileById(id).subscribe(data => {

            if (data.resData.length == 0 && filedata == "DeleteFile") {

              let index = this.filearray.findIndex(record => record.Id === id);
              this.filearray.splice(index, 1);


            }

            else if (data.resData.length > 0 && (filedata == "ShareFile" || filedata == "TagAssignFile")) {
              for (var j = 0; j < this.filearray.length; j++) {
                if (id == this.filearray[j].Id) {

                  this.filearray[j].Tags = data.resData[0].Tags;
                }
              }
            }

            else if (data.resData.length > 0 && filedata == "RenameFile") {
              this.selectedFileId.push(id);
              this.RightClickServiceSend.FileIdShare = this.selectedFileId;
              for (var j = 0; j < this.filearray.length; j++) {
                if (id == this.filearray[j].Id) {
                  this.filearray[j].DocumentName = data.resData[0].DocumentName;
                }
              }

            }

          })


        }
      }
      else if (filedata == "TagUpdate") {

        for (let k = 0; k < this.filearray.length; k++) {
          for (var index in this.filearray[k].Tags) {

            if (this.filearray[k].Tags[index].TagId == this.dataServiceGet.EditedTagId) {
              this.file.FileById(this.filearray[k].Id).subscribe(data => {
                this.filearray[k].Tags = data.resData[0].Tags;
              });

            }

          }

        }
      }

      else {
        for (let i = 0; i < filedata.length; i++) {
          this.file.FileById(filedata[i]).subscribe(data => {

            if (data.resData.length == 0) {
              for (let i = 0; i < filedata.length; i++) {

                let index = this.filearray.findIndex(record => record.Id === filedata[i]);
                this.filearray.splice(index, 1);

              }
            }
          })
        }
      }
    })




    this.clickEventSubscription = this.dataServiceGet.getBtnModalHide().subscribe((data) => {
      if (data == "true") {
        this.show = !this.show;
      }

    })


    this.FileMoveSubscription = this.RightClickServiceGet.getFileMoveEvent().subscribe((data) => {
      if (this.CheckedCount > 1) {

        for (let j = 0; j < this.selectedFileId.length; j++) {
          this.DropFileIds.push({ Id: this.selectedFileId[j] })
        }

        this.file.FileDropIntoFolder(this.DropFileIds, 0).subscribe(data => {
          if (data == "Success") {
            for (let i = 0; i < this.selectedFileId.length; i++) {

              let index = this.filearray.findIndex(record => record.Id === this.selectedFileId[i]);
              this.filearray.splice(index, 1);
              this.CheckedCount = 0;
            }
            this.selectedFileId = [];

          }
          else {

            this.toastr.error("Vous n'êtes pas autorisé à déplacer ce fichier")
          }
        });

        this.DropFolderId = 0;
        this.DropFileIds = [];
      }
      else {
        let fileId = this.RightClickServiceGet.RightClickFileId;
        this.DropFileIds.push({ Id: this.RightClickServiceGet.RightClickFileId })

        this.file.FileDropIntoFolder(this.DropFileIds, 0).subscribe(data => {
          if (data == "Success") {
            let index = this.filearray.findIndex(record => record.Id === fileId);
            this.filearray.splice(index, 1);
          }
          else {
            this.toastr.error("Vous n'êtes pas autorisé à déplacer ce fichier")
          }
        });

        this.DropFolderId = 0;
        this.DropFileIds = [];
      }
    })


    this.FilterFiles = this.filterServiceGet.getFilterFiles().subscribe((data) => {
      let scrolldiv = document.getElementById("loadDocuments");
      scrolldiv.scrollTop = 0;
      let filesData = data;
      if (filesData.Files.length == 0) {
        this.filearray = filesData.Files;
        this.resultantSearchFiles = filesData.Files;
        this.SearchNofiles = true;
        document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents"
      }
      else {
        document.getElementById("Nofiles").innerHTML = " "
        this.SearchNofiles = false;
        this.filearray = filesData.Files;
        this.resultantSearchFiles = filesData.Files;

        this.LoadFilterScroll = true;
        this.FilterlimitValue = 25;
        this.Filteroffsetvalue = 0;
      }


    });


    this.BasketCount = this.RightClickServiceGet.getFileinBasket().subscribe((data) => {

      let id = data.split('_');

      if (id[0] == 'add') {
        if (this.BasketFiles.includes(id[1])) {
          document.getElementById('basFile' + id[1]).innerHTML = "";
        }
        else {
          document.getElementById('basFile' + id[1]).innerHTML = "<i class='fa fa-shopping-bag' style='color:rgb(223, 72, 72); font-size: 12px; margin-left: 3px;'></i>";

        }
      }

      else if (id[0] == 'remove') {
        document.getElementById('basFile' + id[1]).innerHTML = " ";
        let basket = this.tokenStorageSrv.getBasketList();
        for (let i = 0; i < basket.length; i++) {
          for (let j = 0; j < basket[i].files.length; j++) {
            if (basket[i].files[j].Id == id[1]) {
              document.getElementById('basFile' + id[1]).innerHTML = "<i class='fa fa-shopping-bag' style='color:rgb(223, 72, 72); font-size: 12px; margin-left: 3px;'></i>";
              break;
            }

          }
        }


      }



    })

    this.BasketCount = this.dataServiceGet.getBasketCount().subscribe((names) => {
      let Basket;
      if (this.tokenStorageSrv.getBasketList() != null) {
        this.BasketCountId = this.tokenStorageSrv.getBasketList().length;
        this.BasketCountId += 1;
        this.BasketList = this.tokenStorageSrv.getBasketList();
        Basket = {
          Id: this.BasketCountId,
          Name: names,
          files: []
        }
        this.BasketList.push(Basket);
        this.tokenStorageSrv.saveBasketList(this.BasketList);
        //this.tokenStorageSrv.saveBasketCountId(this.BasketCountId);
        this.basketCount = this.BasketList.length;
        if (this.basketCount > 0) {
          this.click = false;
          document.querySelector("#bagIcons").classList.add("addedcolour");
        }
        else {
          // document.querySelector("#bagIcons").classList.add("bagicon");
        }
      }
      else {

        this.BasketCountId += 1;
        Basket = {
          Id: this.BasketCountId,
          Name: names,
          files: []
        }
        this.BasketList.push(Basket);
        this.tokenStorageSrv.saveBasketList(this.BasketList)
        //this.tokenStorageSrv.saveBasketCountId(this.BasketCountId);
        this.basketCount = this.BasketList.length;
        if (this.basketCount > 0) {
          this.click = false;
          document.querySelector("#bagIcons").classList.add("addedcolour");
        }
      }

    })
    this.BasketCount = this.dataServiceGet.getBasCount().subscribe((data) => {
      this.basketCount = this.tokenStorageSrv.getBasketList().length;
      if (this.basketCount == 0) {
        this.click = true;
        this.Removeclass = document.querySelectorAll(".bagicon");
        //console.log(this.Removeclass);
        [].forEach.call(this.Removeclass, function (el) {
          el.classList.remove("addedcolour");
        });
      }
      let basket = this.tokenStorageSrv.getBasketList();
      if (data >= 0) {
        for (let j = 0; j < basket[data].files.length; j++) {
          document.getElementById('basFile' + basket[data].files[j].Id).innerHTML = " ";

        }


      }

    })
    this.UserFilesByTags = this.dataServiceGet.getFileByTags().subscribe((files) => {

      if (files.Files.length == 0) {
        document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents"
      }
      else {
        document.getElementById("Nofiles").innerHTML = " "
        this.filearray = files.Files;
        this.resultantFiles = files.Files;
      }
    })


    this.SearchFilesByAdvance = this.dataServiceGet.getSearchFileByadvanceSearch().subscribe((files) => {
      if (files.Files.length == 0) {
        document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents"
      }
      else {
        document.getElementById("Nofiles").innerHTML = " "
        this.filearray = files.Files;
        this.resultantFiles = files.Files;
      }
    })


    this.clickEventSubscription = this.tabsdata.getFileAppendEvent().subscribe((data) => {
      this.file.uploadFile(data).subscribe(datas => {
        this.filearray.unshift(datas.Files[0])
      })
    })


    this.FolderEventSubscription = this.tabsdata.getFolderAppendEvent().subscribe((folderData) => {
      this.Folderarr.push(folderData.Folders[0]);
    })

    this.clickEventSubscription = this.dataServiceGet.getReloadFilter().subscribe((data) => {
      this.tabsdata.sharedFolderId = 0;
      this.tabsdata.FolderParentId = 0;
      this.bcElements = [];
      // this.filearray = [];
      // this.FilesReload(25, 0);
    })

    //   this.clickEventSubscription = this.dataServiceGet.getReloadFilter().subscribe((data) => {

    //     this.tabsdata.sharedFolderId = 0;
    //   this.tabsdata.FolderParentId = 0;
    //   this.bcElements = [];
    //   this.filearray = [];
    //   this.FilesReload(50, 0);

    // })


    this.RightClickSubcsription = this.RightClickServiceGet.getFileMoveToFolder().subscribe((data) => {
      console.log(data)
      for (let i = 0; i < data.length; i++) {
        this.file.FileById(data[i]).subscribe(files => {
          for (var j = 0; j < this.filearray.length; j++) {
            if (data[i] == this.filearray[j].Id) {
              this.filearray[j].FilePath = files.resData[0].FilePath;
            }
          }

        })
      }

    })



    this.RightClickSubcsription = this.RightClickServiceGet.getRightClickEvent().subscribe((rightclick) => {
      var contextelement = document.getElementById("context-menu");
      contextelement.classList.remove("active");

    })

    this.UnCheckBoxForZip = this.RightClickServiceGet.getUnCheckEventforZip().subscribe((UnCheckZip) => {
      this.checks = false;
      this.SelectedFileArray = [];
      this.CheckedCount = document.querySelectorAll("input:checked")
      for (var i = 0; i < this.CheckedCount.length; i++) {
        this.CheckedCount[i].checked = false;
      }
      this.CheckedCount = '';
    });

    this.RightClickServiceGet.getUncheckEventforBasket().subscribe((Uncheck) => {
      this.checks = false;
      this.CheckedCount = document.querySelectorAll("input:checked")
      for (var i = 0; i < this.CheckedCount.length; i++) {
        this.CheckedCount[i].checked = false;
      }
      this.CheckedCount = '';
      this.fileDetailsBasket = [];
    });

    this.UnCheckBoxsubscription = this.RightClickServiceGet.getUnCheckEvent().subscribe((Uncheck) => {
      this.checks = false;
      this.selectedFileId = [];
      this.SelectedFileForDelete = [];
      this.CheckedCount = document.querySelectorAll("input:checked")
      for (var i = 0; i < this.CheckedCount.length; i++) {
        this.CheckedCount[i].checked = false;
      }
      this.CheckedCount = '';
      this.isScroll = false;

      this.offsetvalue = 0;

      if (Uncheck == "false") {
        this.gotoTop();
        this.FilesReload(25, 0);
      }
    })

    this.ReloadFileSubscription = this.RightClickServiceGet.GetReloadFileRequest().subscribe((data) => {
      if (data == 'true') {
        this.isScroll = false;
        this.gotoTop();
        this.offsetvalue = 0;
        this.FilesReload(25, 0);
      }
      else if (data == 'false') {
        this.FolderReload();
      }
    })

    this.ResultatFilterTerms = this.dataServiceGet.getFilterTerms().subscribe((result) => {
      this.RecentSearchStringArray.push(result);
      this.tokenStorageSrv.saveSearchTerms(this.RecentSearchStringArray);

      let x;
      let terms;
      let termsNames;
      this.FilterTermsArray = [];
      x = result.split(';');

      for (let i = 0; i < x.length; i++) {
        if (x[i] != 'null') {
          terms = x[i].split(':');
          if (terms[1] != "null") {
            termsNames = terms[1].split(',');
            for (let j = 0; j < termsNames.length; j++) {
              this.FilterTermsArray.push(termsNames[j]);

            }
          }
        }
      }
      this.FilterSearchsArray.push(this.FilterTermsArray);
      this.tokenStorageSrv.saveSearchHistory(this.FilterSearchsArray);

    })

  }


  ngOnInit(): void {
    console.log("ok");

    this.tabsdata.sharedFolderId = 0;

    this.subscription = this.tabsdata.message$.subscribe(tabnames => {
      this.gotoTop();
      var contextelement = document.getElementById("context-menu");
      contextelement.classList.remove("active");
      this.message = tabnames;
      this.RightClickServiceSend.sendUncheckEvent("true");
      this.BasketFiles = [];
      if (this.tokenStorageSrv.getBasketList() != null) {
        this.basketCount = this.tokenStorageSrv.getBasketList().length;
        if (this.basketCount == 0) {
          this.click = true;
          this.Removeclass = document.querySelectorAll(".bagicon");
          //console.log(this.Removeclass);
          [].forEach.call(this.Removeclass, function (el) {
            el.classList.remove("addedcolour");
          });
        }
        else {
          this.click = false;
          document.querySelector("#bagIcons").classList.add("addedcolour");
        }


        let basket = this.tokenStorageSrv.getBasketList();
        for (let i = 0; i < basket.length; i++) {
          for (let j = 0; j < basket[i].files.length; j++) {
            if (this.BasketFiles.includes(basket[i].files[j].Id)) {
              //console.log('')
            }
            else {
              // document.getElementById('basketFile'+r[i].files[j].Id).innerHTML=" ";
              this.BasketFiles.push(basket[i].files[j].Id)
            }
          }


        }
      }
      //console.log(this.BasketFiles)
      switch (this.message) {
        case "Tous les documents":
          this.tabfilename = "AllFiles";

          break;
        case "Mes documents":
          this.tabfilename = "MyFiles";
          break;
        case "Documents partagés":
          this.tabfilename = "SharedFiles"

          break;
        case "Résultat personnalisé":

          if ((this.filterServiceGet.FilterTerms == null && this.LoadFilterScroll == true && this.resultantSearchFiles.length == 0) || (this.filterServiceGet.FilterTerms == null && this.LoadFilesScroll == true && this.resultantSearchFiles.length == 0)) {
            this.filearray = [];
          }
          else {

            this.gotoTop();
            this.LoadFilesScroll = false;
            if (this.resultantSearchFiles.length > 0 && this.SearchNofiles == false) {
              this.filearray = this.resultantSearchFiles;
              document.getElementById("Nofiles").innerHTML = " ";

            }
            else if (this.SearchNofiles == true && this.resultantSearchFiles.length == 0) {

              this.filearray = this.resultantSearchFiles;

              document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents"
            }
          }

          // if(this.filterServiceGet.FilterFilesLoad==true)
          // {
          // this.gotoTop();
          // }
          // else{
          //   this.filearray=[];
          // }
          this.Refresh = false;
          if ((this.routeParams.snapshot.paramMap.get("stToken") != null)) {
            this.FilterTermsArray = [];
            this.FilterSearchsArray = [];
            if (this.filearray.length == 0) {
              document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents";
            }
            else {
              document.getElementById("Nofiles").innerHTML = "";
            }
            let searches = this.tokenStorageSrv.getSearchHistory();
            //let rm=xx.split(',');
            if (searches != null) {
              for (let j = 0; j < searches.length; j++) {
                this.FilterTermsArray = [];
                for (let i = 0; i < searches[j].length; i++) {
                  this.FilterTermsArray.push(searches[j][i]);
                }
                this.FilterSearchsArray.push(this.FilterTermsArray);
              }
            }




          }
          else if ((this.routeParams.snapshot.paramMap.get("stToken") == null && this.message == "Résultat personnalisé")) {

            if (this.resultantFiles.length == 0) {
              document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents"
            }
            else {
              document.getElementById("Nofiles").innerHTML = " "
              let search = this.tokenStorageSrv.getSearchHistory();
              this.FilterSearchsArray.push(search);
              this.filearray = this.resultantFiles;
            }
          }
          break;
      }
      this.offsetvalue = 0;
      this.isScroll = false;
      this.Folderarr = [];
      this.resultFiles = [];
      if ((this.message != "Résultat personnalisé") || this.Refresh == true) {
        this.FilesReload(25, 0);
      }
      // else if((this.routeParams.snapshot.paramMap.get("stToken")==null) && (this.tabfilename == "MyFiles" || this.tabfilename == "SharedFiles"))
      // {
      //   this.FilesReload(50, 0);
      // }

    });

    this.subscription = this.searchFilter.Search$.subscribe(datas => {
      this.LoadFilesScroll = false;
      this.filearray = datas.Files;
      if (this.filearray.length == 0) {
        document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents"
      }
      else {
        document.getElementById("Nofiles").innerHTML = " "
      }
    });
    this.SelectedFolderId = this.dataServiceGet.sharedFolderId;
  }
  gotoTop() {
    let scrolldiv = document.getElementById("loadDocuments");
    scrolldiv.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  showBtnModal() {
    this.show = !this.show;

  }
  FilesReload(limit, offset) {
    this.limitValue = limit;
    this.offsetvalue = offset;
    this.CheckedCount = 0;
    this.selectedFileId = [];
    this.SelectedFolderId = 0;
    document.getElementById("Nofiles").innerHTML = " "
    this.LoadFilesScroll = true

    this.file.GetTagsApi().subscribe(data => {
      this.RightClickServiceSend.RightClickTagAttribute = data.Tags;
      this.DataServiceSend.TagsLoad = data.Tags;
    })

    this.file.GetListofTag().subscribe(alltag => {
      this.DataServiceSend.TagsSystem = alltag.SystemTags
      this.DataServiceSend.TagsUser = alltag.UserTags

    })

    this.tabsdata.sharedFolderId = 0;
    this.tabsdata.FolderParentId = 0;
    this.bcElements = [];
    this.file.GetFolders().subscribe(folders => {
      this.resultFolder = folders.Folders;
      if (this.tabfilename == "AllFiles" || this.tabfilename == "MyFiles") {
        this.Folderarr = this.resultFolder.filter(function (e) {
          return e.ParentId == 0
        })
      }

    })

    this.file.getfiles(this.tabfilename, this.limitValue, this.offsetvalue).subscribe(data => {
      if (this.isScroll == true) {
        for (var i = 0; i < data.Files.length; i++) {
          this.resultFiles.push(data.Files[i])

        }
      }
      else {
        this.resultFiles = [];
        this.resultFiles = data.Files;

      }
      if (this.tabfilename == "AllFiles" || this.tabfilename == "MyFiles") {
        // this.filearray = this.resultFiles.filter(function (e) {
        //     return e.FolderId == 0
        // })
        this.filearray = this.resultFiles;
      }
      else {
        this.filearray = this.resultFiles;
      }
    })
  }
  FolderReload() {
    let folderid;
    this.file.GetFolders().subscribe(folders => {
      this.resultFolder = folders.Folders;
      folderid = this.tabsdata.sharedFolderId;
      this.Folderarr = this.resultFolder.filter(function (e) {
        return e.ParentId == folderid
      })

    })
  }
  SelectAllCheckbox(event) {

    if (event.target.checked == true) {
      this.checks = true;
      this.CheckedCount = document.getElementsByClassName("checks").length;
      this.CheckedCount = this.CheckedCount;
      this.SelectedAllCheckBox = true;
      for (var i = 0; i < this.filearray.length; i++) {
        this.systemdependentArray.push(this.filearray[i].IsSystemDependent)
        this.selectedFileId.push(this.filearray[i].Id);
        this.SelectedFileArray.push({ LinkId: this.filearray[i].LinkId });
        this.SelectedFileForDelete.push({ Name: this.filearray[i].Name });
        this.RightClickServiceSend.FileName = this.SelectedFileForDelete;
        this.RightClickServiceSend.RightClickZipfileLinkId = this.SelectedFileArray;
        this.fileDetailsBasket.push(this.filearray[i]);
      }
    }
    else {
      this.SelectedAllCheckBox = false;
      this.checks = false;
      this.CheckedCount = '';
      this.selectedFileId = [];
      this.SelectedFileArray = [];
      this.SelectedFileForDelete = [];
      this.fileDetailsBasket = [];
      this.systemdependentArray.splice(0)

    }
  }
  getCheckValue(event, i: any) {

    if (event.target.checked == true) {

      this.CheckedCount = document.querySelectorAll("input:checked").length;
      this.CheckedCount = this.CheckedCount;
      this.SelectedFileArray.push({ LinkId: this.filearray[i].LinkId });
      this.RightClickServiceSend.RightClickZipfileLinkId = this.SelectedFileArray;
      this.selectedFileId.push(this.filearray[i].Id);
      this.SelectedFileForDelete.push({ Name: this.filearray[i].Name });
      this.fileDetailsBasket.push(this.filearray[i]);
      if (this.filearray[i].IsSystemDependent == true) {

        this.systemdependentArray.push(true);
      }
      else {
        this.systemdependentArray.push(false);

      }
    }
    else {
      this.CheckedCount = document.querySelectorAll("input:checked").length;
      if (this.SelectedAllCheckBox == true) {
        this.CheckedCount = this.CheckedCount - 1;
      }
      else {
        this.CheckedCount = this.CheckedCount;
      }

      const LinkId = this.SelectedFileArray.indexOf(this.filearray[i].LinkId);
      this.SelectedFileArray.splice(LinkId, 1);
      const indexId = this.selectedFileId.indexOf(this.filearray[i].Id);
      this.selectedFileId.splice(indexId, 1);
      const filenameDelete = this.SelectedFileForDelete.indexOf(this.filearray[i].Name);
      this.SelectedFileForDelete.splice(filenameDelete, 1);
      const basketFileDelete = this.fileDetailsBasket.indexOf(this.filearray[i]);
      this.fileDetailsBasket.splice(basketFileDelete, 1);
      if (this.filearray[i].IsSystemDependent == true) {
        const index = this.systemdependentArray.indexOf(this.filearray[i].IsSystemDependent);
        this.systemdependentArray.splice(index, 1);
      }
      else {
        const index = this.systemdependentArray.indexOf(this.filearray[i].IsSystemDependent);

        this.systemdependentArray.splice(index, 1);

      }
    }

  }

  scrollToBottom(): void {

    try {
      let scrolldiv = document.getElementById("loadDocuments");
      this.scrollHeight = scrolldiv.scrollTop + scrolldiv.style.height;
      if (this.LoadFilesScroll == true || this.LoadFilterScroll == true) {
        if (parseInt(this.scrollHeight) >= scrolldiv.scrollHeight - 950) {
          this.offsetvalue = this.offsetvalue + this.limitValue
          this.isScroll = true;
          if ((this.message != "Résultat personnalisé") || this.Refresh == true) {
            console.log("condition ulla");
            this.FilesReload(this.limitValue, this.offsetvalue);

          }

          if ((this.message == "Résultat personnalisé" && this.filterServiceGet.FilterTerms != null && this.Refresh == false)) {
            let Flag;
            let Terms;

            Flag = "AdvanceSearch";
            Terms = this.filterServiceGet.FilterTerms;
            if (Terms != "type:null;tags:null;fromdate:null;todate:null") {

              this.Filteroffsetvalue = this.Filteroffsetvalue + this.FilterlimitValue;
              this.filterServiceSend.FilterTerms = Terms;
              this.file.GetSearchFiles(Flag, Terms, this.FilterlimitValue, this.Filteroffsetvalue).subscribe(data => {


                for (var i = 0; i < data.Files.length; i++) {
                  this.filearray.push(data.Files[i])

                }



              });

            }
            else {

              this.Filteroffsetvalue = this.Filteroffsetvalue + this.FilterlimitValue;
              this.filterServiceSend.FilterTerms = Terms;
              this.file.GetSearchFiles(Flag, Terms, this.FilterlimitValue, this.Filteroffsetvalue).subscribe(data => {


                for (var i = 0; i < data.Files.length; i++) {
                  this.filearray.push(data.Files[i])

                }



              });

            }
          }
        }
      }
      else if (this.LoadFilesScroll == false) {
        if (parseInt(this.scrollHeight) >= scrolldiv.scrollHeight - 730) {
          this.Searchoffsetvalue = this.Searchoffsetvalue + this.SearchlimitValue
          this.isScroll = true;
          if (this.SearchFlag == "HashSearch") {
            this.file.GetSearchFiles(this.SearchFlag, this.searchTextBox, this.SearchlimitValue, this.Searchoffsetvalue).subscribe(hash => {

              //this.filearray = hash.Files;
              for (var i = 0; i < hash.Files.length; i++) {
                this.filearray.push(hash.Files[i])

              }
            })
          }
          else if (this.SearchFlag == "BasicSearch") {
            this.file.GetSearchFiles(this.SearchFlag, this.searchTextBox, this.SearchlimitValue, this.Searchoffsetvalue).subscribe(data => {

              //this.filearray = data.Files;
              for (var i = 0; i < data.Files.length; i++) {
                this.filearray.push(data.Files[i])

              }

            })
          }
        }
      }
      var contextelement = document.getElementById("context-menu");
      contextelement.classList.remove("active");
    } catch (err) { }
  }



  @HostListener('dragover', ['$event']) onDragover(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    this.dropfiles = []
    let fileOver
    evt.preventDefault();
    // evt.target.classList.add('dragging');
    evt.stopPropagation();
    fileOver = false;
    const files = evt.dataTransfer.files;
    const dropfiles = files
    for (const item of dropfiles) {
      item.progress = 0;
      this.dropfiles.push(item);
    }
    for (var i = 0; i < files.length; i++) {
      this.setupReader(files[i]);
    }
  }

  setupReader(file) {
    let dropfilename
    let dropfiletype
    let dropfilesize

    dropfilename = file.name;
    dropfiletype = file.type;
    dropfilesize = file.size;
    let reader = new FileReader();
    this.uploadedDocument = {
      file: file
    }
    reader.readAsDataURL(this.uploadedDocument.file);
    reader.onload = (x) => {
      // called once readAsDataURL is completed
      let tmpDataURL = x.target.result;
      var jsonfile = JSON.stringify(tmpDataURL)
      var splitfilebyte = jsonfile.split(",")
      var filebyte = splitfilebyte[1].replace('"', '')
      this.DragpdfDataURL = filebyte
      let jsonTagIds = JSON.stringify(this.selectedTagIds);
      const guId = uuid.v4();
      let TagsList = [{
        TagId: '0',
        TagName: 'storedoc',
        Color: '#808080',
        IsReadOnly: true,
        TagUsers: null
      }];
      this.uploadFilesSimulator(0)
      this.isShownprogress = true;
      TagsList.forEach(item => item.TagId);
      let jsonTagsList = JSON.stringify(TagsList);

      var fileExtension = dropfiletype.split('/');

      fileExtension = '.' + fileExtension[1];

      var token = atob(sessionStorage.getItem("auth-token"));
      var isMultiToken = sessionStorage.getItem("multiTokenList");
      let FileUsers = '';

      if (isMultiToken == 'True') {
        FileUsers = atob(sessionStorage.getItem("mt-users"))
      }

      let addfileDto: AddfileDto = {
        AccessToken: token,
        DocumentName: dropfilename,
        DocumentType: fileExtension,
        FileBytesString: this.DragpdfDataURL,
        UserId: this.dataServiceGet.TokenJWTdecode,
        GUID: guId,
        FolderParentId: this.SelectedFolderId,
        IsDeleted: false,
        IsSystemDependent: false,
        Path: guId + fileExtension,
        TagIds: this.selectedTagIds,
        TagsList: TagsList,
        FileUser: FileUsers,
        Type: "storedoc",
      }
      this.tabsdata.sendFileAppendEvent(addfileDto);
    }

    setTimeout(() => {
      this.isShownprogress = false;
    }, 20000);

  }
  uploadFilesSimulator(index: number) {

    setTimeout(() => {
      if (index === this.dropfiles.length) {
        // return;
      } else {

        const progressInterval = setInterval(() => {
          if (this.dropfiles[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.dropfiles[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);

  }

  deletedropFile(index: number) {

    this.dropfiles.splice(index, 1);
    if (this.dropfiles.length == 0) {
      this.isShownprogress = false;
    }

    else {
      this.isShownprogress = true;
    }
    this.deletedropallFiles(index);
  }
  deletedropallFiles(index) {
    let deletefile
    let deletefilevalue


    deletefile = this.filearray[index]

    deletefilevalue = deletefile.Name

    this.file.DragDropdeleteFile(deletefilevalue).subscribe(data => {

    })

    this.filearray.splice(index, 1);
  }

  formatBytes(bytes) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed()) + ' ' + sizes[i];
  }

  changeTableRowColor(i: any, menu) {
    if (menu == 'files') {
      if (this.rowClicked === i) this.rowClicked = -1;
      else this.rowClicked = i;
      var contextelement = document.getElementById("context-menu");
      contextelement.classList.remove("active");
    }
    else if (menu == 'folder') {
      var contextelement = document.getElementById("context-menu");
      contextelement.classList.remove("active");
    }
  }

  HideRightClick() {
    var contextelement = document.getElementById("context-menu");
    contextelement.classList.remove("active");
  }
  onKeyUp(event) {

    this.searchTextBox = (document.getElementById('Search') as HTMLInputElement).value;
    this.el = event.key;
    if (this.el === "Backspace" || this.el === "Delete") {
      this.iconsearch();
    }
    this.iconsearch();

    if (this.el === "Enter") {
      this.bSearch();
    }

  }

  bsearchbtn() {
    this.bSearch();
  }
  bSearch() {

    let Flag: string;
    let SearchTerms: string
    this.tabsdata.sharedFolderId = 0;
    this.tabsdata.FolderParentId = 0;
    this.bcElements = [];
    this.searchTextBox = (document.getElementById('Search') as HTMLInputElement).value;
    if (this.searchTextBox.indexOf('#') !== -1 || this.searchTextBox.indexOf('@') !== -1) {
      this.searchTextBox = (document.getElementById('Search') as HTMLInputElement).value;
      //Terms = this.searchTextBox;
      SearchTerms = this.searchTextBox;
      this.SearchFlag = "HashSearch";
      this.SearchlimitValue = 25;
      this.Searchoffsetvalue = 0;
      this.file.GetSearchFiles(this.SearchFlag, SearchTerms, this.SearchlimitValue, this.Searchoffsetvalue).subscribe(hash => {
        this.LoadFilesScroll = false;
        this.filearray = hash.Files;
        this.resultantSearchFiles = hash.Files;
        if (this.filearray.length == 0) {
          document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents"
        }
        else {
          document.getElementById("Nofiles").innerHTML = ""
        }
        this.dataServiceGet.sendFilterResult("true");
        this.LoadFilterScroll = false;
        this.LoadFilesScroll = false;
      })
    }
    else {
      this.searchTextBox = (document.getElementById('Search') as HTMLInputElement).value;
      //Terms = this.searchTextBox;
      SearchTerms = this.searchTextBox;
      this.SearchFlag = "BasicSearch";
      this.SearchlimitValue = 25;
      this.Searchoffsetvalue = 0;
      this.DataServiceSend.sendFilterTerms('text:' + SearchTerms);
      this.file.GetSearchFiles(this.SearchFlag, SearchTerms, this.SearchlimitValue, this.Searchoffsetvalue).subscribe(data => {
        this.LoadFilesScroll = false;
        this.filearray = data.Files;
        this.resultantSearchFiles = data.Files;
        if (this.filearray.length == 0) {
          document.getElementById("Nofiles").innerHTML = "Vous n’avez pas encore ajouté de documents"
        }
        else {
          document.getElementById("Nofiles").innerHTML = ""
        }
        this.dataServiceGet.sendFilterResult("true");
        this.LoadFilterScroll = false;
        this.LoadFilesScroll = false;
      })

    }
    //this.searchTextBox = '';
  }
  iconsearch() {
    if (this.searchTextBox == null || this.searchTextBox == "" || this.searchTextBox.length <= 2) {
      this.isShown = false;
    }
    else {
      this.isShown = true;
    }
  }
  doubleClick(event, query: string, folderParentId) {
    document.getElementById("Nofiles").innerHTML = " ";
    this.RightClickServiceSend.sendUncheckEvent("true");
    this.dropfiles = [];
    this.filearray = [];
    this.CheckedCount = 0;
    this.selectedFileId = [];
    let folderid
    let result
    let resultid;
    let formattedString
    let bcId
    var target = event.target || event.srcElement || event.currentTarget;
    var idAttr = target.attributes.id;
    folderid = idAttr.nodeValue;
    resultid = folderid.split(':')[1];
    if (resultid != 0) {
      this.SelectedFolderId = resultid
    }

    this.Foldernames = query;
    formattedString = this.Foldernames.split(" ").join("");
    bcId = resultid + "_" + formattedString
    let revarr =
    {
      "folderId": resultid,
      "FolderName": this.Foldernames,
      "temporaryId": bcId,
      "ParentId": folderParentId

    }
    this.bcElements.push(revarr)
    this.Folderarr = this.resultFolder.filter(function (e) {
      return e.ParentId == resultid
    })

    this.tabsdata.sharedFolderId = resultid;
    this.tabsdata.FolderParentId = folderParentId;
    this.LoadFilesScroll = false;

    this.file.GetFolderFiles(resultid).subscribe(data => {
      this.filearray = data.Files;

    })
  }
  getallfiles() {
    this.tabsdata.sharedFolderId = 0;
    this.tabsdata.FolderParentId = 0;
    this.CheckedCount = 0;
    this.SelectedFolderId = 0;
    this.selectedFileId = [];
    this.bcElements = [];
    this.filearray = [];
    this.RightClickServiceSend.sendUncheckEvent("false");
    this.Folderarr = this.resultFolder.filter(function (e) {
      return e.ParentId == 0
    })
    this.FilesReload(25, 0);
  }
  upload(): void {
    //get image upload file obj;
  }
  onRightClick(event, i, EventName) {

    if (EventName == 'files') {
      this.rightClickMenu = EventName;

      if (this.CheckedCount >= 2) {

        this.RightClickServiceSend.UserIdsList = "";
        this.RightClickServiceSend.FileIdShare = this.selectedFileId;
        this.RightClickServiceSend.FileName = this.SelectedFileForDelete;
        this.RightClickServiceSend.fileArrayBasket = this.fileDetailsBasket;
        if (this.systemdependentArray.includes(true)) {
          this.systemdependent = true;
        }
        else {
          this.systemdependent = false;
        }
      }
      else {
        this.fileDetailsBasket = [];
        this.fileDetailsBasket.push(this.filearray[i]);
        this.RightClickServiceSend.fileArrayBasket = this.fileDetailsBasket;
        this.RightClickServiceSend.FileReName = this.filearray[i].Name;
        this.SelectedFileForDelete = [];
        this.SelectedFileForDelete.push({ Name: this.filearray[i].Name });
        this.RightClickServiceSend.FileName = this.SelectedFileForDelete;

        this.selectedFileId = [];
        this.selectedFileId.push(this.filearray[i].Id);
        this.SelectedFileArray = [];
        this.SelectedFileArray.push({ LinkId: this.filearray[i].LinkId });
        this.RightClickServiceSend.RightClickZipfileLinkId = this.SelectedFileArray;
        this.SelectedFileArray = [];
        this.RightClickServiceSend.FileIdShare = this.selectedFileId;
        this.selectedFileId = [];
        this.RightClickServiceSend.OwnedBy = this.filearray[i].OwnedBy;
        this.Taglistfiles = [];
        for (var index in this.filearray[i].Tags) {
          this.Taglistfiles.push({ id: this.filearray[i].Tags[index].TagId });
        }
        this.RightClickServiceSend.RightClickTagListOnFiles = this.Taglistfiles;
        if (this.filearray[i].IsSystemDependent == true) {
          this.systemdependent = true;
        }
        else {
          this.systemdependent = false;
          this.UserIdsArray = [];
          this.file.FileById(this.filearray[i].Id).subscribe(data => {

            for (var index in data.resData[0].Users) {
              this.UserIdsArray.push(data.resData[0].Users[index].UserId);
            }
          })

          this.RightClickServiceSend.UserIdsList = this.UserIdsArray;
        }
      }
      this.pdfurl = this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/Download/' + this.dataServiceGet.ClientContainer + '/file/' + this.filearray[i].LinkId;
      // this.pdfurl = this.ConfigService.getpdfUrl().pdfurl + this.dataServiceGet.ClientContainer + '/' + this.filearray[i].Name;
      this.RightClickServiceSend.RightClickFile = this.pdfurl;
      this.rowClicked = i;
      this.RightClickServiceSend.RightClickFileType = this.filearray[i].Name;
      this.RightClickServiceSend.RightClickFileId = this.filearray[i].Id;
      this.RightClickServiceSend.RightClickFileLinkId = this.filearray[i].LinkId;
      this.RightClickServiceSend.RightClickFilesIdList = this.selectedFileId;
    }
    else if (EventName == 'folder') {
      this.rightClickMenu = EventName;
      this.RightClickServiceSend.RightClickFolderId = this.Folderarr[i].FolderId;
      this.RightClickServiceSend.RightClickFolderName = this.Folderarr[i].FolderName
      this.RightClickServiceSend.RightClickFolderColor = this.Folderarr[i].Color;
    }

    event.preventDefault();
    var contextelement = document.getElementById("context-menu");
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    var posX = event.pageX;
    var posY = event.pageY;
    var menuwidth = contextelement.offsetWidth + 10;
    var menuHeight;

    if (this.CheckedCount >= 2) {
      menuHeight = contextelement.offsetHeight + 290;
    }
    else {
      if (this.systemdependent == true) {
        menuHeight = contextelement.offsetHeight + 290;
      }
      else {
        if (this.basketCount > 0) {
          menuHeight = contextelement.offsetHeight + 410;
        }
        else {
          menuHeight = contextelement.offsetHeight + 360;
        }
      }
    }

    var secMargin = 10;
    var posLeft;
    var posTop;
    if (posX + menuwidth + secMargin >= windowWidth && posY + menuHeight + secMargin >= windowHeight) {
      //Case 1: right-bottom overflow:
      posLeft = posX - menuwidth - secMargin + "px";
      posTop = posY - menuHeight - secMargin + "px";
    }

    else if (posX + menuwidth + secMargin >= windowWidth) {
      //Case 2: right overflow:
      // posLeft = posX - menuwidth - secMargin + "px";
      posLeft = posX - 200 + "px";
      posTop = posY + secMargin + "px";
    }

    else if (posY + menuHeight + secMargin >= windowHeight) {
      //Case 3: bottom overflow:
      posLeft = posX + secMargin + "px";
      posTop = posY - menuHeight - secMargin + "px";
    }
    else {
      //Case 4: default values:
      posLeft = posX + secMargin + "px";
      posTop = posY + secMargin + "px";
    };
    contextelement.style.left = posLeft;
    contextelement.style.top = posTop;
    contextelement.classList.add("active");
  }

  delete(index) {
    document.getElementById("Nofiles").innerHTML = " "
    this.RightClickServiceSend.sendUncheckEvent("true");
    let IndexSet
    let folderidd
    IndexSet = this.bcElements[index]
    folderidd = IndexSet.folderId
    this.FolderId = folderidd;
    this.file.GetFolders().subscribe(folders => {
      this.resultFolder = folders.Folders;
      this.Folderarr = this.resultFolder.filter(function (e) {
        return e.ParentId == folderidd
      })
    })
    this.file.GetFolderFiles(folderidd).subscribe(data => {
      this.filearray = data.Files;
    })

    this.bcElements.splice(index + 1, this.bcElements.length - (index + 1));
    this.tabsdata.sharedFolderId = folderidd;
    this.tabsdata.FolderParentId = this.bcElements[index].ParentId;


  }
  RefreshFiles() {
    this.Refresh = true;
    this.gotoTop();
    this.isScroll = true;
    this.tabfilename = "AllFiles"
    this.FilesReload(25, 0)

  }
  ClearAllSearch() {
    this.FilterSearchsArray = [];
    this.tokenStorageSrv.clearHistory();
  }
  SearchAgain(i) {
    let Flag: string;
    let Limit;
    let Offset;
    Flag = "AdvanceSearch";
    Limit = 0;
    Offset = 0;
    let terms = this.tokenStorageSrv.getSearchTerms();
    this.file.GetSearchFiles(Flag, terms[i], Limit, Offset).subscribe(data => {
      this.searchFilter.FilterSearchdatas(data)

    });
  }
  showBaskets() {
    if (this.basketCount > 0) {
      this.dialog.open(BasketsIndexComponent, {
        width: '1200px',
        height: '550px',

      });

    }


  }

  onSortClick(event, data) {
    let target = event.currentTarget,
      classList = target.classList;

    if (classList.contains('fa-chevron-up')) {
      classList.remove('fa-chevron-up');
      classList.add('fa-chevron-down');
      this.sortDir = -1;
    } else {
      classList.add('fa-chevron-up');
      classList.remove('fa-chevron-down');
      this.sortDir = 1;
    }
    if (data == 'documentName') {
      this.sortArr('DocumentName');
    }
    else if (data == 'date') {
      this.sortArr('CreatedAt');
    }
    else if (data == 'fileSize' || data == 'shared') {
      let colName;
      if (data == 'fileSize') {
        colName = "Size";
      }
      else if (data == 'shared') {
        colName = "IsShared";
      }
      this.filearray.sort((a, b) => {
        if (a[colName] < b[colName]) {
          return -1 * this.sortDir;
        }
        else if (a[colName] > b[colName]) {
          return 1 * this.sortDir;
        }
        else {
          return 0;
        }
      });
    }


  }


  sortArr(colName: any) {
    this.filearray.sort((a, b) => {
      a = a[colName].toLowerCase();
      b = b[colName].toLowerCase();
      return a.localeCompare(b) * this.sortDir;
    });
  }

  drop(event: CdkDragDrop<[]>) {

    if (event.previousContainer !== event.container) {
      moveItemInArray(this.filearray, event.previousIndex, event.currentIndex);
    }
    else {

      if (this.CheckedCount > 0) {
        for (let j = 0; j < this.selectedFileId.length; j++) {

          this.DropFileIds.push({ Id: this.selectedFileId[j] })

        }

        if (this.DropFolderId > 0) {
          this.file.FileDropIntoFolder(this.DropFileIds, this.DropFolderId).subscribe(data => {
            if (data == "Success") {
              if (this.SelectedFolderId == 0) {
                for (let i = 0; i < this.selectedFileId.length; i++) {
                  this.file.FileById(this.selectedFileId[i]).subscribe(files => {
                    for (var j = 0; j < this.filearray.length; j++) {
                      if (this.selectedFileId[i] == this.filearray[j].Id) {
                        this.filearray[j].FilePath = files.resData[0].FilePath;
                      }
                    }

                  })
                }
              }
              else if (this.SelectedFolderId > 0) {
                for (let i = 0; i < this.selectedFileId.length; i++) {

                  let index = this.filearray.findIndex(record => record.Id === this.selectedFileId[i]);
                  this.filearray.splice(index, 1);
                  this.CheckedCount = 0;

                }

                this.selectedFileId = [];

              }
            }
            else {

              this.toastr.error("Vous n'êtes pas autorisé à déplacer ce fichier")
            }
          });
        }
        this.DropFolderId = 0;
        this.DropFileIds = [];
      }
      else {
        //copyArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
        // moveItemInArray(this.filearray, event.previousIndex, event.currentIndex);
        this.DropFileIds.push({ Id: event.container.data[event.previousIndex]['Id'] })
        if (this.Folderarr.length == 0) {
          this.DropFolderId = 0;
        }
        if (this.DropFolderId > 0) {
          this.file.FileDropIntoFolder(this.DropFileIds, this.DropFolderId).subscribe(data => {
            if (data == "Success") {
              if (this.SelectedFolderId == 0) {
                this.file.FileById(event.container.data[event.previousIndex]['Id']).subscribe(files => {

                  for (var j = 0; j < this.filearray.length; j++) {
                    if (event.container.data[event.previousIndex]['Id'] == this.filearray[j].Id) {
                      this.filearray[j].FilePath = files.resData[0].FilePath;
                    }
                  }

                })

              }
              else if (this.SelectedFolderId > 0) {
                let index = this.filearray.findIndex(record => record.Id === event.container.data[event.previousIndex]['Id']);
                this.filearray.splice(index, 1);
              }
            }
            else {
              this.toastr.error("Vous n'êtes pas autorisé à déplacer ce fichier")
            }
          });
        }
        this.DropFolderId = 0;
        this.DropFileIds = [];
      }
    }
  }
  onDragStart(event, i) {
    this.gotoTop();
    this.rowClicked = i;

  }
}
export class DndDirective { }


