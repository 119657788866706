import { Component } from '@angular/core';
// import{FileApiService}from './Service/backend/file-api.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'store-doc';
  // title = 'materialApp'; 
  disabled = false;
  invert = false;
  thumbLabel = false;
  value = 0;
  vertical = false;
}
