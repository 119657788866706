import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FilterdataService {

FilterTypeData=null;
FilterTimeLineData=null;
FilterTagsData=null;
FilterGlobalFiles:any;
FilterFilesLoad=true;

FilterTerms:any;

private Files = new Subject<any>();
  constructor() { }


  sendFilterFiles(x: any) {
    this.Files.next(x);
  }
  getFilterFiles(): Observable<any> {
    return this.Files.asObservable();
  }
}

