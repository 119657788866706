<form #myForm="ngForm" (submit)="FileUpload()">
  <div class="form-group">
    <div class="row">
      <div class="col-md-7">
        <div class="container fileover" id="droppable">
          <input multiple="multiple" id="docStoreFile" type="file" (change)="fileEvent($event)" />
          <label for=""><i class="fa fa-plus plus"></i> Parcourir le fichier </label>

        </div>
      </div>

    </div>
    <div class="Listfiles" *ngIf="isShownList">
      <h1>Liste des fichiers</h1>
      <table id="users" class="table-scroll small-first-col">
        <thead>
          <tr>
            <th style="width:10%;"> Nom du document </th>
            <th style="width:4%;"> Sélectionner tags </th>
            <th style="width:8%;"> Tags </th>
            <th style="width:4%;"> Fichier </th>
            <th style="width:4%;"> Supprimer </th>
          </tr>
        <tbody class="body-half-screen">

          <tr *ngFor="let item of selectedfilename let i = index;">

            <td> {{item.FileName}} </td>
            <td scope="row">
              <input id="checkedd" type="checkbox" [checked]="item.checked" (click)="CheckValue($event,i)" />
            </td>
            <td>
              <span class="doctagnames" *ngFor="let x of item.TagList">
                <span class="docTag" [ngStyle]="{'background-color':x.tagcolour+60,'border-color':x.tagcolour}"
                  (click)="RemoveTag($event,i,x.tagname)">{{ x.tagname
                  }}<i class="cancel fa fa-times" aria-hidden="true"></i></span>
              </span>

            </td>
            <td>
              <i class="filedownload fa fa-download" style="font-size:22px;color:dodgerblue;" (click)="DownloadPdf(i)"
                aria-hidden="true"></i>

            </td>
            <td><i class="fa fa-trash-o" style="font-size:22px;color:dodgerblue;" (click)="DeletePdf(i)"></i> </td>
          </tr>

        </tbody>
      </table>
    </div>
    <br>
    <mat-slide-toggle (change)="AddedTag($event)" [checked]="checked"> {{ text }}</mat-slide-toggle>
    <br><br>
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="VOS ÉTIQUETTES">
        <div class="tagnames">
          <span class="doctagnames" *ngFor="let taglist of Tagsarr;let i=index">
            <span class="docTag" [ngStyle]="{'border-color': taglist.Color,'background-color':taglist.Color+60}"
              *ngIf="taglist.IsReadOnly==false"
              (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName,taglist.TagId , taglist.Color)">{{
              taglist.TagName }}
              <span id="sdTag_{{taglist.TagId}}"></span>
            </span>
          </span>
        </div>
      </mat-tab>
      <mat-tab label="ÉTIQUETTES SYSTÈME">
        <div class="tagnames">
          <span class="doctagnames" *ngFor="let taglist of SystemTags;let i=index">
            <span class="docTag" [ngStyle]="{'border-color': taglist.Color,'background-color':taglist.Color+60}"
              *ngIf="taglist.IsReadOnly==true"
              (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName,taglist.TagId , taglist.Color)">{{
              taglist.TagName }}
              <span id="sdTag_{{taglist.TagId}}"></span>
            </span>
          </span>
        </div>
      </mat-tab>
      <mat-tab label="ÉTIQUETTES D'UTILISATEUR">
        <div class="tagnames">
          <span class="doctagnames" *ngFor="let taglist of UserCogTags;let i=index">
            <span class="docTag" [ngStyle]="{'border-color': taglist.Color,'background-color':taglist.Color+60}"
              *ngIf="taglist.IsReadOnly==true"
              (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName,taglist.TagId , taglist.Color)">{{
              taglist.TagName }}
              <span id="sdTag_{{taglist.TagId}}"></span>
            </span>
          </span>
        </div>
      </mat-tab>
      <mat-tab label="RECHERCHEZ VOS TAGS">
        <input type="text" id="tagMoreFilterSearch" class="form-control" autocomplete="off"
          (keydown.backspace)="removeMoreTagOnBackspace($event)" (keyup)="MorekeyUp($event)"
          placeholder="Rechercher des tags" />

        <div class="tagnames">
          <span class="doctagnames" *ngFor="let taglist of searchtagdata;let i=index">
            <span class="docTag" [ngStyle]="{'border-color': taglist.Color,'background-color':taglist.Color+60}"
              *ngIf="taglist.IsReadOnly==true"
              (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName,taglist.TagId , taglist.Color)">{{
              taglist.TagName }}
              <span id="sdTag_{{taglist.TagId}}"></span>
            </span>
          </span>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="btnFooter">
      <button class="registerBtn" [disabled]="click" type="submit" id="button"
        mat-flat-button><b>Enregister</b></button>
    </div>

  </div>
</form>