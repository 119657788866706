
        <div class="modal-header">
           
            <h4 class="modal-title"><span class="roundImg"><i class="fas fa-tag"></i></span><b> Attribuer des balises</b></h4>
        </div>
        <div class="modal-body">
            <div class="row" id="tagAssgnList">
               
                    <span class="doctagnames" *ngFor="let taglist of Tagsarr;let i=index">
                      
                      
                        <span class="docTag" id="Tag_{{taglist.TagId}}"  [ngStyle]="{'border-color': taglist.Color,'background-color':taglist.Color+60}" *ngIf="taglist.IsReadOnly==false" 
                        (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName,taglist.TagId)"
                        >{{ taglist.TagName }}
                       
                    
                  
                     <span id="sdTag_{{taglist.TagId}}"></span>
                    </span>
                    </span>
                    
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btnDoc btn btn-default" (click)="Tagassigning()" id="btnTagAssignSubmit"><b>Enregister</b></button>
        </div>
