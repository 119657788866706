import { Component, OnInit, Input } from '@angular/core';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { MatDialog } from '@angular/material/dialog';
import { PdfPreviewComponent } from 'src/app/Modules/Shared/pdf-preview/pdf-preview.component';
import { LinkSharingComponent } from 'src/app/Modules/Shared/link-sharing/link-sharing.component';
import { TagAssigningComponent } from 'src/app/Modules/Shared/tag-assigning/tag-assigning.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { saveAs } from 'file-saver';
import { ShareFileComponent } from 'src/app/Modules/Shared/share-file/share-file.component';
import { RenameFileComponent } from 'src/app/Modules/Shared/rename-file/rename-file.component';
import { threadId } from 'worker_threads';
import { TokenStorageService } from 'src/app/Service/backend/token-storage.service';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service';
import { FoldersComponent } from '../folders/folders.component';
@Component({
  selector: 'app-right-click-menu',
  templateUrl: './right-click-menu.component.html',
  styleUrls: ['./right-click-menu.component.scss']
})
export class RightClickMenuComponent implements OnInit {
  @Input() checkboxcount: any;
  @Input() systemDependent: any;
  @Input() RightClickMenu: any;
  @Input() FolderId: any;
  FileDetail: any[];
  basketlistNames: any;
  filesArray: any[] = [];
  showBasket: any;
  urlSafe: SafeResourceUrl;
  LinkIdString: any[] = [];
  Baskets: any[] = [];
  selectedFileArray: any[] = [];
  basketMenu=false;
  constructor(private RightClickServiceSend: RightClickDataService,
    private RightClickServiceGet: RightClickDataService,
    public dialog: MatDialog, public sanitizer: DomSanitizer,
    private fileApiService: FileApiService,
    private readonly tokenStorageSrv: TokenStorageService,
    private dataServiceSend: TagsdataService,
    private dataServiceGet: TagsdataService) { 
      this.dataServiceGet.getBasketCount().subscribe(data=>
        {

          let baskets = this.tokenStorageSrv.getBasketList().length;
          if(baskets>0)
          {
            this.basketMenu=true;
          }
          else{
            this.basketMenu=false;
             
            
          }
        })

        this.dataServiceGet.getBasCount().subscribe(data=>
          {
       
            let baskets = this.tokenStorageSrv.getBasketList().length;
            if(baskets>0)
            {
              this.basketMenu=true;
            }
            else{
              this.basketMenu=false;
              this.showBasket=false;
            }
          })
    }
  pdfurl;
  downloadpdf;
  ngOnInit(): void {
    let data = JSON.parse(sessionStorage.getItem("basketList"));
        if(data != null)
        {
          this.basketMenu=true;
        }
        else{
          this.basketMenu=false;
        }

    
  }

  pdfView() {


    this.RightClickServiceSend.sendRightClickEvent("false");

    this.dialog.open(PdfPreviewComponent, {
      width: '2000%',
      height: '700px',

    });

  }


  linkshareView() {

    this.RightClickServiceSend.sendRightClickEvent("false");

    this.dialog.open(LinkSharingComponent, {
      width: '60%',
      height: '300px',


    });

  }
  tagassignView() {
    this.RightClickServiceSend.sendRightClickEvent("false");
    this.dialog.open(TagAssigningComponent, {
      width: '60%',
      height: '300px',


    });
  }
  DownloadPdf() {

    this.pdfurl = this.RightClickServiceGet.RightClickFile;
    this.downloadpdf = this.pdfurl;

  }
  FolderDownloadZip() {
    this.fileApiService.DowloadFolderZip(this.RightClickServiceGet.RightClickFolderId).subscribe((data) => {
      const base64 = data.ZippedData;
      this.ZipContent(base64);

    });
  }
  DownloadZip() {

    this.selectedFileArray = this.RightClickServiceGet.RightClickZipfileLinkId
    this.fileApiService.DownloadZip(this.selectedFileArray).subscribe((data) => {

      const base64 = data.ZippedFile;
      this.ZipContent(base64);

    });

  }
  ZipContent(data) {
    var MyDate = new Date();
    var MyDateString;
    MyDate.setDate(MyDate.getDate());
    MyDateString = ('0' + MyDate.getDate()).slice(-2) + '_'
      + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '_'
      + MyDate.getFullYear() + ' ' +
      (new Date().getHours().toString()) + '_'
      + (new Date().getMinutes().toString()) + '_'
      + (new Date().getSeconds().toString());
    const name = "vos-fichiers " + MyDateString + ".zip";
    const zipBlob = this.dataURItoBlob(data);
    const zipfile = new File([zipBlob], name, { type: 'application/pdf' });
    saveAs(zipfile, name);
    this.RightClickServiceSend.sendUncheckEventforZip("false");
    this.RightClickServiceSend.sendRightClickEvent("false");
  }
  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI)
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  DeleteFile() {
    if (this.RightClickMenu == 'files') {
      if (window.confirm("Are You Sure!?") == true) {
        this.fileApiService.fileDelete(this.RightClickServiceGet.FileName).subscribe(data => {
          //this.RightClickServiceSend.SendRelaodFileRequest("true");
          this.dataServiceSend.sendFileById("DeleteFile");
          this.RightClickServiceSend.sendRightClickEvent("false");
          this.RightClickServiceSend.sendUncheckEvent("true");
        })
      }
      else {
        this.RightClickServiceSend.sendRightClickEvent("false");
      }
    }
    else if (this.RightClickMenu == 'folder') {
      if (window.confirm("Are You Sure!?") == true) {
        this.fileApiService.folderDelete(this.RightClickServiceGet.RightClickFolderId).subscribe(data => {
          this.RightClickServiceSend.SendRelaodFileRequest("false");
          this.RightClickServiceSend.sendRightClickEvent("false");

        })
      }
      else {
        this.RightClickServiceSend.sendRightClickEvent("false");
      }
    }
  }

  RenameFile() {

    this.RightClickServiceSend.sendRightClickEvent("false");

    if (this.RightClickMenu == 'files') {

      this.dialog.open(RenameFileComponent, {
        width: '1100px',
        height: '260px',
        data: 'files'
      });
    }
    else if (this.RightClickMenu == 'folder') {
      this.dialog.open(RenameFileComponent, {
        width: '1100px',
        height: '260px',
        data: 'folder'
      });
    }


  }
  ShareFile() {
    this.RightClickServiceSend.sendRightClickEvent("false");
    this.dialog.open(ShareFileComponent, {
      width: '1100px',
      height: '370px',

    });

  }
  ShowBasketNames() {
    
this.basketlistNames = this.tokenStorageSrv.getBasketList();

if(this.basketlistNames == '')
{
 
  this.showBasket = false;
}
else{

  this.showBasket = true;
}
  }
  AddtoBasket(i) {

    this.showBasket = false;


    this.filesArray = this.RightClickServiceGet.fileArrayBasket

    let data = JSON.parse(sessionStorage.getItem("basketList"));
    let fileDetails;
    for (let j = 0; j < this.filesArray.length; j++) {
      if (data[i].files.find(e => e.Id === this.filesArray[j].Id)) {
        //window.alert('le fichier existe déjà');
      }
      else {
        fileDetails = {
          Id: this.filesArray[j].Id,
          DocName: this.filesArray[j].DocumentName,
          Name: this.filesArray[j].Name,
          LinkId: this.filesArray[j].LinkId,
          CreatedDate: this.filesArray[j].CreatedAt,
          Size: this.filesArray[j].Size,
          SystemDependent: this.filesArray[j].IsSystemDependent
        }
        data[i].files.push(fileDetails);
        this.RightClickServiceSend.sendFileinBasket("add_" + fileDetails.Id);
      }
    }


    this.tokenStorageSrv.saveBasketFiles(data);
    this.RightClickServiceSend.sendRightClickEvent("false");
    this.RightClickServiceSend.sendUncheckEventforBasket("false");


  }


  MoveFiles() {
    this.RightClickServiceSend.sendFileMoveEvent();
    this.RightClickServiceSend.sendRightClickEvent("false");
  }

  FileMovetoFolder()
  {
    this.dialog.open(FoldersComponent, {
      width: '58%',
      //  height: '300px',
       height: '50%',
       
  });
  }
}
