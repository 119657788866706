<ng-progress #progressBar></ng-progress>

<div (click)="HideRightClick()" id="file-container">
    <app-right-click-menu id="context-menu" [checkboxcount]='CheckedCount' [systemDependent]='systemdependent'
        [RightClickMenu]='rightClickMenu' [FolderId]="SelectedFolderId"></app-right-click-menu>


    <span class="iconSet refreshBtn" (click)="RefreshFiles();" id="refreshDocs"><i class="fa fa-refresh"></i></span>
    <!-- [selectedFileArray]='SelectedFileArray'></app-right-click-menu> -->
    <app-button-modal [btnModal]="show"></app-button-modal>


    <div class="form-control" id="headerSearch">

        <input class="form-control" type="text" id="Search" value="" (keyup)="onKeyUp($event)" autocomplete="off"
            placeholder="Chercher un fichier" />
        <div id="bsrchBtn" *ngIf="isShown" (click)="bsearchbtn()"><i class="fas fa-check-circle"></i></div>

    </div>
    <span class="col-md-6 FilesHeading">{{message}}</span>

    <br><br>




    <div id="file-panel" class="mainscroll">

        <div id="loadDocuments" class="loaddoc" (scroll)="scrollToBottom()">
            <div *ngIf="tabfilename !='SharedFiles'">
                <ol class="col-md-12 breadcrumb" id="addNav">
                    <i class="fas fa-home homeIcon" (click)="getallfiles()"></i>
                    <li class="navigation" *ngFor="let fol of bcElements;let i = index" (click)="delete(i)"><i
                            class="fas fa-folder"> {{fol.FolderName}}</i></li>

                </ol>

            </div>


            <span *ngIf="message =='null'" class="clearSearch" (click)="ClearAllSearch();"> <i
                    class="fas fa-eraser clearIcon"></i> Clear </span>

            <div *ngIf="message =='null'" class="border FilterTerms">
                <div class="border searchNames" *ngFor="let terms of FilterSearchsArray;let i=index">
                    <span class="terms" *ngFor="let termnames of terms;">
                        <span class="termname">{{termnames}} </span>
                    </span>
                    <button type="button" (click)="SearchAgain(i);" class="block"><i class="fa fa-search"></i></button>

                </div>



            </div>

            <div class="files-list scrollprogress" id="hidediv" *ngIf="isShownprogress">

                <div class="single-file" *ngFor="let val of dropfiles;let i=index">
                    <i class='fas fa-file-pdf fa-border fa-3x' id="pdficon"></i>

                    <div class="info">
                        <h4 class="name">
                            {{ val?.name }}
                        </h4>
                        <app-progress [progress]="val?.progress"></app-progress>
                    </div>

                    <div class="delete" (click)="deletedropFile(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                            <path fill="#B1B1B1" fill-rule="nonzero"
                                d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                        </svg>
                    </div>
                </div>
            </div>

            <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="DropFilesFolder"
                [cdkDropListConnectedTo]="firstList" #otherList="cdkDropList" class="col-md-12 row" id="loadFolders">
                <div (mouseover)="DropFolderId=folderList.FolderId" (mouseout)="DropFolderId=0" class="folder"
                    *ngFor="let folderList of Folderarr;let folderIndex=index"
                    (click)="changeTableRowColor(folderIndex,'folder')"
                    (contextmenu)="onRightClick($event,folderIndex,'folder')"
                    (dblclick)="folderList.state=!folderList.state;doubleClick($event, folderList.FolderName,folderList.FolderId)">

                    <div class="col-md-12 form-control folderheader" id="FolderId:{{folderList.FolderId}}">
                        <span>
                            <i class="fa fa-folder folderIcon" [ngStyle]="{'color': folderList.Color}"
                                id="FolderId:{{folderList.FolderId}}" aria-hidden="true">{{folderList.color}}
                            </i>
                        </span>
                        <span id="FolderId:{{folderList.FolderId}}" class="tagName">{{folderList.FolderName}}</span>

                    </div>
                </div>
            </div>
            <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="filearray" #firstList="cdkDropList"
                class="col-md-12 row">
                <table class="col-md-12 tbl">
                    <thead class="tblHeader">
                        <tr class="tblRowHead">
                            <th style="padding-left:35px;  width:0.3%">
                                <input type="checkbox" (change)="SelectAllCheckbox($event)">
                            </th>
                            <th class="txtDocName" style="width:16%;">
                                Nom
                                <i (click)="onSortClick($event,'documentName')" class="my-icon fa fa-chevron-down"></i>
                            </th>
                            <th style="width:2%;">
                                Créé à
                                <i (click)="onSortClick($event,'date')" class="my-icon fa fa-chevron-down"></i>
                            </th>
                            <th style="width:2%;">
                                Taille
                                <i (click)="onSortClick($event,'fileSize')" class="my-icon fa fa-chevron-down"></i>
                            </th>
                            <th style="width:2%;">
                                Partagé
                                <i (click)="onSortClick($event,'shared')" class="my-icon fa fa-chevron-down"></i>
                            </th>
                        </tr>
                        <!-- <mat-divider></mat-divider> -->
                    </thead>

                    <tbody class="col-md-12" id="loadDocuments">

                        <!-- <mat-divider></mat-divider> -->
                        <span id="Nofiles"></span>

                        <tr cdkDrag class="doclist" *ngFor="let val of filearray;let i=index"
                            [style.background-color]="rowClicked == i ? ' rgba(179, 208, 250, 0.7)' : 'white'"
                            (click)="changeTableRowColor(i,'files')" (contextmenu)="onRightClick($event,i,'files')"
                            (cdkDragStarted)="onDragStart($event,i)">

                            <td style="padding-left:20px; width:0.3%"> <input type="checkbox" id="checksId"
                                    class="checks" [checked]="checks" (click)="getCheckValue($event,i)"></td>

                            <td class="documentname" style="width:15%">
                                {{val.DocumentName}} <i class="fas fa-lock" style="color:grey; font-size: 12px;"
                                    *ngIf="val.IsSystemDependent==true;"></i>
                                <span id="basFile{{val.Id}}">
                                    <span *ngFor="let baskets of BasketFiles;">

                                        <i *ngIf="baskets==val.Id" class="fa fa-shopping-bag"
                                            style="color:rgb(223, 72, 72); font-size: 12px; margin-left: 3px;"></i>
                                    </span>
                                </span>



                                <br />
                                <span *ngIf="val.FilePath!=null;" class="FolderPath"><i class="fa fa-folder"></i>
                                    {{val.FilePath}} <br /></span>

                                <span class="doctagnames" *ngFor="let x of val.Tags;let i=index">

                                    <span class="docTag" [ngStyle]="{'color':x.Color,'border-color':x.Color}">{{
                                        x.TagName
                                        }}</span>

                                </span>
                            </td>
                            <td class="docdate" style="width:1%; ">{{val.CreatedAt |date:"dd/MM/y hh:mm:ss"}}</td>
                            <td class="docSize" style="width:2%;">{{val.Size|filesize}}</td>

                            <td class="docicon" style="width:2%;">


                                <i class="fas fa-users-cog"
                                    *ngIf="val.IsShared==true && val.IsSystemDependent==true;"></i>
                                <i class="fa fa-users" *ngIf="val.IsShared==true && val.IsSystemDependent==false;"></i>
                                <span
                                    *ngIf="(val.IsShared==false && val.IsSystemDependent==false) || (val.IsShared==false && val.IsSystemDependent==true);">
                                    - </span>

                            </td>

                            <div class="layout-item-drag-preview" *cdkDragPreview>

                                <i class="fa fa-file icon"></i> déplacer ({{ CheckedCount > 0 ? CheckedCount : 1 }})
                                fichier(s)
                            </div>
                        </tr>



                    </tbody>

                </table>
            </div>

        </div>


    </div>
    <div class="basket-container" (click)="showBaskets()">

        <button mat-fab class="bagicon" id="bagIcons" [disabled]="click" color="primary">
            <span *ngIf="basketCount > 0;" class="basketCount">{{basketCount}}</span>
            <i class="fa fa-shopping-bag icon"></i>

        </button>

    </div>
    <div class="button-container">

        <button mat-fab class="plusicon" color="primary" (click)="showBtnModal()">
            <i class="fas fa-plus icon" *ngIf="!show"></i>
            <i class="fas fa-close icon" *ngIf="show"></i>
        </button>

    </div>



</div>