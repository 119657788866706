import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { TokenStorageService } from 'src/app/Service/backend/token-storage.service'
import { UserApiService } from 'src/app/Service/backend/user-api.service';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { Base64 } from 'base64-string';

const TOKEN_KEY = 'auth-token';

@Component({
  selector: 'app-two-column-layout',
  templateUrl: './two-column-layout.component.html',
  styleUrls: ['./two-column-layout.component.scss']
})

export class TwoColumnLayoutComponent implements OnInit {

  UserAccess: any;
  MultiUserAccessData: any = [];
  TokenId: any;
  DocStoreUserAccess: any;
  DocStoreMultiUserAccess: any = [];
  userAccessKey: any;
  TagIds: any;
  Term: any;
  multiTokens: any = 'False';
  Flag: any;
  Limit;
  Offset;
  constructor(private DataServiceSend: TagsdataService,
    private readonly routeParams: ActivatedRoute,
    private fileService: FileApiService,
    private userApiService: UserApiService,
    private readonly tokenStorageSrv: TokenStorageService,
    private RightClickServiceSend: RightClickDataService) {
  }


  ngOnInit(): void {
    this.TokenId = this.routeParams.snapshot.paramMap.get("stToken");

    this.routeParams.queryParams.subscribe(data => {
      if (this.routeParams.snapshot.paramMap.get("stToken") == null) {

        this.TokenId = data.stToken;
        this.userAccessKey = data.Key;
        this.TagIds = data.tagIds;
        this.Flag = data.Flag;
        this.Term = data.Term;
        this.multiTokens = data.adminKeys;

        this.Limit = 0;
        this.Offset = 0;
        this.tokenStorageSrv.saveAuthToken(this.TokenId);
        if (this.Flag == null && this.multiTokens == 'False') {
          console.log('if Inside')
          this.fileService.GetUserFilesByTags(this.userAccessKey, this.TagIds).subscribe(datas => {
            this.DataServiceSend.sendFileByTags(datas);
          })
        }
        else if (this.Flag != null && this.multiTokens == 'False') {
          console.log('else Inside')
          this.fileService.GetSearchFiles(this.Flag, this.Term, this.Limit, this.Offset).subscribe(data => {

            this.DataServiceSend.sendSearchFileByadvanceSearch(data);


          })
        }

        if (this.multiTokens == 'True') {

          this.tokenStorageSrv.saveMultiTokens(this.multiTokens);

          this.userApiService.MultiUsersGet().subscribe(data => {

            let tokensArr = data.toString().split(',');

            this.MultipleUserGet(tokensArr)

          })

        }

      }
    })

    this.tokenStorageSrv.saveAuthToken(this.TokenId);

    this.userApiService.UserAccess().subscribe(data => { //need to uncomment
      this.UserAccess = data;
      this.RightClickServiceSend.UserDetailforShare = this.UserAccess;
      console.log("revathi");
      this.userApiService.AddDocStoreUserAccess(this.UserAccess).subscribe(datas => {
        console.log("revathi");
        this.DocStoreUserAccess = datas;
        this.tokenStorageSrv.saveDocStoreUser(this.DocStoreUserAccess);
        var UserList = {
          StoreDocUserKey: this.DocStoreUserAccess[0].StoreDocUserKey,
          UserId: this.DocStoreUserAccess[0].UserId
        }
        this.userApiService.AetcProDocStoreUserUpdate(this.DocStoreUserAccess).subscribe(update => {
        })
      })
    })



  }
  async MultipleUserGet(tokensArr) {
    for await (var index of tokensArr) {
      var sdToken = index
      const enc = new Base64();
      const b64 = enc.decode(sdToken);
      let UserId = b64.substring(0, b64.indexOf("_"));
      this.userApiService.MultiUserAccess(UserId).subscribe(data1 => {
        this.MultiUserAccessData.push(data1[0]);
        console.log("revathi");
        this.userApiService.AddDocStoreUserAccess(data1).subscribe(sdUserData => {
          console.log("revathi");
          this.DocStoreMultiUserAccess.push(sdUserData[0]);
          this.tokenStorageSrv.saveDocStoreMutiUsers(this.DocStoreMultiUserAccess);
        })
      })
    }


  }





}
