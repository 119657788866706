<div>

    <h2 class="btnnameHeader">
        <i class="fas fa-tags icons" *ngIf="recieveddata.buttonname=='Ajouter une nouvelle balise'"></i>
        <i class="fas fa-folder icons" *ngIf="recieveddata.buttonname=='Ajouter un dossier'"></i>
        <i class="fas fa-file-upload icons" *ngIf="recieveddata.buttonname=='Télécharger un nouveau fichier'"></i>
        <i class="fa fa-shopping-bag icons" *ngIf="recieveddata.buttonname=='Ajouter un panier'"></i>
        {{recieveddata.buttonname}}
    </h2>

</div>
<div class="btncontent">

    <app-addfilebtn *ngIf="recieveddata.buttonname=='Télécharger un nouveau fichier'"></app-addfilebtn>
    <app-addtagbtn *ngIf="recieveddata.buttonname=='Ajouter une nouvelle balise'"></app-addtagbtn>
    <app-addfolderbtn *ngIf="recieveddata.buttonname=='Ajouter un dossier'"></app-addfolderbtn>
<app-add-basketbtn *ngIf="recieveddata.buttonname=='Ajouter un panier'"></app-add-basketbtn>
</div>