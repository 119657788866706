import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationServiceService } from 'src/app/configuration-service.service'
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { TokenStorageService } from 'src/app/Service/backend/token-storage.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  private UserAccessList;
  private AdminAccessList;
  private DocStoreUserAccessList;
  private UserNamesShare;
  private userKeysbyUserIds;
  private aetcProUserUpdate: any;
  helper = new JwtHelperService();
  constructor(private http: HttpClient, private ConfigService: ConfigurationServiceService, private tokenServ: TokenStorageService, private dataServiceSend: TagsdataService) { }

  UserAccess() {
    this.dataServiceSend.BearerHeader = false;
    let token = this.tokenServ.getAuthToken();
    token = atob(token);
    const decodedToken = this.helper.decodeToken(token);
    this.UserAccessList = this.http.get(this.ConfigService.getApiConfig().RoutePrefic_Aetc_ClientLocal_Api + decodedToken.UserId, {});
    return this.UserAccessList;
  }

  MultiUsersGet() {
    this.dataServiceSend.BearerHeader = false;

    let sdtoken = this.tokenServ.getAuthToken();
    sdtoken = atob(sdtoken);
    const decodedToken = this.helper.decodeToken(sdtoken);
    let MultiUserAccess = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Aetc_StoredocLocal_Api + 'GetAdminKeys', { UserId: decodedToken.UserId, AccessToken: sdtoken });

    return MultiUserAccess;
  }

  MultiUserAccess(UserId) {
    this.dataServiceSend.BearerHeader = false;

    return this.http.get(this.ConfigService.getApiConfig().RoutePrefic_Aetc_ClientLocal_Api + UserId, {});
  }

  AddDocStoreUserAccess(UserAccess) {
    
    this.dataServiceSend.BearerHeader = true;
    console.log("UserAccess");
    console.log(UserAccess);
    this.DocStoreUserAccessList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'user/Add', UserAccess);
    return this.DocStoreUserAccessList;
  }

  GetUserNamesForShare(params) {
    this.dataServiceSend.BearerHeader = false;
    this.UserNamesShare = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Aetc_StoredocLocal_Api + 'UserNames', { shareParameters: params });
    return this.UserNamesShare;
  }

  UserKeysbyUserIds(params) {
    this.dataServiceSend.BearerHeader = false;
    this.userKeysbyUserIds = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Aetc_StoredocLocal_Api + 'GetUserKeysbyUserIds', { UserIds: params });
    return this.userKeysbyUserIds;
  }

  GetSharedUsers(params) {
    this.dataServiceSend.BearerHeader = false;
    return this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Aetc_StoredocLocal_Api + 'GetSharedUsers', { UserIdsList: params });
  }

  AetcProDocStoreUserUpdate(params) {
    this.dataServiceSend.BearerHeader = false;
    this.aetcProUserUpdate = atob(sessionStorage.getItem("sd-user"));
    return this.http.put(this.ConfigService.getApiConfig().RoutePrefic_Aetc_ClientLocal_Api + 'Update', params);
  }

}
