import { Injectable } from '@angular/core';
import { TokenStorageService } from 'src/app/Service/backend/token-storage.service';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'

const TOKEN_HEADER_KEY = 'Authorization';
const helper = new JwtHelperService();
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public fileApi: FileApiService, private tokenServ: TokenStorageService, 
    private dataService: TagsdataService,private dataServiceGet: TagsdataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
  
    let authReq;
    if(this.dataServiceGet.BearerHeader==true)
    {
      let token = this.tokenServ.getAuthToken();
      token = atob(token);
     
      authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) })
      const decodedToken = helper.decodeToken(token);
      this.dataService.TokenJWTdecode=decodedToken.UserId;
      this.dataService.ClientContainer=decodedToken.ClientContainer;
      this.dataService.ClientId=decodedToken.ClientId;
      return next.handle(authReq);
      }
     else if(this.dataServiceGet.BearerHeader==false){
      authReq=request.clone({headers:request.headers.delete(TOKEN_HEADER_KEY)})
      return next.handle(authReq);
     }

  }


}

