import { Component, OnInit } from '@angular/core';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { FileShareDto } from 'src/app/Dto/FileShareDto';
import { UserApiService } from 'src/app/Service/backend/user-api.service';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { MatDialog } from '@angular/material/dialog';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'

@Component({
  selector: 'app-share-file',
  templateUrl: './share-file.component.html',
  styleUrls: ['./share-file.component.scss']
})

export class ShareFileComponent implements OnInit {

  keyword = 'FullName';
  constructor(public dialog: MatDialog, private RightClickServiceGet: RightClickDataService,
    private userApiService: UserApiService,
    private fileApiService: FileApiService, 
    private RightClickServiceSend: RightClickDataService,
    private dataServiceGet: TagsdataService,
    private dataServiceSend: TagsdataService) { }
  UserAccessDetail;
  UserList;
  contactName;
  negativeId;
  Id;
  Files: any[] = [];
  AllUserIds: any[] = [];
  SelectedNameArray: any[] = [];
  selectedUserIds: any[] = [];
  UserBackgroundColor: any;
  UserBorderColor: any;
  ownedBy: any;
  UserSharedArray: any[] = [];

  ngOnInit(): void {
    this.AllUserIds = this.RightClickServiceGet.UserIdsList;
    this.ownedBy = this.RightClickServiceGet.OwnedBy;
    
    if (this.AllUserIds.length >= 1) {
      this.userApiService.GetSharedUsers(this.AllUserIds).subscribe(SharedUsersList => {
        for (var val in SharedUsersList) {
          this.SelectedNameArray.push(SharedUsersList[val])

        }
       
      })
    }
    this.UserAccessDetail = this.RightClickServiceGet.UserDetailforShare;
    this.contactName = this.UserAccessDetail[0].ContactName
  }

  SelectNameEvent(inputName) {
    if (inputName.length >= 3) {
      const suggestionsPanel = document.querySelector('.suggestions')
      let fileshareDto: FileShareDto = {
        term: inputName,
        client_Id: this.UserAccessDetail[0].Sub_Client_Id,
        CurrentUser_Id: this.UserAccessDetail[0].UserId,
        Profile_Code: this.UserAccessDetail[0].Sub_Profile_Id,

      }
      this.userApiService.GetUserNamesForShare(fileshareDto).subscribe(data => {
        this.UserList = data;
        console.log(this.UserList)
      })
      
    }
  }


  selectEvent(item) {

    if (this.SelectedNameArray.some(rm => rm.Id === item.Id)) {
      document.getElementById("RepeatedName").innerHTML = "Utilisateur déjà ajouté"
    }
    else {
      document.getElementById("RepeatedName").innerHTML = " "
      this.SelectedNameArray.push(item);
      this.selectedUserIds.push(item.Id)
      this.UserSharedArray.push(item);
    }

  }
  DeleteSelectedName(i) {
    if (this.UserSharedArray.some(item => item.Id === this.SelectedNameArray[i].Id)) {
      const indexId = this.selectedUserIds.indexOf(this.SelectedNameArray[i].Id);
      this.selectedUserIds.splice(indexId, 1);
      this.SelectedNameArray.splice(i, 1);

    }
    else {
      this.negativeId = this.SelectedNameArray[i].Id * (-1);
      this.SelectedNameArray.splice(i, 1);
      this.selectedUserIds.push(this.negativeId);
    }
  }

  SubmitShare() {
    if (this.selectedUserIds.length >= 1) {
      this.userApiService.UserKeysbyUserIds(this.selectedUserIds).subscribe(Users => {
        JSON.stringify(Users);
       
        
for(let i in Users)
{
  Users[i].ClientId=this.dataServiceGet.ClientId
}

        console.log(Users);
        this.userApiService.AddDocStoreUserAccess(Users).subscribe(UsersList => {

          console.log(UsersList)
          this.userApiService.AetcProDocStoreUserUpdate(UsersList).subscribe(update => {
          })
          for (var i in this.RightClickServiceGet.FileIdShare) {
            this.Files.push({
              Id: this.RightClickServiceGet.FileIdShare[i].toString()
            })
          }
          this.fileApiService.FileShareApi(UsersList, this.Files).subscribe(data => {
            this.dataServiceSend.sendFileById("ShareFile");
            this.RightClickServiceSend.sendUncheckEvent("true");
          })
        })
      })
      this.dialog.closeAll();
    }
    else {
      window.alert("Veuillez sélectionner les clients à partager");
    }
  }
}
