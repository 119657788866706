<div class="add-opt" id="buttonModal" *ngIf="btnModal">
      <ul class="MenuAdd" id="accessView">
          <li class="newAddBtn" (click)="openDialog('Télécharger un nouveau fichier')">
              <span>
                  <i class="far fa-file-alt"> </i>
                  <span class="nav-text addBtn">
                      Ajouter le fichier
                  </span>
              </span>
          </li>
          <li  class="newAddBtn" (click)="openDialog('Ajouter une nouvelle balise')">
              <span>
                  <i class="fas fa-tags"> </i>
                  <span class="nav-text addBtn">
                      Ajouter une étiquette
                  </span>
              </span>
          </li>

          <li class="newAddBtn" (click)="openDialog('Ajouter un dossier')">
              <span>
                  <i class="fas fa-folder"> </i>
                  <span class="nav-text addBtn">
                      Ajouter un dossier
                  </span>
              </span>
          </li>
          <li class="newAddBtn" (click)="openDialog('Ajouter un panier')">
          <span>
              <i class="fa fa-shopping-bag"> </i>
              <span class="nav-text addBtn">
              Ajouter un panier
              </span>
          </span>
      </li>
      </ul>
     
</div>