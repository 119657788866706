import { Component, OnInit, ViewChild } from '@angular/core';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { MatDialog } from '@angular/material/dialog';
import { threadId } from 'worker_threads';
@Component({
  selector: 'app-addfolderbtn',
  templateUrl: './addfolderbtn.component.html',
  styleUrls: ['./addfolderbtn.component.scss']
})
export class AddfolderbtnComponent implements OnInit {
  @ViewChild('inputFolderfield') inputname: any;
  FolderName: any;
  SelectedFolderId = 0;
  DocStoreUserAccess: any;
  SelectefFolderParentId:any;
  FolderNameExist:any;
  EmptySpace:any;
  FolderColourValue:any;
  constructor(private dataServiceGet: TagsdataService,
    private ApiService: FileApiService,
    private dataServiceSend: TagsdataService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.SelectedFolderId = this.dataServiceGet.sharedFolderId;
    this.SelectefFolderParentId = this.dataServiceGet.FolderParentId;
    //this.dataServiceGet.sendMoreClickEvent("true");
  }

  FolderColorEvent(folderColour)
  {
    this.FolderColourValue = folderColour
  }


  FoldernameEvent(inputFoldername) {
    this.FolderName = inputFoldername;
this.ApiService.GetFolderNameCheck(this.FolderName,this.SelectefFolderParentId).subscribe(data=>
  {
this.FolderNameExist=data;
if(data==true)
{
document.getElementById("inputfield").innerHTML = "le nom du dossier existe"
}
else if((data==false)){
  document.getElementById("inputfield").innerHTML = ""
}
  })

  }
  space(event)
  {
    if(this.inputname.nativeElement.value == '' || this.inputname.nativeElement.value == ' ')
    {
      event.preventDefault();
      document.getElementById("inputfield").innerHTML = "entrez le nom du dossier"
    }
  }
  AddFolder() {
    if (this.inputname.nativeElement.value == '' || this.inputname.nativeElement.value == ' ') {
      document.getElementById("inputfield").innerHTML = "entrez le nom du dossier"

    }
    else if (this.inputname.nativeElement.value != '' && this.FolderNameExist==false) {
      document.getElementById("inputfield").innerHTML = ""
      this.DocStoreUserAccess = atob(sessionStorage.getItem("sd-user"));
      let DocUser = JSON.parse(this.DocStoreUserAccess)
      this.ApiService.AddFolderApi(this.FolderName, this.FolderColourValue ,this.SelectedFolderId, DocUser).subscribe(data => {
        this.dataServiceSend.sendFolderAppendEvent(data);
      });
      this.dialog.closeAll();
    }

  }
 
  
}
