
        <div class="modal-header">
   
        <h4 class="modal-title"><span class="roundImg"><i class="fas fa-link faIconss"></i></span><b> Obtenir un lien partageable</b></h4>
 </div>
        <div class="modal-body">
            <input type="checkbox" id="accessType" name="linkType" [checked]="true" (change)="toggleVisibility($event)" /> <label class="searchLbl" for="linkType"> Sans expiration</label>
                <div id="publicAccess" *ngIf="isShownpublic">
                    <input id="docUrl" class="docurld" readonly value= "{{this.shareId}}" /> 
                     <button type="button" id="btnCopy" class="btn btn-default" (click)="LinkCopies()"><i class="fas fa-copy"> Copier le lien</i></button>
                </div> 

                <div id="privateAccess" class="row"  *ngIf="isShownprivate">
                    <div class="col-md-6">
                        <div class="identifier">
                            Identifiant:
                            <input class="form-control" id="identifier" [(ngModel)]='Identifiant' placeholder="Enter your Identifier" autocomplete="off" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="expTime">
                            Date d'expiration:
                            <input class="form-control" type="date" name="sevadate" [(ngModel)]='expirydatee' min-date="" placeholder="yyyy-MM-dd" ng-required="true" />
                          
                            <span class="customError" id="expDateError" style="color:red;font-size:12px;" *ngIf="expDateError">Veuillez sélectionner la date d'expiration</span>
                        </div>
                    </div>
                    <div class="col-md-12" style="margin-top:10px;float:right;">
                        <div class="linkSubmit">
                            <button type="button" class="btnDoc btn btn-default" id="btnLinkSubmit"  style="float:right;"  (click)="GenerateLink()"><b>Enregister</b></button>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="ExpiryAccess" >
                        <div id="ExpiryAccess">
                            <input id="docUrl1" class="docurld" readonly value= "{{this.privateGenerateLink}}" />  
                            <button type="button" id="btnCopy1" class="btn btn-default"  (click)="LinkprivateCopies()"  style="margin-top:10px;"><i class="fas fa-copy"> Copier le lien</i></button>
                        </div>
                    </div>
                </div>

        </div>
        <div class="modal-footer">
        </div>

    
    
    