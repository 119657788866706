import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RightClickDataService {
  private RightClickView = new Subject<any>();
  RightClickFile: any;
  fileArrayBasket:any;
  RightClickBool: boolean;
  CheckboxCount: any;
  RightClickFileType: any;
  FileName: any;
  FileReName: any;
  UserDetailforShare: any;
  FileIdShare: any;
  UserIdsList: any;
  OwnedBy: any;
  RightClickZipfileLinkId:any;
  RightClickFileLinkId:any;
  RightClickFileId:any;
  RightClickTagAttribute:any;
  RightClickTagListOnFiles:any;
  RightClickFilesIdList:any;
  UpdateSharedDetails: any;
  RightClickFolderId:any;
  RightClickFolderName:any;
  RightClickFolderColor:any;
  private ReloadFileRequest = new Subject<any>();
  private addFileview = new Subject<any>();
  private UnCheckBox = new Subject<any>();
  private UnCheckBoxBasketFiles = new Subject<any>();
  private UncheckBoxZip = new Subject<any>();
  private filesBasket = new Subject<any>();
  private fileId = new Subject<any>();
  private fileMove = new Subject<any>();
  private FileMovetoFolder = new Subject<any>();
  constructor() { }

  sendFileMoveEvent() {
    this.fileMove.next();
  }
  getFileMoveEvent(): Observable<any>{ 
    return this.fileMove.asObservable();
  }
  sendFileinBasket(id:any)
  {
this.fileId.next(id);
  }
  getFileinBasket(): Observable<any> {
    return this.fileId.asObservable();
  }
  sendRightClickEvent(x: any) {
    this.RightClickView.next(x);
  }
  getRightClickEvent(): Observable<any> {
    return this.RightClickView.asObservable();
  }


  Sendaddfileevent(x: any) {
    this.addFileview.next(x);
  }
  Getaddfileevent(): Observable<any> {
    return this.addFileview.asObservable();
  }


  SendRelaodFileRequest(reload: any) {
    this.ReloadFileRequest.next(reload)
  }
  GetReloadFileRequest(): Observable<any> {
    return this.ReloadFileRequest.asObservable();
  }
  sendUncheckEvent(y: any) {
    this.UnCheckBox.next(y);
  }
  getUnCheckEvent(): Observable<any> {
    return this.UnCheckBox.asObservable();
  }
  sendUncheckEventforZip(y: any) {
    this.UncheckBoxZip.next(y);
  }
  getUnCheckEventforZip(): Observable<any> {
    return this.UncheckBoxZip.asObservable();
  }
  sendfilesForBasket(y: any) {
    this.filesBasket.next(y);
  }
  getfilesForBasket(): Observable<any> {
    return this.filesBasket.asObservable();
  }
  sendUncheckEventforBasket(y: any) {
    this.UnCheckBoxBasketFiles.next(y);
  }
  getUncheckEventforBasket(): Observable<any> {
    return this.UnCheckBoxBasketFiles.asObservable();
  }
  sendFileMoveToFolder(fileIds:any) {
    this.FileMovetoFolder.next(fileIds);
  }
  getFileMoveToFolder(): Observable<any> {
    return this.FileMovetoFolder.asObservable();
  }
}
