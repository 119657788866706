<div class="headerdiv">

    <h4 class="modal-title"><span class="roundImg"><i style="color: rgb(223, 72, 72);"
                class="fas fa-folder-open"></i></span><b> Dossiers</b></h4>
</div>
<div *ngIf="bcElements.length>0" class="bcdiv">
    <ol class="col-md-12 breadcrumbs" id="addNav">
        <i class="fas fa-home" (click)="getallfolders()"></i>
         <li class="navigation"  *ngFor="let fol of bcElements;let i = index" (click)="DeleteBc(i)"><b> {{fol.FolderName}} </b></li> 
        
    </ol>

</div> 
<div class="col-md-12 row" id="loadFolders">

            <div   class="folder" *ngFor="let folderList of Folderarr;let folderIndex=index" (click)="FolderClick(folderList.FolderId ,folderList.FolderName)">

                <div  class="col-md-12 form-control folderheader" id="FolderId:{{folderList.FolderId}}">
                    <span>
                        <i class="fa fa-folder folderIcon" [ngStyle]="{'color': folderList.Color}"  id="FolderId:{{folderList.FolderId}}"  aria-hidden="true">{{folderList.color}}
                        </i>
                    </span>
                    <span id="FolderId:{{folderList.FolderId}}"  class="tagName"> {{folderList.FolderName}}</span>

                </div>
            </div>
        </div>
<div class="btndiv" *ngIf="FolderId > 0">
    <button  class="movebtn btn btn-default" type="button" id="button"  (click)="FileMove()"><b>Bouge ici</b></button>


</div>

