import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypefilterComponent } from 'src/app/Modules/Filter/Typefilter/typefilter.component';
import { TagsfilterComponent } from 'src/app/Modules/Filter/tagsfilter/tagsfilter.component';
import { TimelinefilterComponent } from 'src/app/Modules/Filter/timelinefilter/timelinefilter.component';
import { FiltersComponent } from 'src/app/Modules/Filter/Filters/filters.component';

import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ScrollingModule } from '@angular/cdk/scrolling';
// import {MatSliderModule} from '@angular/material/slider';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider'
import { Ng5SliderModule } from 'ng5-slider';
import { MoreFilterComponent } from './more-filter/more-filter.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    TypefilterComponent,
    TagsfilterComponent,
    TimelinefilterComponent,
    FiltersComponent,
    MoreFilterComponent
  ],

  imports: [
    CommonModule,
    MatIconModule,
    MatChipsModule,
    MatDatepickerModule,
    MatInputModule, MatButtonModule,
    ScrollingModule,
    MatSliderModule,
    Ng5SliderModule,
    MatCheckboxModule,
    FormsModule,
    MatSlideToggleModule
    // MatSlideToggleModule

  ],
  entryComponents:[MoreFilterComponent, ],
  
  exports: [
    TypefilterComponent,
    TagsfilterComponent,
    TimelinefilterComponent,
    FiltersComponent,
    MoreFilterComponent
  ],
})
export class FilterModule { }

