<form #myForm="ngForm">
    <div class="row">
        <div class="col-md-5">
            <span> <input #inputBasketfield class="form-control docInput" type="text" placeholder="nom du panier"
                    (keyup)="BasketnameEvent($event.target.value)" (keydown.space)='space($event);' /></span>
            <span class="col-md-2" id="inputfield"></span>
        </div>
    </div>
    <div>
        <button class="termineBtn btn btn-default"  (click)="AddBasket()"><b>Terminé</b></button>

    </div>
</form>
