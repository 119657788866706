import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { DocsModule } from './Modules/Docs/docs.module'
import { FilterModule } from './Modules/Filter/filter.module';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { HttpClientModule } from '@angular/common/http'
import { interceptorProviders } from './Interceptor/interceptors';
import { ToastrModule } from 'ngx-toastr';
import { NgxFilesizeModule } from 'ngx-filesize';
import { FileApiService } from './Service/backend/file-api.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ButtonModalModule } from './Modules/Shared/button-modal.module';
import { Ng5SliderModule } from 'ng5-slider';
import { DndDirective } from './dnd.directive';
import { IndexComponent } from './Components/Index/index.component';
import { TwoColumnLayoutComponent } from './Components/two-column-layout/two-column-layout.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { TabsComponent } from './Components/Tabs/tabs.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
const appConfig = (config: FileApiService) => {
  return () => {
    return config.loadfiles();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    TwoColumnLayoutComponent,
    TabsComponent,
    DndDirective,

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,


    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),

    MatListModule,
    Ng5SliderModule,

    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatNativeDateModule,
    HttpClientModule,
    FilterModule,
    DocsModule,
    MatDividerModule,
    NgxFilesizeModule,
    MatDialogModule,
    ButtonModalModule,
    MatSlideToggleModule,
    MatTabsModule,
    PdfViewerModule

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appConfig,
      multi: true,
      deps: [FileApiService]
    },
    interceptorProviders,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
