import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TagsdataService {

  private messageSource: Subject<any>
  private Source: Subject<any>
  private SourceMoreFilter: Subject<any>
  private SourceMoreTimeFilter: Subject<any>
  private SourceTypetoMoreFilter: Subject<any>
  private SourceMoreTagFilter: Subject<any>
  private Sourcefilter: Subject<any>
  private Sourcetagfilter: Subject<any>
  private subject = new Subject<any>();
  private FolderSubject = new Subject<any>();
  private TypetoMoreSubject = new Subject<any>();
  private TimetoMoreSubject = new Subject<any>();
  private TagtoMoreSubject = new Subject<any>();
  private SourceFolder = new Subject<any>();
  private SourceSearch: Subject<any>
  private ReloadFilter = new Subject<any>();
  private FilesByTags = new Subject<any>();
  private FilesBySearch = new Subject<any>();
  private MoreClickEvent = new Subject<any>();
  private FilterResult = new Subject<any>();
  private FilterTerms = new Subject<any>();
  private basketCount = new Subject<any>();
  private bascount = new Subject<any>();
  private BtnModalHide = new Subject<any>();
  private FileById = new Subject<any>();

  BearerHeader: any;
  message$: any;
  mes$: any;
  TypetoMoremes$: any;
  Moremes$: any;
  MoremesTime$: any;
  MoremesTag$: any;
  Timemes$: any;
  Tagmes$: any;
  Search$: any;
  TagsList: any;
  sharedFolderId: any;
  FolderParentId: any;
  TagsLoad: any;
  TokenJWTdecode: any;
  ClientId: any;
  ClientContainer: any;
  Baskets: any;
  EditedTagId: any;
  TagsUser: any;
  TagsSystem: any;

  constructor() {

    this.messageSource = new Subject<any>();
    this.message$ = this.messageSource.asObservable();

    this.Source = new Subject<any>();
    this.mes$ = this.Source.asObservable();

    this.SourceTypetoMoreFilter = new Subject<any>();
    this.TypetoMoremes$ = this.SourceTypetoMoreFilter.asObservable();


    this.SourceMoreFilter = new Subject<any>();
    this.Moremes$ = this.SourceMoreFilter.asObservable();

    this.SourceMoreTimeFilter = new Subject<any>();
    this.MoremesTime$ = this.SourceMoreTimeFilter.asObservable();

    this.SourceMoreTagFilter = new Subject<any>();
    this.MoremesTag$ = this.SourceMoreTagFilter.asObservable();

    this.Sourcefilter = new Subject<any>();
    this.Timemes$ = this.Sourcefilter.asObservable();

    this.Sourcetagfilter = new Subject<any>();
    this.Tagmes$ = this.Sourcetagfilter.asObservable();

    this.SourceSearch = new Subject<any>();
    this.Search$ = this.SourceSearch.asObservable();

    this.SourceFolder = new Subject<any>();



  }

  sendFileById(file: any) {
    this.FileById.next(file);
  }
  getFileById(): Observable<any> {
    return this.FileById.asObservable();
  }
  sendBtnModalHide(hide: any) {
    this.BtnModalHide.next(hide);
  }
  getBtnModalHide(): Observable<any> {
    return this.BtnModalHide.asObservable();
  }
  sendBasCount(count: any) {
    this.bascount.next(count);
  }
  getBasCount(): Observable<any> {
    return this.bascount.asObservable();
  }
  sendBasketCount(count: any) {
    this.basketCount.next(count);
  }
  getBasketCount(): Observable<any> {
    return this.basketCount.asObservable();
  }
  sendFilterTerms(obj: any) {
    this.FilterTerms.next(obj);
  }
  getFilterTerms(): Observable<any> {
    return this.FilterTerms.asObservable();
  }
  sendFilterResult(x: any) {
    this.FilterResult.next(x);
  }
  getFilterResult(): Observable<any> {
    return this.FilterResult.asObservable();
  }
  changeMessage(message: string) {
    this.messageSource.next(message)
  }



  Filtertypedatas(TypeValue: string) {
    this.Source.next(TypeValue)
  }
  TypetoMoreFilter(TypeValue: string) {
    this.SourceTypetoMoreFilter.next(TypeValue)
  }
  // FilterMoredatas(TypeMoreValue: string , TimeMoreValue:string , TagsMoreValue:string) {
  //   this.SourceMoreFilter.next(TypeMoreValue)
  //   this.SourceMoreFilter.next(TimeMoreValue)
  //   this.SourceMoreFilter.next(TagsMoreValue)

  // }
  FilterMoredatas(TypeMoreValue: string) {
    this.SourceMoreFilter.next(TypeMoreValue)
    // this.SourceMoreFilter.next(TimeMoreValue)
    // this.SourceMoreFilter.next(TagsMoreValue)

  }
  FilterMoreTimedatas(TimeMoreValue: string) {

    this.SourceMoreTimeFilter.next(TimeMoreValue)
  }

  FilterMoreTagdatas(TagMoreValue: string) {

    this.SourceMoreTagFilter.next(TagMoreValue)
  }



  Filtertimelinedatas(TimelineValue: string) {
    this.Sourcefilter.next(TimelineValue)
  }

  Filtertagsdatas(TagValue: string) {
    this.Sourcetagfilter.next(TagValue)
  }
  FilterSearchdatas(SearchValue: string) {
    this.SourceSearch.next(SearchValue)
  }

  sendFileAppendEvent(dtovalue: any) {
    this.subject.next(dtovalue);
  }
  getFileAppendEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  folderValue(folderValue: string) {
    this.SourceFolder.next(folderValue)
  }

  sendFolderAppendEvent(newFolder: any) {
    this.FolderSubject.next(newFolder);

  }
  getFolderAppendEvent(): Observable<any> {
    return this.FolderSubject.asObservable();
  }
  sendReloadFilter(y: any) {
    this.ReloadFilter.next(y);
  }
  getReloadFilter(): Observable<any> {
    return this.ReloadFilter.asObservable();
  }
  sendFileByTags(obj: any) {
    this.FilesByTags.next(obj);
  }
  getFileByTags(): Observable<any> {
    return this.FilesByTags.asObservable();
  }


  sendSearchFileByadvanceSearch(obj: any) {
    this.FilesBySearch.next(obj);
  }
  getSearchFileByadvanceSearch(): Observable<any> {
    return this.FilesBySearch.asObservable();

  }
  sendMoreClickEvent(y: any) {
    this.MoreClickEvent.next(y);
  }
  getMoreClickEvent(): Observable<any> {
    return this.MoreClickEvent.asObservable();
  }

  sendTypetoMoreFilterEvent(yy: any) {

    this.tempData = yy;
    this.TypetoMoreSubject.next(yy);

  }
  getTypetoMoreFilterEvent(): Observable<any> {
    return this.TypetoMoreSubject.asObservable();
  }

  sendTimetoMoreFilterEvent(yy: any) {

    this.timetempdata = yy;
    this.TimetoMoreSubject.next(yy);

  }
  getTimetoMoreFilterEvent(): Observable<any> {
    return this.TimetoMoreSubject.asObservable();
  }

  sendTagtoMoreFilterEvent(yy: any) {

    this.tagtempdata = yy;
    this.TagtoMoreSubject.next(yy);

  }
  getTagtoMoreFilterEvent(): Observable<any> {
    return this.TagtoMoreSubject.asObservable();
  }


  tempData: any;
  timetempdata: any;
  tagtempdata: any;

  gettimetempdata() {
    return this.timetempdata
  }
  gettempData() {
    return this.tempData
  }
  gettagtempData() {
    return this.tagtempdata
  }

}

