import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { TwoColumnLayoutComponent } from 'src/app/Components/two-column-layout/two-column-layout.component';


const routes: Routes = [

    {
        path: 'Home/stToken/:stToken',
        component: TwoColumnLayoutComponent
    },

    {
        path: 'Home/UserByTags',
        component: TwoColumnLayoutComponent
    },

    {
        path: 'Home/SearchResult',
        component: TwoColumnLayoutComponent
    },

    {
        path: 'Home/Admins',
        component: TwoColumnLayoutComponent
    },

    {
        path: 'App',
        component: AppComponent
    },

    {
        path: 'RefreshTwoColumnLayout',
        component: TwoColumnLayoutComponent
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
