import { Component, OnInit ,Inject } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { MoreFilterComponent } from 'src/app/Modules/Filter/more-filter/more-filter.component';
import { MatDialog } from '@angular/material/dialog';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

ReceivedValue;
  constructor( private MoreFilter: TagsdataService, public dialog: MatDialog,private dialogRef: MatDialogRef<ConfirmationDialogComponent> ,@Inject(MAT_DIALOG_DATA) public data: any) 
  {

   this.ReceivedValue = data
 
  
  }

  ngOnInit(): void {
  }

  public decline() {
    this.dialogRef.close({ data: 'cancel' });
  }

  public accept() {
    this.dialogRef.close({ data: 'delete' });
  }
  public OkReset()
  {
    this.dialogRef.close(this.MoreFilter.sendReloadFilter("true"))
    this.dialog.open(MoreFilterComponent,{
      width:'65%',
      height:'790px',
       
 
   
    });

  }
  public CancelReset()
  {
    this.dialogRef.close({ data: 'cancel' });
  }
  public nnn()
  {
   
  }
  public hhh()
  {
    
  }
 
}
