<form #myForm="ngForm">
  <div class="form-group">
    <div class="row">
        <div class="col-md-5">
            <input class="form-control docInput" #inputTagfield type="text" id="ccc" placeholder="Entrez votre nom de balise"
              value="{{this.tags}}"  (keyup)="TagnameEvent($event.target.value)" (keydown.space)="$event.preventDefault()"/>
            <span class="col-md-2" id="inputfield"></span>
        </div>

        <div class="col-md-4">
            <label>Sélectionnez la couleur de votre étiquette</label>
        </div>
        <div class="col-md-2">
            <input #inputtagcolour class="form-control docInput" type="color"  value="{{this.tagcolour}}" (change)="TagColorEvent($event.target.value)" />
        </div>
       
    </div>
 

<div class="row">
    <br>
    <mat-slide-toggle (click)="EditTag()" isSlideOn = false;>Modifier la tag </mat-slide-toggle>
    
    <div class="tagbox" id="selectedtagbox">
    <div class="row"  id="tagAssgnList">     
        <span class="doctagnames" *ngFor="let taglist of TagsListarr;let i=index">
          
          
            <span class="docTag" id="Tag_{{taglist.TagId}}"  [ngStyle]="{'border-color': taglist.Color,'background-color':taglist.Color+60}" *ngIf="taglist.IsReadOnly==false" 
            (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName,taglist.TagId, taglist.Color)"
            >{{ taglist.TagName }}
           
         <span id="sdTag_{{taglist.TagId}}"></span>
        </span>
        </span>
        
    </div>
</div>
</div>
<div class="btnFooter">
    <button class="registerBtn btn btn-default" *ngIf="isShownsubmit" type="submit"  (click)="AddTag()"><b>Enregister</b></button>
    <button class="updateBtn btn btn-default" *ngIf="isShownupdate" type="update"  (click)="UpdateTag()"><b>mettre à jour</b></button>
    <button class="resetBtn btn btn-default" *ngIf="isShowncancel" type="cancel"  (click)="ResetTag()"><b>Annuler</b></button>

</div> 
</div>
</form>


