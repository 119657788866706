<!-- <i class="fas fa-filter filtericon"></i> -->
<span class="headermoreFilter">Filtre</span>

<div class="border searchtypeeform" id="circle">

    <i class="fas fa-text-height typeicon"></i>
    <span class="typetxt"> Par type de documents </span>
    <div class="checkoption">
        <div   class="fa fa-file-pdf icon" id="type_pdf"> <span class="tooltiptext"></span></div> 
    <label class="optionlabel"><input type="checkbox" class="optionpdf" [(ngModel)]="option1" (change)="changeTypeSelected('pdf')">  pdf </label>
    <div   class="fa fa-image icon" id="type_img"> <span class="tooltiptext"></span></div> 
     <label class="optionlabel"><input type="checkbox" class="optionimg" [(ngModel)]="options2" (change)="changeTypeSelected('jpg')"> jpg  </label>
    <label class="optionlabel"><input type="checkbox" class="optionimgjpeg" [(ngModel)]="option3" (change)="changeTypeSelected('jpeg')"> jpeg </label>
    <label class="optionlabel"><input type="checkbox" class="optionimgpng" [(ngModel)]="option4" (change)="changeTypeSelected('png')"> png  </label>
    <div   class="fa fa-file icon" id="type_file"><span class="tooltiptext"></span></div> 
   <label class="optionlabel"><input  type="checkbox"  class="optiondoc" [ngModel]="option5"  (change)="changeTypeSelected('doc')">  doc </label>
   <label class="optionlabel"><input  type="checkbox"  class="optiondocx" [ngModel]="option6"  (change)="changeTypeSelected('docx')">  docx </label>
   <div   class="fa fa-file-powerpoint-o icon" id="type_pre"> <span class="tooltiptext"></span></div> 
   <label class="optionlabel"><input  type="checkbox"  class="optionpre" [ngModel]="option7"  (change)="changeTypeSelected('ppt')">  ppt </label>
   <label class="optionlabel"><input  type="checkbox"  class="optionprepptx" [ngModel]="option8"  (change)="changeTypeSelected('pptx')"> pptx </label>
   <div   class="fa fa-file-excel-o icon" id="type_excel"> <span class="tooltiptext"></span></div> 
   <label class="optionlabel"><input type="checkbox"  class="optionexcel"  [ngModel]="option9"  (change)="changeTypeSelected('xlsx')">  xlsx </label>
   <label class="optionlabel"><input  type="checkbox"  class="optionexcelxlsm"  [ngModel]="option10"  (change)="changeTypeSelected('xlsm')">  xlsm </label> 
   </div> 


</div>

<div class="border searchtimeform" id="circle">

    <i class="fas fa-history typeicon"></i>
    <span class="typetxt"> Par periode </span>
    <div class="col-md-12" id="dates">
        <label class="searchLblone">Déterminer une date ci-dessous:</label>
        <div class="row">
            <div class="col-md-4">
                <label class="searchLbl">
                    A partir du:
                </label>
                <input class="form-control" type="date" [(ngModel)]='fromdate' id="searchFromDate" placeholder="yyyy/MM/dd" ng-required="true" (change)="handleDate()"/>
               
            </div>
            <div class="col-md-4 todate">
                <label class="searchLbl">Jusqu’au:</label>
                <input class="form-control" type="date" [(ngModel)]='todate' id="searchToDate" placeholder="yyyy-MM-dd" ng-required="true" (change)="handle()" />
                
            </div>
        </div>
    </div>
 
</div>
<div class="border searchtagform" id="circle">

    <i class="fas fa-tags typeicon"></i>
    <span class="typetxt"> Par tags </span>
    <input type="text"  id="tagMoreFilterSearch" class="form-control" autocomplete="off" (keydown.backspace)="removeMoreTagOnBackspace($event)" (keyup)="MorekeyUp($event)" placeholder="Rechercher des tags" />
  
    <div class="searchtag">
           <span selectable multiple class="doctagnames">
          <span class="docTag" *ngFor="let taglist of searchtagdata"  id ="FilterTagId_{{taglist.TagId}}" [ngStyle]="{'border-color':taglist.Color,'color':taglist.Color}"   (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName , taglist.TagId)">{{ taglist.TagName }}
          <span id="selectTag_{{taglist.TagId}}"></span>
      </span>
    </span> 
    </div>
    <div class="listtag">
      <div class="manualtag">
            <span selectable multiple class="doctagnames"> 
            <span class="docTag" *ngFor="let taglist of manualtagList"   id ="FilterTagId_{{taglist.TagId}}" [ngStyle]="{'border-color':taglist.Color,'background-color':taglist.Color+25}"   (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName , taglist.TagId)">{{ taglist.TagName }}
            <span id="selectTag_{{taglist.TagId}}"></span>
        </span>
        </span>
    </div> 
    <br>
    <div class="usertag">
        <label class="searchLbl">Tags avec noms:
        <span selectable multiple class="doctagnames"> 
      
          <span class="docTag"  *ngFor="let taglist of usertagList"  id ="FilterTagId_{{taglist.TagId}}"  [ngStyle]="{'border-color':taglist.Color,'background-color':taglist.Color+25}"   (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName , taglist.TagId)">{{ taglist.TagName }}
          <span id="selectTag_{{taglist.TagId}}"></span>
      </span>
      </span>
    </label>
    </div>
    <br>
    <div class="systemtag">
        <label class="searchLbl">Tags par défaut:
          <span selectable multiple class="doctagnames"> 
              <span class="docTag" *ngFor="let taglist of systemtagList"   id ="FilterTagId_{{taglist.TagId}}" [ngStyle]="{'border-color':taglist.Color,'background-color':taglist.Color+25}"   (click)="taglist.state=!taglist.state;changeSelected('s', taglist.TagName , taglist.TagId)">{{ taglist.TagName }}  
              <span id="selectTag_{{taglist.TagId}}"></span>
            </span>
          </span>
        </label> 
    </div>
</div>

</div>
<br><br>
<div>
    <button class="registerBtn btn btn-default" type="submit" (click)="SubmitMore()"><b>Rechercher</b></button>
</div>
    

