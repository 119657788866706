import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationServiceService {
  API_URL;
  PdfUrl;
  constructor() {

     let envConfig = "prod"
    //let envConfig = "uat"

    let localConfig = {
      RoutePrefix_Storedoc_Aetc_restApi: 'http://localhost:3000/api/v1/aetcpro/',
      RoutePrefix_Storedoc_restApi: 'http://localhost:3000/api/v1/',

      StoreDoc_Main_Base_URL: 'http://localhost:3000/api/v1/',

      RoutePrefix_Aetc_StoredocLocal_Api: 'http://localhost:54781/api/StoreDoc/',
      RoutePrefic_Aetc_ClientLocal_Api: 'http://localhost:54781/api/Client/Users/',

      FileUri: 'https://stuatstore4doc.blob.core.windows.net/'
    }

    let uatConfig = {
      RoutePrefix_Storedoc_Aetc_restApi: 'http://test-api.store4doc.com/api/v1/aetcpro/',
      RoutePrefix_Storedoc_restApi: 'http://test-api.store4doc.com/api/v1/',

      StoreDoc_Main_Base_URL: 'http://test-api.store4doc.com/api/v1/',

      RoutePrefix_Aetc_StoredocLocal_Api: 'http://okit.aetc.pro:90/api/StoreDoc/',
      RoutePrefic_Aetc_ClientLocal_Api: 'http://okit.aetc.pro:90/api/Client/Users/',

      FileUri: 'https://stuatstore4doc.blob.core.windows.net/'
    }

    let prodConfig = {
      RoutePrefix_Storedoc_Aetc_restApi: 'https://prod-api.store4doc.com/api/v1/aetcpro/',
      RoutePrefix_Storedoc_restApi: 'https://prod-api.store4doc.com/api/v1/',

      StoreDoc_Main_Base_URL: 'https://prod-api.store4doc.com/api/v1/',

      RoutePrefix_Aetc_StoredocLocal_Api: 'https://okit.aetc.pro/api/StoreDoc/',
      RoutePrefic_Aetc_ClientLocal_Api: 'https://okit.aetc.pro/api/Client/Users/',

      FileUri: 'https://stprodstore4doc.blob.core.windows.net/'
    }

    if (envConfig == "prod") {
      this.API_URL = prodConfig
    }
    else if (envConfig == "uat") {
      this.API_URL = uatConfig
    }
    else {
      this.API_URL = localConfig
    }

    this.PdfUrl =
    {
      pdfurl: this.API_URL.FileUri
    }

  }

  getApiConfig() {
    return this.API_URL;
  }
  getpdfUrl() {
    return this.PdfUrl;
  }
}
