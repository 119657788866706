import { Component, OnInit, ViewChild } from '@angular/core';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { MatDialog } from '@angular/material/dialog';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
@Component({
  selector: 'app-addtagbtn',
  templateUrl: './addtagbtn.component.html',
  styleUrls: ['./addtagbtn.component.scss']
})

export class AddtagbtnComponent implements OnInit {
  @ViewChild('inputTagfield') inputname: any;
  @ViewChild('inputtagcolour') inputcolour: any;
  constructor(private ApiService: FileApiService,
    private RightClickServiceGet: RightClickDataService,
    private dataServiceGet: TagsdataService,
    private dataServiceSend: TagsdataService,
    private RightClickServiceSend: RightClickDataService,
    public dialog: MatDialog) { }
  UserAccess: any;
  tagName: any;
  tagColor: any;
  DocStoreUserAccess: any;
  selectedChips: any[] = [];
  TagIdsUncheck: any[] = [];

  tags: any[] = [];
  tagcolour: any[] = [];
  TagsListarr: any[] = []
  isShownsubmit: boolean = true;

  isSlideOn: boolean = true;
  isShownupdate: boolean = false;
  isShowncancel: boolean = false;
  AllowEditTag: boolean = false;
  EditTagId: any;
  Removeclass
  ngOnInit(): void {
    this.TagsListarr = this.RightClickServiceGet.RightClickTagAttribute
    //this.dataServiceGet.sendMoreClickEvent("true");
  }

  TagnameEvent(inputTagname) {
    this.tagName = inputTagname;

  }
  TagColorEvent(inputTagColor) {
    this.tagColor = inputTagColor;
  }

  AddTag() {

    if (this.inputname.nativeElement.value == '') {
      document.getElementById("inputfield").innerHTML = "entrez le nom de la balise"
    }
    else if (this.inputname.nativeElement.value != '') {
      document.getElementById("inputfield").innerHTML = ""
      this.DocStoreUserAccess = atob(sessionStorage.getItem("sd-user"));
      let DocUser = JSON.parse(this.DocStoreUserAccess)
      this.ApiService.AddTagsApi(this.tagName, this.tagColor, false, DocUser).subscribe(data => {
        if (data == "Success") {
          this.ApiService.GetTagsApi().subscribe(data => {
            this.RightClickServiceSend.RightClickTagAttribute = data.Tags;
            this.dataServiceGet.TagsLoad = data.Tags;
          });
        }
      });
      this.dialog.closeAll();
    }

  }
  EditTag() {

    if (this.isSlideOn == true) {
      document.querySelector("#selectedtagbox").classList.add("editcolour");
      this.isShownsubmit = true;
      this.isShownupdate = false;
      this.isShowncancel = false;
      this.AllowEditTag = true;
      this.isSlideOn = false;
    }
    else {
      this.Removeclass = document.querySelectorAll(".tagbox");
      //console.log(this.Removeclass);
      [].forEach.call(this.Removeclass, function (el) {
        el.classList.remove("editcolour");
      });
      this.isSlideOn = true;
      this.isShownsubmit = true;
      this.isShownupdate = false;
      this.isShowncancel = false;
      this.AllowEditTag = false;
      this.tagcolour = [];
      this.selectedChips = [];
      this.tags = [];
      this.TagIdsUncheck.forEach((value, index, array) => {
        document.getElementById('sdTag_' + value).innerHTML = " "

      });
      document.getElementById("inputfield").innerHTML = " "
    }


  }
  changeSelected(parameter: any, query: string, TagId: any, Color) {

    this.tags = [];
    this.selectedChips = [];
    document.getElementById("inputfield").innerHTML = " "

    this.TagIdsUncheck.push(TagId);
    this.TagIdsUncheck.forEach((value, index, array) => {
      document.getElementById('sdTag_' + value).innerHTML = " "
    });

    if (this.AllowEditTag == true) {
      // document.querySelector("#selectedtagbox").classList.add("editcolour");
      this.isShownupdate = true;
      this.isShowncancel = true;
      this.isShownsubmit = false;
      const index = this.selectedChips.indexOf(query);
      if (index >= 0) {
        this.selectedChips.splice(index, 1);
        this.tags = this.selectedChips
        document.getElementById('sdTag_' + TagId).innerHTML = " "


      }
      else {
        this.selectedChips.push(query);
        this.tags = this.selectedChips
        this.tagcolour = Color
        this.EditTagId = TagId;
        document.getElementById('sdTag_' + TagId).innerHTML = "<i class='fa fa-check-circle'></i>"
      }
    }
    else {

    }

  }
  UpdateTag() {
    let Edittagid;
    let Edittagname;
    let Edittagcolour;
    Edittagname = this.inputname.nativeElement.value;
    Edittagcolour = this.inputcolour.nativeElement.value;
    Edittagid = this.EditTagId
    this.ApiService.EditTagsApi(Edittagid, Edittagname, Edittagcolour).subscribe(data => {
      if (data == "Success") {
        this.ApiService.GetTagsApi().subscribe(data => {
          this.RightClickServiceSend.RightClickTagAttribute = data.Tags;
          this.dataServiceGet.TagsLoad = data.Tags;
          this.dataServiceSend.EditedTagId = Edittagid;
          this.dataServiceSend.sendFileById("TagUpdate");
          this.dialog.closeAll();
        })
      }
    });

  }
  ResetTag() {
    // this.Removeclass = document.querySelectorAll(".tagbox")
    //   console.log(this.Removeclass);

    //   [].forEach.call(this.Removeclass, function (el) {
    //     el.classList.remove("editcolour");
    //   });
    //this.isSlideOn = false;
    this.isShownsubmit = true;
    this.isShownupdate = false;
    this.isShowncancel = false;
    //this.AllowEditTag = false;
    this.tagcolour = [];
    this.selectedChips = [];
    this.tags = [];
    this.TagIdsUncheck.forEach((value, index, array) => {
      document.getElementById('sdTag_' + value).innerHTML = " "

    });
    document.getElementById("inputfield").innerHTML = " "
  }
}
export class SlideToggleOverviewExample { }