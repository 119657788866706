import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  UniqId: any;

  subscription: Subscription;

  constructor(private readonly routeParams: ActivatedRoute) {
   
}

  ngOnInit(): void {
    this.UniqId = this.routeParams.snapshot.paramMap.get("stToken");

  }

}
