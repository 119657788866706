import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonModalComponent } from 'src/app/Modules/Shared/button-modal/button-modal.component';

@Component({
  selector: 'app-btn-index',
  templateUrl: './btn-index.component.html',
  styleUrls: ['./btn-index.component.scss']
})
export class BtnIndexComponent implements OnInit {
  recieveddata;
  constructor(
    dialogRef: MatDialogRef<ButtonModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.recieveddata = data;
    
  }

  ngOnInit(): void {
  }

}
