import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { ConfigurationServiceService } from 'src/app/configuration-service.service'
import { TagsdataService } from '../Frontend/tagsdata.service';
import { HttpRequest } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class FileApiService {
  private FilesList;
  private AddTagsList;
  private EditTagsList;
  private AddFoldersList;
  private Zipfile;
  private FileDelete;
  private FolderDelete;
  private FileRename;
  private fileShare;
  private TagsList;
  private FoldersList;
  private GenerateLink;
  private TagAssignList;
  private FoldersNameCheck;
  constructor(private http: HttpClient,
    private ConfigService: ConfigurationServiceService,
    private dataserviceSend: TagsdataService) { }
  loadfiles() {
    return "LoadFiles";
  }
  getfiles(Flagname: any, limit: any, offset: any) {
    this.dataserviceSend.BearerHeader = true;

    this.FilesList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/limit', {
      Flag: Flagname,
      SearchTerms: null, Limit: limit, Offset: offset
    });
    return this.FilesList;
  }
  GetUserFilesByTags(key, tagids) {
    this.dataserviceSend.BearerHeader = true;
    this.FilesList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/ForUserByTags', {
      AccessKey: key,
      TagIds: tagids
    });
    return this.FilesList;
  }

  GetSearchFiles(flag: any, searchTerms: any, limit: any, offset: any) {
    this.dataserviceSend.BearerHeader = true;
    this.FilesList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/Search', { Flag: flag, SearchTerms: searchTerms, Limit: limit, Offset: offset });
    return this.FilesList;

  }
  uploadFile(params: any) {
    this.dataserviceSend.BearerHeader = false;
    this.FilesList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Aetc_StoredocLocal_Api + 'Upload', { parameters: params });
    return this.FilesList;

  }

  FileDropIntoFolder(FileIds, FolderId) {
    console.log(FileIds)
    console.log(FolderId)
    this.dataserviceSend.BearerHeader = true;
    this.FilesList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/Move', { Files: FileIds, Destination: FolderId });
    return this.FilesList;
  }
  FileById(fileId) {
    this.dataserviceSend.BearerHeader = true;
    this.FilesList = this.http.get(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/' + fileId, {});
    return this.FilesList;
  }

  AddTagsApi(tagName, tagColor, isRead, UserAccess) {

    this.dataserviceSend.BearerHeader = true;
    this.AddTagsList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'tags/Add', { TagName: tagName, Color: tagColor, IsReadOnly: isRead, TagUsers: UserAccess });
    return this.AddTagsList;
  }

  EditTagsApi(tagId, tagname, tagColor) {
    this.dataserviceSend.BearerHeader = true;
    this.EditTagsList = this.http.put(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'tags/Update', { Id: tagId, TagName: tagname, Color: tagColor });
    return this.EditTagsList;
  }

  GetTagsApi() {
    this.dataserviceSend.BearerHeader = true;
    this.TagsList = this.http.get(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'tags', {});
    return this.TagsList;
  }

  GetListofTag() {
    this.dataserviceSend.BearerHeader = true;
    this.TagsList = this.http.get(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'tags/ForSearch', {});
    return this.TagsList;

  }
  GetFolders() {
    this.dataserviceSend.BearerHeader = true;
    this.FoldersList = this.http.get(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'folders', {});
    return this.FoldersList;
  }
  AddFolderApi(folderName, foldercolour, folderParentId, docuser) {
    this.dataserviceSend.BearerHeader = true;
    this.AddFoldersList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'folders/add', { Name: folderName, Color: foldercolour, Parent_Id: folderParentId, FolderUsers: docuser });
    return this.AddFoldersList;
  }

  DownloadZip(linkIdArray): Observable<any> {
    this.dataserviceSend.BearerHeader = true;
    this.Zipfile = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/DownloadZip', { Files: linkIdArray });
    return this.Zipfile;

  }
  DowloadFolderZip(FolderId): Observable<any> {
    this.dataserviceSend.BearerHeader = true;
    this.Zipfile = this.http.get(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'folders/DownloadZip/' + FolderId, {});
    return this.Zipfile;
  }


  fileDelete(FileName) {
    this.dataserviceSend.BearerHeader = true;
    this.FileDelete = this.http.put(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/DeleteMultiple', {
      Files: FileName
    });
    return this.FileDelete;
  }
  fileRename(guidName, NewFileName) {
    this.dataserviceSend.BearerHeader = true;
    this.FileRename = this.http.put(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/Rename', { guid: guidName, fileName: NewFileName });
    return this.FileRename;
  }

  FileShareApi(users, fileIds) {

    this.dataserviceSend.BearerHeader = true;
    this.fileShare = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/Share', { Users: users, Files: fileIds });
    return this.fileShare;
  }

  GetGenerateLink(IdentifiantName: any, ExpiryDate: any, FileId: any) {
    this.dataserviceSend.BearerHeader = true;
    this.GenerateLink = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/GenerateLink', { Identifier: IdentifiantName, ExpiryDate: ExpiryDate, FileId: FileId });
    return this.GenerateLink;
  }

  TagAssign(TagIds: any, filesIds: any) {
    this.dataserviceSend.BearerHeader = true;
    this.TagAssignList = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'tags/Assign', { Files: filesIds, Tags: TagIds });
    return this.TagAssignList;
  }
  GetFolderFiles(folderid) {
    this.dataserviceSend.BearerHeader = true;
    this.FilesList = this.http.get(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'folders/' + folderid + '/files', {});
    return this.FilesList;
  }
  GetFolderNameCheck(foldername, parentId) {
    this.dataserviceSend.BearerHeader = true;
    this.FoldersNameCheck = this.http.post(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'folders/CheckName', { Name: foldername, ParentId: parentId });
    return this.FoldersNameCheck;
  }
  DragDropdeleteFile(deletefilevalue) {
    this.dataserviceSend.BearerHeader = true;
    this.FilesList = this.http.put(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'files/Delete', { name: deletefilevalue });
    return this.FilesList;
  }
  folderDelete(FolderId) {
    this.dataserviceSend.BearerHeader = true;

    this.FolderDelete = this.http.put(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'folders/Delete/' + FolderId, {

    });
    return this.FolderDelete;
  }
  folderRename(folderid, NewFileName, NewColor) {
    this.dataserviceSend.BearerHeader = true;
    this.FileRename = this.http.put(this.ConfigService.getApiConfig().RoutePrefix_Storedoc_restApi + 'folders/Rename', { Name: NewFileName, Color: NewColor, Id: folderid });
    return this.FileRename;
  }

  // downloadFile(): any {
  // 	return this.http.get('http://localhost:4200', {responseType: 'blob'});
  // }


  downloadPdf() {
    let aaa = this.http.get('api/my-pdf', { responseType: 'arraybuffer' });
    return aaa;

  }


}





