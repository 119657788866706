import { Component, OnInit,ViewChild } from '@angular/core';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-add-basketbtn',
  templateUrl: './add-basketbtn.component.html',
  styleUrls: ['./add-basketbtn.component.scss']
})
export class AddBasketbtnComponent implements OnInit {
  @ViewChild('inputBasketfield') inputname: any;
BasketName:any;


  constructor(private dataServiceGet: TagsdataService,
    private ApiService: FileApiService,
    private dataServiceSend: TagsdataService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    //this.dataServiceSend.sendMoreClickEvent("true");
  }
  BasketnameEvent(inputFoldername) {
    this.BasketName = inputFoldername;


  }
  space(event)
  {
    if(this.inputname.nativeElement.value == '' || this.inputname.nativeElement.value == ' ')
    {
      event.preventDefault();
      document.getElementById("inputfield").innerHTML = "entrez le nom du panier"
    }
  }
  AddBasket() {
    if (this.inputname.nativeElement.value == '' || this.inputname.nativeElement.value == ' ') {
      document.getElementById("inputfield").innerHTML = "entrez le nom du panier"

    }
    else if (this.inputname.nativeElement.value != '') {
      document.getElementById("inputfield").innerHTML = ""
      this.dataServiceSend.sendBasketCount(this.BasketName);
      this.dialog.closeAll();
    }

  }
}
