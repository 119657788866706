<div class="headerdiv">

    <h4 class="modal-title"><span class="roundImg"><i style="color: rgb(223, 72, 72);"
                class="fas fa-user-plus"></i></span><b> Partager</b></h4>
</div>

<div class="inputdiv">
    <div class="docInput ng-autocomplete">
        <ng-autocomplete (keyup)="SelectNameEvent($event.target.value)" [data]="UserList" [searchKeyword]="keyword"
            placeholder="Entrez votre nom de client." historyIdentifier="UserList" [itemTemplate]="itemTemplate"
            (selected)='selectEvent($event)' [notFoundTemplate]="notFoundTemplate">
        </ng-autocomplete>
        <span id="RepeatedName"></span>
    </div>

    <div class="SelectedNames">

        <span class="selectedUserNames" *ngFor="let val of SelectedNameArray;let i=index">

            <span *ngIf="val.Id >= 1" class="NameTag"
                [ngClass]="{'ProfileCode1': (val.ProfileCode==1),'ProfileCode2':(val.ProfileCode==2),
            'ProfileCode4':(val.ProfileCode==4),'ProfileCode5':(val.ProfileCode==5),'ProfileCode6':(val.ProfileCode==6)}">
                {{val.FullName}} <i *ngIf="ownedBy!=val.Id" style="font-size: 15px;" (click)="DeleteSelectedName(i)"
                    class="fa fa-times"></i>
            </span>
        </span>

    </div>

</div>
<div class="footerdiv">
    <span class="description">
        <span class="colorDesc" id="admin"></span>Admin
        <span class="colorDesc" id="superInterlocater"></span>Super Interlocator
        <span class="colorDesc" id="interlocater"></span>Interlocator
        <span class="colorDesc" id="ficheClient"></span>Fiche Clients
        <span class="colorDesc" id="ClientPro"></span>Clients Pro
    </span>
    <button class="registerBtn btn btn-default" type="submit" id="button"  (click)="SubmitShare()"><b>Enregister</b></button>
</div>

<ng-template #itemTemplate let-item>
    <a [innerHTML]="item.FullName"></a>
</ng-template>
<ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
</ng-template>
