import { Component, OnInit } from '@angular/core';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { MatDialog } from '@angular/material/dialog';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { ConfigurationServiceService } from 'src/app/configuration-service.service'

@Component({
  selector: 'app-link-sharing',
  templateUrl: './link-sharing.component.html',
  styleUrls: ['./link-sharing.component.scss']
})
export class LinkSharingComponent implements OnInit {

  constructor(
    private RightClickServiceGet: RightClickDataService,
    private dataService: TagsdataService, 
    private file: FileApiService,
    private ConfigService: ConfigurationServiceService, 
    public dialog: MatDialog) { }

  APiDocStorelink = this.ConfigService.getApiConfig().StoreDoc_Main_Base_URL;
  shareId
  linkid;
  filename;
  filetype;
  fileId;
  isShownprivate: boolean = false;
  ExpiryAccess: boolean = false;
  expDateError: boolean = false;
  isShownpublic: boolean = true;
  Identifiant = '';
  expirydatee = '';
  privateGenerateLink
  checked
  minDate = new Date();

  ngOnInit(): void {
    this.linkid = this.RightClickServiceGet.RightClickFileLinkId;
    this.fileId = this.RightClickServiceGet.RightClickFileId
    this.shareId = this.APiDocStorelink + "files/Download/"+ this.dataService.ClientContainer+"/file/" + this.linkid;
  }
  toggleVisibility(event) {
    if (event.target.checked == true) {
      this.isShownprivate = false;
      this.ExpiryAccess = false;
      this.expDateError = false;
      this.isShownpublic = true;
    }
    else {
      this.isShownprivate = true;
      this.ExpiryAccess = false;
      this.expDateError = false;
      this.isShownpublic = false;
    }
  }

  GenerateLink() {

    let IdentifiantName = this.Identifiant
    let ExpiryDate = this.expirydatee
    let FileId = this.fileId
    this.file.GetGenerateLink(IdentifiantName, ExpiryDate, FileId).subscribe(data => {

      this.ExpiryAccess = true;
      this.privateGenerateLink = data;
    });


  }
  LinkCopies() {

    let copyText = document.getElementById("docUrl") as HTMLInputElement;

    copyText.setSelectionRange(0, 99999)
    copyText.select()
    document.execCommand("copy");
    document.getElementById("btnCopy").innerHTML = ('<i class="fas fa-check"></i> Copié');
    setTimeout(function () {
      document.getElementById("btnCopy").innerHTML = ('<i class="fas fa-copy"></i> Copier le lien');
      window.getSelection().removeAllRanges();
    }, 2000);


  }
  LinkprivateCopies() {

    let copyTextprivate = document.getElementById("docUrl1") as HTMLInputElement;

    copyTextprivate.setSelectionRange(0, 99999)
    copyTextprivate.select()
    document.execCommand("copy");
    document.getElementById("btnCopy1").innerHTML = ('<i class="fas fa-check"></i> Copié');
    setTimeout(function () {
      document.getElementById("btnCopy1").innerHTML = ('<i class="fas fa-copy"></i> Copier le lien');
      window.getSelection().removeAllRanges();
    }, 2000);
  }




}
