
<div class="flexContainer">
  <section class="col-md-2-5 fillHeight">
    <div>
      <app-index></app-index>
    </div>
  </section>
  <div class="colTwo">
    <ng-content select="router-outlet"></ng-content>
    <div>
      <app-files-index></app-files-index>
    </div>

  </div>
</div>