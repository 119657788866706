import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { Subscription } from 'rxjs';
import { TagfilterService } from 'src/app/Service/backend/tagfilter.service';
import { FilterdataService } from 'src/app/Service/Frontend/filterdata.service';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'

@Component({
  selector: 'app-tagsfilter',
  templateUrl: './tagsfilter.component.html',
  styleUrls: ['./tagsfilter.component.scss']
})

export class TagsfilterComponent implements OnInit {
  clickEventSubscription: Subscription;
  TypeEventSubscription: Subscription;
  constructor(private TagFilter: TagsdataService,
    private file: FileApiService,
    private tagfile: TagfilterService,
    private filterServiceSend: FilterdataService,
    private filterServiceGet: FilterdataService,
    private RightClickServiceSend: RightClickDataService,
    private RightClickServiceGet: RightClickDataService) {
    this.clickEventSubscription = this.TagFilter.getReloadFilter().subscribe((data) => {

      if (data == "true") {
        this.selectedChips = [];
        this.searchtagdata = [];
        (document.getElementById('tagFilterSearch') as HTMLInputElement).value = '';
        this.TagIdsUncheck.forEach((value, index, array) => {

        });
        this.TagIdsUncheck = [];
        document.getElementById('circletag').style.height = '91px';
        document.getElementById('SearchTag').style.overflow = 'hidden';
      }
    })

  }


  selectedChips: any[] = [];
  sTag: string = null;
  text = '';
  isShow = false;
  arr: any[];
  searchtagdata: any[];
  systemtagList: any[];
  manualtagList: any[];
  usertagList: any[];
  TagSearcharr: any[];
  messagetype: string;
  subscription: Subscription;
  TagValue: string;
  TagIdsUncheck: any[] = [];
  selectedtagList: any[] = [];
  STypes: string = null;
  STags: string = null;
  FlagForSlideToggle: boolean = false;
  disabled = false;
  SDates: string = 'fromdate:null;todate:null';
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.arr, event.previousIndex, event.currentIndex);
  }

  ngOnInit(): void {

  }

  newMessage(name) {
    this.TagFilter.Filtertagsdatas(name)
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  onToggleChange() {
    if (this.FlagForSlideToggle == true) {
      this.STags = this.STags.toString()
      this.STags = this.STags.replace(/,/g, '`')

    } else {
      this.STags = this.STags.toString()
      this.STags = this.STags.replace(/`/g, ',')

    }

  }

  changeSelected(parameter: string, query: string, tagid: any) {
    console.log("data click process");
    let Flag: string;
    let Terms: string;
    let Limit;
    let Offset;
    let tags: any;
    this.TagIdsUncheck.push(tagid);
    const index = this.selectedChips.indexOf(query);
    const selectedindex = this.selectedtagList.findIndex(record => record.TagName === query);
    if (index >= 0 || selectedindex >= 0) {
      this.selectedChips.splice(index, 1);
      this.selectedtagList.splice(index, 1);
      tags = this.selectedChips;
      for (let i = 0; i < this.searchtagdata.length; i++) {
        if (this.searchtagdata[i].TagId == tagid) {
          document.getElementById('selectTag_' + tagid).innerHTML = " "
        }
      }


    } else {
      this.selectedChips.push(query);
      for (let i = 0; i < this.searchtagdata.length; i++) {
        if (this.searchtagdata[i].TagId == tagid) {
          this.selectedtagList.push(this.searchtagdata[i])
        }
      }

      //this.selectedChips = this.selectedChips.sort();

      tags = this.selectedChips
      //document.getElementById('selectTag_' + tagid).innerHTML = "<i class='fa fa-check-circle'></i>"

    }

    this.sTag = tags.toString()

    //this.TagFilter.sendTagtoMoreFilterEvent(tags);
    //this.newMessage(this.sTag);
    //this.subscription = this.TagFilter.Tagmes$.subscribe(x => this.TagValue = x)
    this.filterServiceSend.FilterFilesLoad = true;
    this.filterServiceSend.FilterTagsData = tags;
    this.STypes = this.filterServiceGet.FilterTypeData;
    this.SDates = this.filterServiceGet.FilterTimeLineData;
    this.STags = this.filterServiceGet.FilterTagsData;

    if (this.FlagForSlideToggle == true) {
      this.STags = this.STags.toString()
      this.STags = this.STags.replace(/,/g, '`')
    }

    if (this.STypes == '') {
      this.STypes = null;
    }
    if (this.SDates == '') {
      this.SDates = null;
    }
    if (this.STags == '') {
      this.STags = null;
    }

    this.STags = 'tags:' + this.STags;
    if (this.STags.includes("null")) {
      this.STags = null
    }
    this.STypes = 'type:' + this.STypes
    if (this.STypes.includes("null")) {
      this.STypes = null
    }
    this.SDates = this.SDates

    if (this.STags == null) {
      this.STags = 'tags:' + this.STags
    }
    if (this.STypes == null) {
      this.STypes = 'type:' + this.STypes
    }
    if (this.SDates == null) {
      this.SDates = 'fromdate:' + this.SDates + ';' + 'todate:' + this.SDates
    }
    Terms = this.STypes + ';' + this.STags + ';' + this.SDates

    Flag = "AdvanceSearch";
    Limit = 25;
    Offset = 0;


    this.TagFilter.sendFilterTerms(Terms);
    this.filterServiceSend.FilterTerms = Terms;
    if (Terms != "type:null;tags:null;fromdate:null;todate:null") {

      this.file.GetSearchFiles(Flag, Terms, Limit, Offset).subscribe(data => {
        this.newMessage(data);
        this.filterServiceSend.FilterGlobalFiles = data;
        this.filterServiceSend.sendFilterFiles(data);
        this.TagFilter.sendFilterResult("true");
        this.TagFilter.sendReloadFilter("false");
        this.RightClickServiceSend.sendUncheckEvent("true");

      });


    }
    else {
      this.file.GetSearchFiles(Flag, Terms, Limit, Offset).subscribe(data => {
        this.newMessage(data);
        this.filterServiceSend.FilterGlobalFiles = data;
        this.filterServiceSend.sendFilterFiles(data);
        this.TagFilter.sendFilterResult("true");
        this.TagFilter.sendReloadFilter("false");
        this.RightClickServiceSend.sendUncheckEvent("true");

      });

    }
    if (this.selectedtagList.length > 3) {
      document.getElementById('selectedList').style.height = '91px';
      document.getElementById('selectedList').style.overflow = 'auto';
    }
    if (this.selectedtagList.length <= 3) {
      document.getElementById('selectedList').style.height = '50px';
      document.getElementById('selectedList').style.overflow = 'auto';
    }
  }

  keyUp(event) {

    let tagsearchTextBox
    let el
    tagsearchTextBox = (document.getElementById('tagFilterSearch') as HTMLInputElement).value;

    el = event.key;

    if (tagsearchTextBox.length >= 3) {

      this.tagfile.SearchTags(tagsearchTextBox).subscribe(data => {

        this.searchtagdata = data;
        if (this.searchtagdata.length >= 3) {
          document.getElementById('circletag').style.height = '200px';
          document.getElementById('SearchTag').style.overflow = 'auto';
          document.getElementById('SearchTag').style.height = '100px';
        }
        if (this.searchtagdata.length <= 2) {

          document.getElementById('circletag').style.height = '160px';
          document.getElementById('SearchTag').style.overflow = 'hidden';
          document.getElementById('SearchTag').style.height = '53px';
        }
      })
    }
    if (el === "Backspace") {
      this.searchtagdata = []
      if (this.selectedtagList.length > 3) {

        document.getElementById('SearchTag').style.overflow = 'hidden';
        document.getElementById('SearchTag').style.height = '0px';
        document.getElementById('circletag').style.height = '91px';
        document.getElementById('selectedList').style.height = '120px';
        document.getElementById('selectedList').style.overflow = 'auto';
      }

      if (this.selectedtagList.length <= 3) {
        document.getElementById('SearchTag').style.overflow = 'hidden';
        document.getElementById('SearchTag').style.height = '0px';
        document.getElementById('circletag').style.height = '91px';
        document.getElementById('selectedList').style.height = '50px';
        document.getElementById('selectedList').style.overflow = 'auto';
      }
    }


  }


  removeTagOnBackspace = function (event) {
    if (event.keyCode === 8) {
      this.TagSearcharr = this.arr;
    }
  };
}