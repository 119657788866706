import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const SD_USER = 'sd-user'
const SEARCH_HISTORY = 'search';
const SEARCH_TERMS = 'searchTerms';
const BASKET_LIST = 'basketList';
const MULTI_TOKENLIST = 'multiTokenList';
const MT_USERS = 'mt-users'

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  public saveAuthToken(TokenId: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, TokenId);
  }

  public getAuthToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveMultiTokens(multiTokens: string) {
    window.sessionStorage.removeItem(MULTI_TOKENLIST);
    window.sessionStorage.setItem(MULTI_TOKENLIST, multiTokens);
  }

  public getMultiTokens(): string {
    return sessionStorage.getItem(MULTI_TOKENLIST);
  }

  public saveDocStoreMutiUsers(mtUsers: any) {
    window.sessionStorage.removeItem(MT_USERS);
    let mtUsersBase64 = btoa(JSON.stringify(mtUsers));
    window.sessionStorage.setItem(MT_USERS, mtUsersBase64);
  }
  public getDocStoreMutiUsers(): any {
    return sessionStorage.getItem(MT_USERS);
  }

  public saveDocStoreUser(sdUser: any) {
    window.sessionStorage.removeItem(SD_USER);
    let sdUserBase64 = btoa(JSON.stringify(sdUser));
    window.sessionStorage.setItem(SD_USER, sdUserBase64);
  }
  public getDocStoreUser(): any {
    return sessionStorage.getItem(SD_USER);
  }
  public saveSearchHistory(searches: any) {
    window.sessionStorage.removeItem(SEARCH_HISTORY);

    window.sessionStorage.setItem(SEARCH_HISTORY, JSON.stringify(searches));
  }
  public getSearchHistory(): any {
    return JSON.parse(sessionStorage.getItem(SEARCH_HISTORY));
  }
  public saveSearchTerms(terms: any) {
    window.sessionStorage.removeItem(SEARCH_TERMS);

    window.sessionStorage.setItem(SEARCH_TERMS, JSON.stringify(terms));
  }
  public getSearchTerms(): any {
    return JSON.parse(sessionStorage.getItem(SEARCH_TERMS));
  }
  public clearHistory() {
    window.sessionStorage.removeItem(SEARCH_HISTORY);
    window.sessionStorage.removeItem(SEARCH_TERMS);
  }
  public saveBasketList(names: any) {
    window.sessionStorage.removeItem(BASKET_LIST);

    window.sessionStorage.setItem(BASKET_LIST, JSON.stringify(names));
  }
  public getBasketList(): any {
    return JSON.parse(sessionStorage.getItem(BASKET_LIST));
  }
  public saveBasketFiles(names: any) {


    window.sessionStorage.removeItem(BASKET_LIST);
    window.sessionStorage.setItem(BASKET_LIST, JSON.stringify(names));
    //window.sessionStorage.setItem(BASKET_LIST,JSON.stringify(names));

  }
  DeletefromBasket(data) {
    window.sessionStorage.removeItem(BASKET_LIST);
    window.sessionStorage.setItem(BASKET_LIST, JSON.stringify(data));
  }
}

