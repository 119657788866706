import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FileApiService } from 'src/app/Service/backend/file-api.service';
import { RightClickDataService } from 'src/app/Service/Frontend/right-click-data.service'
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TagsdataService } from 'src/app/Service/Frontend/tagsdata.service'
import { empty, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-rename-file',
  templateUrl: './rename-file.component.html',
  styleUrls: ['./rename-file.component.scss']
})

export class RenameFileComponent implements OnInit {
  @ViewChild('inputfield') inputname: any;
  NewFileName;
  SelectedFolderId = 0;
  RenameFolderName;
  RenameFolderColor;
  SelectefFolderParentId: any;
  FolderNameExist: any;
  ChangeFoldercolor: any;
  FolderEventSubscription: Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public dataname: any, private RightClickServiceGet: RightClickDataService,
    private RightClickServiceSend: RightClickDataService,
    private fileApiService: FileApiService,
    private dataServiceGet: TagsdataService,
    private dataServiceSend: TagsdataService,
    public dialog: MatDialog) {
  }
  ngOnInit(): void {
    this.SelectedFolderId = this.dataServiceGet.sharedFolderId;
    this.SelectefFolderParentId = this.dataServiceGet.FolderParentId;

  

    if(this.dataname=='folder')
    {
      this.NewFileName = this.RightClickServiceGet.RightClickFolderName
      this.RenameFolderName = this.NewFileName;
      this.ChangeFoldercolor = this.RightClickServiceGet.RightClickFolderColor
    }
    else{
      this.NewFileName=''
    }

  }
  ChangeFolderColorEvent(Colorchange) {
    this.ChangeFoldercolor = Colorchange
  }

  RenameEvent(inputName) {
    this.NewFileName = inputName;
    this.RenameFolderName = '';
    if (this.dataname == 'folder') {

      this.fileApiService.GetFolderNameCheck(this.NewFileName, this.SelectefFolderParentId).subscribe(data => {
        this.FolderNameExist = data;
        if (data == true) {
          document.getElementById("inputfieldEmpty").innerHTML = "le nom du dossier existe"
        }
        else if ((data == false)) {
          document.getElementById("inputfieldEmpty").innerHTML = ""
        }
      })

    }
  }
  AddNewFileName() {
    if (this.RenameFolderName != null) {
      this.FolderNameExist = false;
    }
    if (this.inputname.nativeElement.value == '') {
      document.getElementById("inputfieldEmpty").innerHTML = "entrez un nouveau nom de fichier"
    }
    else if (this.inputname.nativeElement.value != '') {
      document.getElementById("inputfieldEmpty").innerHTML = ""
      if (this.dataname == 'files') {
        this.fileApiService.fileRename(this.RightClickServiceGet.FileReName, this.NewFileName).subscribe(data => {
          //this.RightClickServiceSend.SendRelaodFileRequest("true");
          this.dataServiceSend.sendFileById("RenameFile");
        })
        this.dialog.closeAll();
      }
      else if (this.dataname == 'folder' && this.FolderNameExist == false) {

        this.fileApiService.folderRename(this.RightClickServiceGet.RightClickFolderId, this.NewFileName, this.ChangeFoldercolor).subscribe(data => {

          this.RightClickServiceSend.SendRelaodFileRequest("false");
        })
        this.dialog.closeAll();
      }

    }
  }
}
