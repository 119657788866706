<h2 style="border-bottom:1px solid rgb(233, 230, 230);">Aperçu</h2>

<div *ngIf="filetype=='pdf'">

    <pdf-viewer [src]="pdfurl" [render-text]="true" style="display: block; background-color: rgb(102, 101, 101);">
    </pdf-viewer>

</div>
<div *ngIf="filetype!='pdf'">
    <img [src]="pdfurl" style="  display: block;
    margin-left: auto;
    margin-right: auto;">
</div>