<div class="border col-md-12 searchtypeform" id="circle">
   <!-- <div class="input-container input-group" id="typeclick">  -->

      <i class="fas fa-text-height typeicon"></i>
      <span class="typetxt"> Taper </span>

   
    <div [hidden]="isShow" class="col-md-12 typebody" >
      
          <!-- <div class="post" *ngFor="let typelist of typedatas">     -->
       <button  class="col-md-2 BtnIcon btn btn-default fa fa-file-image-o icon" id="type_img" (click)="changeSelected('jpg')">
         <span  id="icon_jpg" ></span>
       <span class="tooltiptext">Images</span>
      </button> 
       <button   class="col-md-2 BtnIcon btn btn-default fa fa-file-pdf-o icon" id="type_pdf" (click)="changeSelected('pdf')"> 
         <span  id="icon_pdf"></span>
         <span class="tooltiptext">Pdf</span>
      </button> 
       <button   class="col-md-2 BtnIcon btn btn-default fa fa-file-word-o icon" id="type_file" (click)="changeSelected('doc')"> 
         <span  id="icon_doc"></span>
         <span class="tooltiptext">Documents</span>
      </button> 
       <button   class="col-md-2 BtnIcon btn btn-default fa fa-file-powerpoint-o icon" id="type_pre" (click)="changeSelected('ppt')"> 
         <span  id="icon_ppt"></span>
         <span class="tooltiptext">Présentations</span>
      </button> 
       <button   class="col-md-2 BtnIcon btn btn-default fa fa-file-excel-o icon" id="type_excel" (click)="changeSelected('xlsx')"> 
         <span  id="icon_xlsx"></span>
         <span class="tooltiptext">Tableur</span>
      </button> 
   
 
   </div> 
</div>