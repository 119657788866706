
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesIndexComponent } from './files-index/files-index.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { NgxFilesizeModule } from 'ngx-filesize';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { MatIconModule } from '@angular/material/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'fa-icons';
import { RightClickMenuComponent } from 'src/app/Modules/Shared/right-click-menu/right-click-menu.component';
import { ProgressComponent } from './progress/progress.component';
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { MatButtonModule } from '@angular/material/button';
import { ButtonModalComponent } from 'src/app/Modules/Shared/button-modal/button-modal.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DragDropModule} from '@angular/cdk/drag-drop'

library.add(fas);
@NgModule({
    declarations: [FilesIndexComponent, RightClickMenuComponent, ProgressComponent,ButtonModalComponent],
    imports: [
        CommonModule, MatDividerModule, MatListModule, NgxFilesizeModule,
        FontAwesomeModule, MatIconModule, PdfViewerModule,
        NgProgressModule,MatSlideToggleModule,
        NgProgressHttpModule,MatButtonModule,DragDropModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [FilesIndexComponent, MatDividerModule, NgxFilesizeModule, FontAwesomeModule],
})

export class DocsModule {

}