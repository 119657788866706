<form #myForm="ngForm">
    <div class="row">
        <div class="col-md-5">
            <span> <input #inputFolderfield class="form-control docInput" type="text" placeholder="nom du dossier"
                    (keyup)="FoldernameEvent($event.target.value)" (keydown.space)='space($event);' /></span>
            <span class="col-md-2" id="inputfield"></span>
        </div>
        <div class="col-md-4">
            <label>Sélectionnez la couleur de votre étiquette</label>
        </div>
        <div class="col-md-2">
            <input #inputFoldercolour class="form-control docInput" type="color" (change)="FolderColorEvent($event.target.value)"  />
        </div>
    </div>
    <div>
        <button class="termineBtn btn btn-default"  (click)="AddFolder()"><b>Terminé</b></button>

    </div>
</form>